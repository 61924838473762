import React, { useEffect, useState } from 'react';
import { useTheme } from '@mui/material/styles';
import { useNavigate, Link as RouterLink } from 'react-router-dom';
import SurveyService from 'services/survey.service';
import transformToJqDatatable from 'utils/data-transformers';
import useAuth from 'hooks/useAuth';
import dayjs from 'dayjs';
import EmojiEventsTwoToneIcon from '@mui/icons-material/EmojiEventsTwoTone';

// material-ui

import {
    Alert,
    AlertTitle,
    Grid,
    Divider,
    Paper,
    Typography,
    Table,
    Link,
    TableBody,
    Chip,
    TableCell,
    TableHead,
    TableContainer,
    TableRow,
    Button,
    Card,
    CardHeader,
    CardActions,
    CardContent,
    Stack
} from '@mui/material';

import UserCountCard from 'ui-component/cards/UserCountCard';
import RoundIconCard from 'ui-component/cards/RoundIconCard';
import MiscService from 'services/misc.service';
import { IconAbacus, IconFile, IconFileDollar, IconFileDownload } from '@tabler/icons';
import { FileDownloadOffRounded } from '@mui/icons-material';
import useGlobalModal from 'hooks/useGlobalModal';
import RemoveRedEyeTwoToneIcon from '@mui/icons-material/RemoveRedEyeTwoTone';

const DashBoard = () => {
    const [fetched, setFetched] = useState(false);
    const auth = useAuth();
    const navigate = useNavigate();
    const { openGlobalModal } = useGlobalModal();
    const columns = React.useRef([
        { field: 'survey_title' },
        { field: 'start_date' },
        { field: 'end_date' },
        { field: 'quota' },
        { field: 'created_at', orderable: true },
        { field: 'survey_status' },
        { field: 'survey_token' }
    ]);
    const [rows, setRows] = React.useState([]);
    const [isLoading, setIsLoading] = React.useState(true);
    const [dashboardData, setDashboardData] = React.useState({
        spending: 0
    });
    const [paginationProp, setPaginationProp] = React.useState({
        direction: 'desc',
        sortBy: 'created_at',
        searchBy: 'survey_title',
        page: 0,
        rowsPerPage: 5,
        rowsNumber: 5,
        filter: null,
        singleFilterValue: null
    });

    const { user } = useAuth();

    const openModal = () => {
        openGlobalModal({ title: 'Survey Flip Tutorials', content: 'https://docs.surveyflip.com/surveys/setting-up' });
    };

    const fetchMySurveys = async () => {
        const params = transformToJqDatatable({ columns: columns.current, pagination: paginationProp });
        const response = await SurveyService.list(params);
        setRows(response.data.data);
        setIsLoading(false);
    };

    const theme = useTheme();

    const cardStyle = {
        background: theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.grey[50],
        height: '100%',
        border: '1px solid',
        borderColor: theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.grey[100]
    };

    const fetchDashboardData = async () => {
        const { data } = await MiscService.getDashboardData(user?.id);
        setDashboardData(data);
    };

    useEffect(() => {
        fetchMySurveys();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        fetchDashboardData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user]);
    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <Typography variant="h2">Welcome {user?.first_name},</Typography>
            </Grid>
            {rows && rows.length < 1 && (
                <Grid item xs={12}>
                    <Alert severity="info" variant="outlined" sx={{ mt: 1, mb: 1 }}>
                        <AlertTitle>Heads up!</AlertTitle>
                        Looks like you do not have any survey created yet.
                        <Button onClick={() => navigate('/surveys/add')}>Create your first survey.</Button>
                    </Alert>
                </Grid>
            )}
            {rows && rows.length > 0 && (
                <>
                    <Grid item xs={12} lg={4} sm={6}>
                        <Paper variant="outlined">
                            <RoundIconCard primary="Surveys" secondary={dashboardData?.surveys} iconPrimary={IconFile} color="#007bff" />
                        </Paper>
                    </Grid>
                    <Grid item xs={12} lg={4} sm={6}>
                        <Paper variant="outlined">
                            <RoundIconCard
                                primary="Responses"
                                secondary={dashboardData?.answers}
                                iconPrimary={IconAbacus}
                                color="#17a2b8"
                            />
                        </Paper>
                    </Grid>
                    <Grid item xs={12} lg={4} sm={6}>
                        <Paper variant="outlined">
                            <RoundIconCard
                                primary="In Spending"
                                secondary={`$${dashboardData?.spending ?? 0}`}
                                iconPrimary={IconFileDollar}
                                color={theme.palette.primary.main}
                            />
                        </Paper>
                    </Grid>
                    <Grid item xs={12} lg={4} sm={6}>
                        <Paper variant="outlined">
                            <RoundIconCard
                                primary="Remaining Response(s)"
                                secondary={dashboardData?.response_remained}
                                iconPrimary={EmojiEventsTwoToneIcon}
                                color="#28a745"
                            />
                        </Paper>
                    </Grid>
                    <Grid item xs={12} lg={4} sm={6}>
                        <Paper variant="outlined">
                            <RoundIconCard
                                primary="Response(s) Filled"
                                secondary={dashboardData?.response_filled}
                                iconPrimary={FileDownloadOffRounded}
                                color="#ffc107"
                            />
                        </Paper>
                    </Grid>
                    <Grid item xs={12} lg={4} sm={6}>
                        <Paper variant="outlined">
                            <RoundIconCard
                                primary="Response Rate"
                                secondary={`${dashboardData?.response_rate}%`}
                                iconPrimary={IconFileDownload}
                                color="#343a40"
                            />
                        </Paper>
                    </Grid>

                    <Grid item xs={12}>
                        <Stack justifyContent="space-between" direction="row" sx={{ marginTop: 3, marginBottom: 0 }}>
                            <Typography variant="h4">Your Recent Surveys</Typography>
                            <Typography variant="h5">
                                <Button variant="text" href="/surveys">
                                    View All
                                </Button>
                            </Typography>
                        </Stack>
                    </Grid>

                    <Grid item xs={12}>
                        <TableContainer component={Paper} variant="outlined">
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Name</TableCell>
                                        <TableCell>Quota</TableCell>
                                        <TableCell>Status</TableCell>
                                        <TableCell>Start - End</TableCell>
                                        <TableCell>Quota Available</TableCell>
                                        <TableCell align="center">Action</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {rows &&
                                        rows.map((row, index) => (
                                            <TableRow hover key={index}>
                                                <TableCell>
                                                    <Link to={`/surveys/${row.survey_token}/view`} component={RouterLink}>
                                                        {row.survey_title}
                                                    </Link>
                                                </TableCell>
                                                <TableCell>{row.quota}</TableCell>
                                                <TableCell>
                                                    <Chip
                                                        size="small"
                                                        label={row.survey_status}
                                                        sx={{
                                                            color: theme.palette.black,
                                                            bgcolor: theme.palette.secondary.light
                                                        }}
                                                    />
                                                </TableCell>
                                                <TableCell>
                                                    {dayjs(row.start_date).format('MMM DD, YYYY')} -{' '}
                                                    {dayjs(row.end_date).format('MMM DD, YYYY')}
                                                </TableCell>
                                                <TableCell> {row?.meta?.remaining_quota ?? 0} </TableCell>
                                                <TableCell align="center">
                                                    <Button
                                                        size="small"
                                                        variant="text"
                                                        onClick={() => navigate(`/surveys/${row.survey_token}/view`)}
                                                    >
                                                        Manage
                                                    </Button>
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Grid>
                </>
            )}
            <Grid item xs={12} sm={6} lg={4} sx={{ marginTop: 4 }}>
                <Card sx={cardStyle}>
                    <CardHeader title="Profile" />
                    <Divider />
                    <CardContent sx={{ minHeight: '130px' }}>
                        <Grid container spacing={1} direction="column">
                            <Grid item>
                                <Typography variant="subtitle1">Manage your profile</Typography>
                            </Grid>
                            <Grid item>
                                <Typography variant="subtitle2">
                                    Complete your profile with as much information for the best possible results for your surveys.
                                </Typography>
                            </Grid>
                        </Grid>
                    </CardContent>
                    <Divider />
                    <CardActions>
                        <Grid container justifyContent="flex-start">
                            <Grid item>
                                <Button variant="outlined" href="/profile">
                                    Manage
                                </Button>
                            </Grid>
                        </Grid>
                    </CardActions>
                </Card>
            </Grid>
            <Grid item xs={12} sm={6} lg={4} sx={{ marginTop: 4 }}>
                <Card sx={cardStyle}>
                    <CardHeader title="Tips &amp; Tricks" />
                    <Divider />
                    <CardContent sx={{ minHeight: '130px' }}>
                        <Grid container spacing={1} direction="column">
                            <Grid item>
                                <Typography variant="subtitle1">Learn how to effectively conduct surveys</Typography>
                            </Grid>
                            <Grid item>
                                <Typography variant="subtitle2">
                                    Discover variety of ways to design questionnaire and conduct effective surveys for your research.
                                </Typography>
                            </Grid>
                        </Grid>
                    </CardContent>
                    <Divider />
                    <CardActions>
                        <Grid container justifyContent="flex-start">
                            <Grid item>
                                <Button variant="outlined" onClick={openModal}>
                                    View Tutorials
                                </Button>
                            </Grid>
                        </Grid>
                    </CardActions>
                </Card>
            </Grid>
            <Grid item xs={12} sm={6} lg={4} sx={{ marginTop: 4 }}>
                <Card sx={cardStyle}>
                    <CardHeader title="Have a Question ?" />
                    <Divider />
                    <CardContent sx={{ minHeight: '130px' }}>
                        <Grid container spacing={1} direction="column">
                            <Grid item>
                                <Typography variant="subtitle1">Explore the knowledgebase ? </Typography>
                            </Grid>
                            <Grid item>
                                <Typography variant="subtitle2">Find answers quickly on common questions</Typography>
                            </Grid>
                        </Grid>
                    </CardContent>
                    <Divider />
                    <CardActions>
                        <Grid container justifyContent="flex-start">
                            <Grid item>
                                <Button variant="outlined" onClick={openModal}>
                                    View Tutorials
                                </Button>
                            </Grid>
                        </Grid>
                    </CardActions>
                </Card>
            </Grid>
        </Grid>
    );
};

export default DashBoard;
