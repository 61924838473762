import ReactQuill from 'react-quill';

import useStore from 'zustand_store/useStore';
import MediaLibray from 'views/components/MediaLibray';
import 'react-quill/dist/quill.snow.css';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';

/**
 * on image icon click
 * Open the modal with params
 * Send server for list of user and public files to include, show them in cards,
 * upon selection of the image, attributes should show up on right panel (like wp)
 * Add a button "Insert" in modal action area
 * Return the file's public path so that the preview can be build
 * Suggest appropriate size
 */

const modules = {
    toolbar: {
        container: [
            ['bold', 'italic', 'underline', 'strike'],
            ['blockquote', 'code-block'],
            [{ header: 1 }, { header: 2 }],
            [{ list: 'ordered' }, { list: 'bullet' }],
            [{ direction: 'rtl' }],
            [{ size: ['small', false, 'large', 'huge'] }],
            [{ align: [] }],
            ['link', 'image', 'video'],
            ['clean'] // remove formatting button
        ],
        handlers: {
            image(file) {
                useStore.setState({ mediaLibraryOpen: true });
            }
        }
    }
};

const RichTextEditor = ({ formik, name, placeholder, value }) => {
    const quillRef = React.useRef(false);
    let reactQuillRef = React.useRef();
    const selectedImage = useStore((state) => state.selectedLibraryImage);
    const handleChange = (value) => {
        formik.setFieldValue(name, value);
    };

    useEffect(() => {
        if (selectedImage.length !== undefined) {
            const range = reactQuillRef.getEditor().getSelection(true);
            reactQuillRef.getEditor().insertEmbed(range.index, 'image', `${selectedImage[0].img}`);
            reactQuillRef.getEditor().formatText(range.index, 1, 'width', '720px');
            useStore.setState({ selectedLibraryImage: {} });
        }
    }, [selectedImage]);

    return (
        <>
            <ReactQuill
                placeholder={placeholder}
                sx={{ minHeight: '150px' }}
                theme="snow"
                ref={(el) => {
                    reactQuillRef = el;
                }}
                modules={modules}
                value={value}
                onChange={handleChange}
            />
            <MediaLibray />
        </>
    );
};

RichTextEditor.propTypes = {
    formik: PropTypes.object.isRequired,
    placeholder: PropTypes.string,
    value: PropTypes.string,
    name: PropTypes.string
};

export default RichTextEditor;
