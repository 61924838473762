/* eslint-disable class-methods-use-this */
import axiosServices from 'utils/axios';

class MiscService {
    async getCountryName(id) {
        const response = await axiosServices.get(`/country/${id}`);
        return response;
    }

    async getDashboardData(userId) {
        const response = await axiosServices.get(`/users/${userId}/dashboard`);
        return response;
    }

    async getTimezones() {
        const response = await axiosServices.get('/timezones');
        return response;
    }
}

export default new MiscService();
