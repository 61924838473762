/* eslint-disable react/self-closing-comp */
import { Button, Grid, IconButton } from '@mui/material';
import PropTypes from 'prop-types';
import { IconCopy, IconDeviceFloppy } from '@tabler/icons';
import Tooltip from '@mui/material/Tooltip';
import { useSnackbar } from 'notistack';
import { useParams } from 'react-router-dom';
import QuestionService from 'services/question.service';
import { LoadingButton } from '@mui/lab';
import { useState } from 'react';

// ==============================|| Main Template ||============================== //

const SaveQuestion = ({ formik }) => {
    const [isLoading, setIsLoading] = useState(false);

    const handleSave = () => {
        setIsLoading(true);
        formik.handleSubmit();
        setIsLoading(false);
    };

    return (
        <Tooltip title="Save Qustion" placement="top">
            <LoadingButton loading={isLoading} onClick={handleSave}>
                <IconDeviceFloppy />
            </LoadingButton>
        </Tooltip>
    );
};

SaveQuestion.propTypes = {
    formik: PropTypes.any
};

export default SaveQuestion;
