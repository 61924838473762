// third-party
import { FormattedMessage } from 'react-intl';

// assets
import {
    IconDashboard,
    IconUser,
    IconUsers,
    IconChartBar,
    IconCash,
    IconCurrencyDollar,
    IconShield,
    IconSettings,
    IconReport,
    IconBrandNetbeans,
    IconBulb,
    IconFileDollar
} from '@tabler/icons';

// constant
const icons = {
    IconDashboard,
    IconChartBar,
    IconCash,
    IconCurrencyDollar,
    IconUser,
    IconUsers,
    IconShield,
    IconReport,
    IconSettings,
    IconBrandNetbeans,
    IconBulb,
    IconFileDollar
};

// eslint-disable-next-line no-unused-expressions
const AdminMenus = [
    {
        id: 'admin',
        mainMenu: true,
        menuTitle: 'Admin Dashboard',
        type: 'item',
        url: '/admin',
        icon: icons.IconDashboard,
        breadcrumbs: false
    },
    {
        id: 'surveys',
        mainMenu: true,
        menuTitle: 'Manage Surveys',
        type: 'item',
        url: '/admin/surveys',
        icon: icons.IconChartBar,
        breadcrumbs: false
    },
    {
        id: 'payments',
        mainMenu: true,
        menuTitle: 'Manage Payment',
        type: 'item',
        url: '/admin/payments',
        icon: icons.IconCurrencyDollar,
        breadcrumbs: false,
        submenus: [
            {
                id: 'payment-menu-one',
                title: <FormattedMessage id="Payments" />,
                type: 'item',
                url: '/admin/payments',
                icon: icons.IconCurrencyDollar,
                breadcrumbs: false
            },
            {
                id: 'payment-menu-two',
                title: <FormattedMessage id="Payments(Cust.)" />,
                type: 'item',
                url: '/admin/payments/custom',
                icon: icons.IconFileDollar,
                breadcrumbs: false
            },
            {
                id: 'payment-menu-three',
                title: <FormattedMessage id="Upcoming" />,
                type: 'item',
                url: '/admin/payments',
                icon: icons.IconCurrencyDollar,
                breadcrumbs: false
            }
        ]
    },
    {
        id: 'users',
        mainMenu: true,
        menuTitle: 'Manage Users',
        type: 'item',
        url: '/admin/users',
        icon: icons.IconUsers,
        breadcrumbs: false,
        submenus: [
            {
                id: 'users',
                title: <FormattedMessage id="All" />,
                type: 'item',
                url: '/admin/users',
                icon: icons.IconUser,
                breadcrumbs: false
            },
            {
                id: 'subscribers',
                title: <FormattedMessage id="Clients" />,
                type: 'item',
                url: '/admin/users/subscribers',
                icon: icons.IconUser,
                breadcrumbs: false
            },
            {
                id: 'respondents',
                title: <FormattedMessage id="Respondents" />,
                type: 'item',
                url: '/admin/users/respondents',
                icon: icons.IconUser,
                breadcrumbs: false
            }
        ]
    },
    {
        id: 'earnings',
        mainMenu: true,
        menuTitle: 'Manage Earnings',
        type: 'item',
        url: '/admin/earnings',
        icon: icons.IconCash,
        breadcrumbs: false
    },
    {
        id: 'reports',
        mainMenu: true,
        menuTitle: 'Reports',
        type: 'item',
        url: '/admin/reports',
        icon: icons.IconReport,
        breadcrumbs: false
    },
    {
        id: 'solr',
        mainMenu: true,
        menuTitle: 'Solr Statistics',
        type: 'item',
        url: '/admin/solr',
        icon: icons.IconBrandNetbeans,
        breadcrumbs: false
        // submenus: [
        //     {
        //         id: 'status',
        //         title: <FormattedMessage id="Solr Status" />,
        //         type: 'item',
        //         url: '/admin/solr/status',
        //         icon: icons.IconBulb,
        //         breadcrumbs: false
        //     }
        // ]
    },
    {
        id: 'settings',
        mainMenu: true,
        menuTitle: 'Manage Settings',
        type: 'item',
        url: '/admin/settings',
        icon: icons.IconSettings,
        breadcrumbs: false,
        submenus: [
            {
                id: 'settings-general',
                title: <FormattedMessage id="General" />,
                type: 'item',
                url: '/admin/settings',
                icon: icons.IconSettings,
                breadcrumbs: false
            },
            {
                id: 'settings-restrictions',
                title: <FormattedMessage id="Restrictions" />,
                type: 'item',
                url: '/admin/settings/restrictions',
                icon: icons.IconSettings,
                breadcrumbs: false
            }
        ]
    }
];

export default AdminMenus;
