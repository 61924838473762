import SaveSharpIcon from '@mui/icons-material/SaveSharp';
import { Button, CardActions, CardContent, Divider, Grid, IconButton, Modal, Alert } from '@mui/material';
import { IconX } from '@tabler/icons';
// import { makeStyles } from '@material-ui/styles';
import { Form, Formik } from 'formik';
import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import SurveyService from 'services/survey.service';
import MainCard from 'ui-component/cards/MainCard';
import { isEmpty } from 'lodash';
import useRestrictionStore from 'zustand_store/useRestrictionStore';
import RuleComponent from './RuleComponent';
import { margin } from '@mui/system';
import ListSkeleton from 'views/ListSkeleton';

// modal position
function getModalStyle() {
    const top = 50;
    const left = 50;

    return {
        top: `${top}%`,
        left: `${left}%`,
        transform: `translate(-${top}%, -${left}%)`
    };
}

// modal content
const Body = React.forwardRef((props, ref) => {
    const { open, setOpen, handleOpen, handleClose, surveyToken, allQuesData, setAllQuesData, modalStyle, classes, survey } = props;

    const [questionLoaded, setQuestionLoaded] = useState(false);
    const [allQuestitle, setAllQuestitle] = useState([]);
    const [choiceMap, setChoiceMap] = useState({});
    const saveButton = useRef(null);
    // const ruleEngine = [];
    const restrictionStore = useRestrictionStore((state) => state.ruleEngine);
    const ruleEngine = restrictionStore?.find((element) => Number(Object.keys(element)[0]) === survey.id)
        ? Object.values(restrictionStore?.find((element) => Number(Object.keys(element)[0]) === survey.id))[0]
        : [];

    const { enqueueSnackbar } = useSnackbar();

    const ADD_RULENGINE = useRestrictionStore((state) => state.ADD_RULENGINE);

    const allQtitle = useCallback(() => allQuestitle, [allQuestitle]);
    const [formikInit, setFormikInit] = useState(ruleEngine || []);

    const fetchAllQuestion = async () => {
        const { data } = await SurveyService.showQuestions(surveyToken);
        setAllQuesData(data.data);
        setAllQuestitle(data.data?.map((questionData) => questionData.question_title));
        setQuestionLoaded(true);
    };

    const onRestrictionModalSubmit = async (values) => {
        values.restrictions = values.restrictions?.map((restrictionProperties, index) => {
            const findQuesId = allQuesData[index]?.id;
            const findQuesOrder = allQuesData[index]?.order;

            return { ...restrictionProperties, qID: findQuesId, qOrder: findQuesOrder };
        });

        // Obligatory Part
        const resp = await SurveyService.postSurveyRestrictions(surveyToken, values.restrictions);
        handleClose();
        ADD_RULENGINE(survey.id, values.restrictions);
        enqueueSnackbar('Logical Restrictions saved successfully', { variant: 'success', autoHideDuration: 3000 });
    };

    useEffect(() => {
        fetchAllQuestion();
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (allQuesData.length > 0) {
            allQuesData.map((questionData) => {
                const { meta } = questionData;
                setChoiceMap((prevChoice) => ({
                    ...prevChoice,
                    [questionData.id]: meta?.choices
                }));
                return null;
            });

            if (!Array.isArray(Object.values(formikInit)) || !Object.values(formikInit).length || survey.meta.rule_engine) {
                const emptyArr = allQtitle().length - Object.values(formikInit).length;

                for (let i = 0; i < emptyArr; i += 1) {
                    setFormikInit((prevState) => [...prevState, {}]);
                }
            }
        }
    }, [allQuestitle, allQuesData]); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <div ref={ref} tabIndex={-1}>
            <MainCard
                style={{
                    width: '1200px',
                    maxWidth: '100vw',
                    maxHeight: '100%',
                    position: 'fixed',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    overflowY: 'auto'
                }}
                title="Branching and Calculation"
                content={false}
                secondary={
                    <IconButton onClick={handleClose} aria-label="restriction-modal-close">
                        <IconX />
                    </IconButton>
                }
                data-testid="main-testing-div"
            >
                <CardContent style={{ padding: '0', background: '#eee' }}>
                    {!questionLoaded ? (
                        <ListSkeleton number={10} />
                    ) : (
                        <Grid
                            style={{
                                alignItem: 'center',
                                height: '60vh',
                                width: '100%',
                                overflowY: 'scroll'
                            }}
                        >
                            {allQuestitle.length === Object.values(formikInit).length &&
                                allQuesData.length === Object.values(formikInit).length && (
                                    <Formik
                                        initialValues={{ restrictions: formikInit }}
                                        onSubmit={(values) => onRestrictionModalSubmit(values)}
                                        validateOnChange={false}
                                        validateOnBlur
                                        enableReinitialize={false}
                                    >
                                        <Form>
                                            <Grid>
                                                {Object.values(formikInit)?.map((_, index) => (
                                                    <Grid key={index}>
                                                        {allQuesData && !isEmpty(allQuesData) && allQuesData?.length > 1 && (
                                                            <RuleComponent
                                                                allQuesData={allQuesData}
                                                                allQtitle={allQtitle}
                                                                choiceMap={choiceMap}
                                                                index={index}
                                                                survey={survey}
                                                                data-testid="rule-component"
                                                            />
                                                        )}
                                                    </Grid>
                                                ))}
                                            </Grid>
                                            <Grid>
                                                <Button ref={saveButton} type="submit" style={{ display: 'None' }}>
                                                    Save
                                                </Button>
                                            </Grid>
                                        </Form>
                                    </Formik>
                                )}
                            {questionLoaded && allQuesData?.length < 2 && (
                                <Alert variant="filled" severity="error" style={{ margin: '10px' }}>
                                    To use the branching and calculation feature, at least two questions are needed
                                </Alert>
                            )}
                        </Grid>
                    )}
                </CardContent>

                <Divider />

                <CardActions>
                    <Grid container justifyContent="space-between">
                        <Button color="error" variant="outlined" role="button" type="button" onClick={handleClose}>
                            Cancel
                        </Button>

                        <Button
                            variant="contained"
                            size="large"
                            color="primary"
                            disabled={questionLoaded && allQuesData?.length < 2}
                            startIcon={<SaveSharpIcon />}
                            onClick={() => {
                                saveButton.current.click();
                            }}
                        >
                            Save
                        </Button>
                    </Grid>
                </CardActions>
            </MainCard>
        </div>
    );
});

Body.propTypes = {
    modalStyle: PropTypes.object,
    classes: PropTypes.object,
    handleClose: PropTypes.func,
    open: PropTypes.bool,
    setOpen: PropTypes.func,
    handleOpen: PropTypes.func,
    surveyToken: PropTypes.string,
    allQuesData: PropTypes.array,
    setAllQuesData: PropTypes.func,
    survey: PropTypes.object
};

// ===========================|| SIMPLE MODAL ||=========================== //

export default function SimpleModal(props) {
    const { open } = props;
    // const classes = useStyles();

    // getModalStyle is not a pure function, we roll the style only on the first render
    const [modalStyle] = React.useState(getModalStyle);

    return (
        <Grid container justifyContent="flex-end">
            <Modal open={open} aria-labelledby="simple-modal-title" aria-describedby="simple-modal-description">
                <Body
                    {...props}
                    style={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        bgcolor: 'background.paper',
                        boxShadow: 24,
                        borderRadius: '3px',
                        width: '896px'
                        // maxHeight: '100%',
                    }}
                    modalStyle={modalStyle}
                />
            </Modal>
        </Grid>
    );
}

SimpleModal.propTypes = {
    modalStyle: PropTypes.any,
    handleClose: PropTypes.func,
    open: PropTypes.bool,
    setOpen: PropTypes.func,
    handleOpen: PropTypes.func,
    surveyToken: PropTypes.string,
    allQuesData: PropTypes.array,
    setAllQuesData: PropTypes.func,
    ruleEngine: PropTypes.array
};
