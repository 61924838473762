import Loadable from 'ui-component/Loadable';
import { lazy } from 'react';
// project imports
import AdminLayout from 'layout/AdminLayout';
import AuthGuard from 'utils/route-guard/AuthGuard';

const AdminUsers = Loadable(lazy(() => import('views/admin/AdminUsers')));
const AdminReports = Loadable(lazy(() => import('views/admin/Reports')));
const AdminSettings = Loadable(lazy(() => import('views/admin/AdminSettings')));
const AdminSettingsRestrictions = Loadable(lazy(() => import('views/admin/AdminSettingsRestrictions')));
const AdminSubscribers = Loadable(lazy(() => import('views/admin/AdminSubscribers')));
const AdminRespondents = Loadable(lazy(() => import('views/admin/AdminRespondents')));
const AdminDashboard = Loadable(lazy(() => import('views/admin/Analytics')));
const AdminEditUser = Loadable(lazy(() => import('views/admin/AdminEditUser')));
const AdminPayments = Loadable(lazy(() => import('views/admin/AdminPayments')));
const AdminCustomPayments = Loadable(lazy(() => import('views/admin/AdminCustomPayments')));
const AdminPaymentView = Loadable(lazy(() => import('views/admin/AdminPaymentView')));
const AdminEarnings = Loadable(lazy(() => import('views/admin/AdminEarnings')));
const AdminSurveys = Loadable(lazy(() => import('views/admin/AdminSurveys')));
const SolrStatistics = Loadable(lazy(() => import('views/admin/solr/SolrStatistics')));
const AdminAddSubscribers = Loadable(lazy(() => import('views/admin/AdminAddSubscribers')));

const AdminRoutes = {
    path: '/admin',
    element: (
        <AuthGuard>
            <AdminLayout />
        </AuthGuard>
    ),
    children: [
        {
            path: '',
            element: <AdminDashboard />
        },
        {
            path: 'users',
            children: [
                {
                    path: '',
                    element: <AdminUsers />
                },
                {
                    path: 'subscribers',
                    children: [
                        {
                            path: '',
                            element: <AdminSubscribers />
                        },
                        {
                            path: 'add',
                            element: <AdminAddSubscribers />
                        }
                    ]
                },
                {
                    path: 'respondents',
                    element: <AdminRespondents />
                },
                {
                    path: 'edit/:id',
                    element: <AdminEditUser />
                }
            ]
        },
        {
            path: 'solr',
            element: <SolrStatistics />
        },
        {
            path: 'payments',
            children: [
                {
                    path: '',
                    element: <AdminPayments />
                },
                {
                    path: 'custom',
                    element: <AdminCustomPayments />
                },
                {
                    path: 'view/:id',
                    element: <AdminPaymentView />
                }
            ]
        },
        {
            path: 'earnings',
            children: [
                {
                    path: '',
                    element: <AdminEarnings />
                }
            ]
        },
        {
            path: 'surveys',
            element: <AdminSurveys />
        },
        {
            path: 'settings',
            children: [
                {
                    path: '',
                    element: <AdminSettings />
                },
                {
                    path: 'restrictions',
                    element: <AdminSettingsRestrictions />
                }
            ]
        },
        {
            path: 'reports',
            element: <AdminReports />
        }
    ]
};

export default AdminRoutes;
