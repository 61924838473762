import { useRoutes } from 'react-router-dom';

// routes
import MainRoutes from './MainRoutes';
import LoginRoutes from './LoginRoutes';
import AuthenticationRoutes from './AuthenticationRoutes';
import OtherRoutes from './OtherRoutes';
import AdminRoutes from './AdminRoutes';
import AdvanceFormCreateRoute from './AdvanceFormCreateRoute';

// ==============================|| ROUTING RENDER ||============================== //

export default function ThemeRoutes() {
    return useRoutes([MainRoutes, LoginRoutes, AuthenticationRoutes, OtherRoutes, AdminRoutes, AdvanceFormCreateRoute]);
}
