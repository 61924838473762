import React from 'react';

// material-ui
import { useTheme } from '@mui/styles';
import { Typography, Button, Grid, TextField } from '@mui/material';
import * as yup from 'yup';
import Password from '@mui/icons-material/Password';
// project imports
import AnimateButton from 'ui-component/extended/AnimateButton';
import { gridSpacing } from 'store/constant';
import { useSnackbar } from 'notistack';
import ProfileService from 'services/profile.service';
import { useFormik } from 'formik';
import ProfileSkeleton from './ProfileSkeleton';

// ===========================|| PROFILE 1 - CHANGE PASSWORD ||=========================== //

const ChangePassword = () => {
    const theme = useTheme();
    const { enqueueSnackbar } = useSnackbar();
    const [mainData, setMainData] = React.useState({
        current_password: '',
        new_password: '',
        new_password_confirmation: ''
    });

    const [data, setData] = React.useState(mainData);
    const [isLoading, setIsLoading] = React.useState(true);

    const handleSubmit = async (event) => {
        event.preventDefault();
        await ProfileService.changePassword(data);
        enqueueSnackbar('Password changed successfully', { variant: 'success' });
        setData(mainData);
    };

    const clearInput = () => {
        setData(mainData);
    };

    const validationSchema = yup.object({
        current_password: yup.string().required('No password provided.').min(6, 'Password is too short - should be 6 charaters minimum.'),
        new_password: yup.string().required('New Password is required').min(6, 'Password is too short - should be 6 charaters minimum.'),
        new_password_confirmation: yup
            .string()
            .required('New Password must match')
            .oneOf([yup.ref('new_password'), null], 'New Password must match')
            .min(6, 'Password is too short - should be 6 charaters minimum.')
    });

    const formik = useFormik({
        initialValues: mainData,
        enableReinitialize: true,
        validateOnChange: false, // this one
        validateOnBlur: false, // and this one
        validationSchema,
        onSubmit: async (values) => {
            await ProfileService.changePassword(values);
            enqueueSnackbar('Password changed successfully', { variant: 'success' });
        }
    });

    React.useEffect(() => {
        setIsLoading(false);
    }, []);

    return (
        <Grid container spacing={gridSpacing}>
            {isLoading ? (
                <ProfileSkeleton number={6} />
            ) : (
                <>
                    <Grid item alignContent="left" xs={12}>
                        <Typography variant="h3" pt={1}>
                            Change Password
                        </Typography>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <form onSubmit={formik.handleSubmit}>
                            <Grid container spacing={gridSpacing} sx={{ mb: '14px' }}>
                                <Grid item xs={12}>
                                    <TextField
                                        type="password"
                                        id="outlined-basic7"
                                        // value={data.current_password ?? ''}
                                        fullWidth
                                        label="Current Password"
                                        name="current_password"
                                        onChange={formik.handleChange}
                                        value={formik.values.current_password}
                                        error={formik.touched.current_password && Boolean(formik.errors.current_password)}
                                        helperText={formik.touched.current_password && formik.errors.current_password}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        name="new_password"
                                        type="password"
                                        fullWidth
                                        label="New Password"
                                        onChange={formik.handleChange}
                                        value={formik.values.new_password}
                                        error={formik.touched.new_password && Boolean(formik.errors.new_password)}
                                        helperText={formik.touched.new_password && formik.errors.new_password}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        name="new_password_confirmation"
                                        type="password"
                                        fullWidth
                                        label="Confirm Password"
                                        onChange={formik.handleChange}
                                        value={formik.values.new_password_confirmation}
                                        error={formik.touched.new_password_confirmation && Boolean(formik.errors.new_password_confirmation)}
                                        helperText={formik.touched.new_password_confirmation && formik.errors.new_password_confirmation}
                                    />
                                </Grid>
                            </Grid>
                            <Grid spacing={2} container justifyContent="flex-start" sx={{ mt: 3 }}>
                                <Grid item>
                                    <AnimateButton>
                                        <Button startIcon={<Password />} variant="contained" type="submit">
                                            Change Password
                                        </Button>
                                    </AnimateButton>
                                </Grid>
                                {/* <Grid item>
                            <Button variant="outlined" color="primary" onClick={clearInput}>
                                Clear
                            </Button>
                        </Grid> */}
                            </Grid>
                        </form>
                    </Grid>
                </>
            )}
        </Grid>
    );
};

export default ChangePassword;
