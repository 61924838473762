import React, { useState } from 'react';

// material-ui
import { useTheme } from '@mui/material/styles';
import { Alert, AlertTitle, Button, Grid, MenuItem, Stack, Typography, TextField } from '@mui/material';

import AnimateButton from 'ui-component/extended/AnimateButton';
import { gridSpacing } from 'store/constant';
import GeneralSettingsService from 'services/profile.service';
import Save from '@mui/icons-material/Save';
// assets
import { DatePicker, LocalizationProvider } from '@mui/lab';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import useStore from 'zustand_store/useStore';

// third-party
import { useFormik } from 'formik';
import * as yup from 'yup';
import { useSnackbar } from 'notistack';
import ProfileSkeleton from './ProfileSkeleton';
import { Upload } from '@mui/icons-material';
import Loader from 'views/components/Loader';
import GeneralService from 'services/general.service';

// Formik - form
const validationSchema = yup.object({
    first_name: yup.string().required('First name is required'),
    last_name: yup.string().required('Last Name is required'),
    email: yup.string('Enter your email').email('Enter a valid email').required('Email is required'),
    gender: yup.string().required('Gender is required'),
    date_of_birth: yup
        .date()
        .transform((v) => (v instanceof Date && !Number.isNaN(v) ? v : null))
        .required('Date of Birth field is Required')
        .typeError('Date of Birth field is Required and must be in date format')
});

// ===========================|| PROFILE 1 - MY ACCOUNT ||=========================== //

const MyAccount = () => {
    const theme = useTheme();
    const { enqueueSnackbar } = useSnackbar();
    const [isLoading, setIsLoading] = useState(true);
    const [loaderOpen, setLoaderOpen] = useState(false);

    const [gender, setGender] = React.useState([
        {
            label: 'Male',
            value: 'm'
        },
        {
            label: 'Female',
            value: 'f'
        }
    ]);

    const [generalSetting, setGeneralSetting] = React.useState({
        first_name: '',
        last_name: '',
        email: '',
        gender: '',
        date_of_birth: '',
        subscribed: false
    });

    const fetchData = async () => {
        const { data } = await GeneralSettingsService.get();
        setGeneralSetting(data);
        setIsLoading(false);
    };

    const formik = useFormik({
        initialValues: generalSetting,
        enableReinitialize: true,
        validationSchema,
        onSubmit: async (values) => {
            values.subscribed = values?.subscribed?.subscribed ? 1 : 0;
            const response = await GeneralSettingsService.update(values);
            enqueueSnackbar('Profile Updated Successfully!', { variant: 'success', autoHideDuration: 3000 });
            useStore.setState({ userEditDrawerOpen: false });
        }
    });

    const handleUploadClick = (event) => {
        const file = event.target.files[0];
        const reader = new FileReader();
        if (file.size / 1024 / 1024 > 5) {
            enqueueSnackbar('Image must be less than 5MB !', { variant: 'error', autoHideDuration: 3000 });
        } else {
            setLoaderOpen(true);
            reader.onloadend = async () => {
                const fd = new FormData();
                fd.append('image', file);

                const { data: imageLink } = await GeneralService.storeImage(fd, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                });
                formik.setFieldValue('meta.logo', `${process.env.REACT_APP_BACKEND_BASE}/storage/${imageLink}`);
                setLoaderOpen(false);
            };

            reader.readAsDataURL(file);
        }
    };

    React.useEffect(() => {
        fetchData();
    }, []);

    return (
        <Grid container spacing={gridSpacing}>
            {isLoading ? (
                <ProfileSkeleton number={6} />
            ) : (
                <>
                    <Grid item xs={12} md={6}>
                        <Grid alignContent="left" mb={3}>
                            <Typography variant="h3" pt={0}>
                                My Account
                            </Typography>
                        </Grid>
                        <form autoComplete="off" onSubmit={formik.handleSubmit}>
                            <Grid container spacing={gridSpacing}>
                                <Grid item xs={12}>
                                    <TextField
                                        id="outlined-basic5"
                                        fullWidth
                                        label="First Name"
                                        value={formik.values.first_name}
                                        onChange={formik.handleChange}
                                        name="first_name"
                                        error={formik.touched.first_name && Boolean(formik.errors.first_name)}
                                        helperText={formik.touched.first_name && formik.errors.first_name}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        id="outlined-basic6"
                                        fullWidth
                                        label="last Name"
                                        value={formik.values.last_name}
                                        onChange={formik.handleChange}
                                        name="last_name"
                                        error={formik.touched.last_name && Boolean(formik.errors.last_name)}
                                        helperText={formik.touched.last_name && formik.errors.last_name}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        id="outlined-basic7"
                                        fullWidth
                                        label="Account Email"
                                        value={formik.values.email}
                                        onChange={formik.handleChange}
                                        name="email"
                                        error={formik.touched.email && Boolean(formik.errors.email)}
                                        helperText={formik.touched.email && formik.errors.email}
                                        disabled
                                    />
                                </Grid>

                                <Grid item xs={12}>
                                    <TextField
                                        id="outlined-select-experience"
                                        fullWidth
                                        select
                                        label="Gender"
                                        value={formik.values.gender || ''}
                                        onChange={formik.handleChange}
                                        name="gender"
                                        error={formik.touched.gender && Boolean(formik.errors.gender)}
                                        helperText={formik.touched.gender && formik.errors.gender}
                                    >
                                        {gender.map((option) => (
                                            <MenuItem key={option.value} value={option.value}>
                                                {option.label}
                                            </MenuItem>
                                        ))}
                                    </TextField>
                                </Grid>

                                <Grid item xs={12}>
                                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                                        <DatePicker
                                            renderInput={(props) => (
                                                <TextField
                                                    fullWidth
                                                    {...props}
                                                    name="date_of_birth"
                                                    error={Boolean(formik.errors.date_of_birth)}
                                                    helperText={formik.touched.date_of_birth && formik.errors.date_of_birth}
                                                />
                                            )}
                                            label="Date Of Birth"
                                            value={formik.values.date_of_birth}
                                            onChange={(value) => formik.setFieldValue('date_of_birth', value)}
                                        />
                                    </LocalizationProvider>
                                </Grid>
                                <Grid item xs={12}>
                                    <Loader loaderOpen={loaderOpen} />

                                    <Button startIcon={<Upload />} variant="outlined" component="label">
                                        Upload a logo
                                        <input
                                            accept="image/*"
                                            id="upload"
                                            type="file"
                                            onChange={(event) => handleUploadClick(event)}
                                            hidden
                                        />
                                    </Button>

                                    {formik?.values?.meta?.logo && (
                                        <Grid
                                            style={{
                                                margin: '20px',
                                                height: '200px',
                                                display: 'flex',
                                                alignItems: 'center',
                                                justifyContent: 'center',
                                                overflow: 'hidden'
                                            }}
                                        >
                                            <img
                                                src={formik?.values?.meta?.logo}
                                                style={{
                                                    display: 'block',
                                                    maxWidth: '100%',
                                                    maxHeight: '100%',
                                                    minHeight: '1px'
                                                }}
                                                alt="images"
                                            />
                                        </Grid>
                                    )}
                                </Grid>
                                <Grid item xs={12}>
                                    <Stack direction="row" justifyContent="flex-start">
                                        <AnimateButton>
                                            <Button startIcon={<Save />} variant="contained" type="submit">
                                                Save Changes
                                            </Button>
                                        </AnimateButton>
                                    </Stack>
                                </Grid>
                            </Grid>
                        </form>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Typography variant="h3" mb={3}>
                            Subscription Settings
                        </Typography>
                        <Grid item alignContent="right">
                            {generalSetting?.subscription?.subscribed && !generalSetting?.subscription?.incomplete ? (
                                <Alert icon={false} variant="outlined" severity="success">
                                    Subscription : <b>Active</b> <br />
                                    Plan : <b>{generalSetting.subscription.current_plan}</b>
                                </Alert>
                            ) : (
                                <>
                                    {generalSetting?.subscription.incomplete && (
                                        <Alert severity="warning" variant="outlined" icon={false}>
                                            <AlertTitle>Important!</AlertTitle>
                                            You have an incomplete payment. Click the upgrade button to complete the payment.
                                        </Alert>
                                    )}
                                </>
                            )}
                            {!generalSetting.subscription?.subscribed && (
                                <Alert severity="error" variant="outlined" icon={false}>
                                    <AlertTitle>Oops!</AlertTitle>
                                    You are not subscribed to any plan currently. Use the upgrade button to subscribe.
                                </Alert>
                            )}
                            <Button
                                style={{ background: theme.palette.error.main, borderColor: '#EDE7F6' }}
                                variant="contained"
                                sx={{
                                    marginTop: 2,
                                    ':hover': {
                                        boxShadow: 'none'
                                    }
                                }}
                                href="/packages"
                            >
                                Upgrade
                            </Button>
                        </Grid>
                    </Grid>
                </>
            )}
        </Grid>
    );
};

export default MyAccount;
