function transformer(data, questionData) {
    const values = [];
    const labels = [];
    const percentage = [];
    Object.values(data).map((element) => {
        labels.push(element.choice);
        values.push(element.count);
        percentage.push(element.percentage);
        return null;
    });

    // eslint-disable-next-line no-sequences
    return [labels, values, percentage];
}

export default transformer;
