import { find, findIndex } from 'lodash';
import QuestionAnswerFinder from './QuestionAnswerFinder';

const yupInitializer = (yup, object) => {
    const pup = yup;
    // eslint-disable-next-line no-eval
    return Object.assign(...Object.keys(object).map((key) => ({ [key]: eval(`pup.${object[key]}`) })));
};

function setLocalStorage(key, value) {
    window.localStorage.setItem(key, value);
}

function getLocalStorage(key, value) {
    window.localStorage.getItem(key, value);
}

// eslint-disable-next-line consistent-return
function addOrReplaceObjectPropertyInResponse({ state, questionId, answer, answeredAt }, local = false) {
    const currentQuestion = find(state.questions, { id: questionId });
    const findIfIdExist = find(state.responseContainer.responses, {
        qID: questionId
    });

    if (findIfIdExist) {
        const currentQuestionIndex = findIndex(state.responseContainer.responses, {
            qID: questionId
        });

        state.responseContainer.responses[currentQuestionIndex] = {
            qID: questionId,
            answer,
            answeredAt,
            identifier: currentQuestion.meta.identifier,
            key: currentQuestion.meta?.field_key || ''
        };
    } else {
        state.responseContainer.responses.push({
            qID: questionId,
            answer,
            answeredAt,
            identifier: currentQuestion.meta.identifier,
            key: currentQuestion.meta?.field_key || ''
        });
    }
    if (local) {
        return state;
    }
}

function compareGenerator(
    assignmentOperatorComparer,
    condition,
    rule,
    responseContainer,
    conditionQuestionResponse,
    conditionQuestionResponseIndex,
    questions,
    index
) {
    //  Get Current Question Information from State
    const allCurrentQuestionData = find(questions, { id: condition.ques });
    //  Find out Current Question Answer
    const currentQuestionAnswerFinder = new QuestionAnswerFinder(allCurrentQuestionData, conditionQuestionResponse);
    const currentQuestionAnswer = currentQuestionAnswerFinder[allCurrentQuestionData.meta.identifier]
        ? currentQuestionAnswerFinder[allCurrentQuestionData.meta.identifier]()
        : currentQuestionAnswerFinder.GenericQuestion();

    // if there is Next Question Answer
    // if (rule.cond[index + 1]) {
    //     //  Find Out Next Question Information from State
    //     const allNextQuestionData = find(questions, { id: rule.cond[index + 1].ques });

    //     //  Find out Next Question Answer
    //     const nextQuestionAnswerFinder = new QuestionAnswerFinder(
    //         allNextQuestionData,
    //         responseContainer?.responses[index + 1]
    //     );
    //     const nextQuestionAnswer = !!nextQuestionAnswerFinder[allNextQuestionData.meta.identifier]
    //         ? nextQuestionAnswerFinder[allNextQuestionData.meta.identifier]()
    //         : nextQuestionAnswerFinder.GenericQuestion();
    // }

    // If next Rule Condition, available check by index
    if (rule.cond[index + 1]) {
        //  Find Out Next Question Information from State
        const allNextQuestionData = find(questions, { id: rule.cond[index + 1].ques });

        //  Find out Next Question Answer
        const nextQuestionAnswerFinder = new QuestionAnswerFinder(allNextQuestionData, responseContainer?.responses[index + 1]);
        const nextQuestionAnswer = nextQuestionAnswerFinder[allNextQuestionData.meta.identifier]
            ? nextQuestionAnswerFinder[allNextQuestionData.meta.identifier]()
            : nextQuestionAnswerFinder.GenericQuestion();

        if (condition.logicalOp === 'or') {
            return (
                assignmentOperatorComparer.compare(condition.val, currentQuestionAnswer, condition.assignmentOp) ||
                assignmentOperatorComparer.compare(rule.cond[index + 1].val, nextQuestionAnswer, rule.cond[index + 1].assignmentOp)
            );
        }
        if (condition.logicalOp === 'and') {
            return (
                assignmentOperatorComparer.compare(condition.val, currentQuestionAnswer, condition.assignmentOp) &&
                assignmentOperatorComparer.compare(rule.cond[index + 1].val, nextQuestionAnswer, rule.cond[index + 1].assignmentOp)
            );
        }
        return assignmentOperatorComparer.compare(condition.val, currentQuestionAnswer, condition.assignmentOp);
    }
    return assignmentOperatorComparer.compare(condition.val, currentQuestionAnswer, condition.assignmentOp);
}

function redirectBasedOnUserRole(role) {
    if (role.includes('Client')) {
        window.location.href = process.env.REACT_APP_CLIENT_APP;
    }
    window.location.href = process.env.REACT_APP_RESPONDENT_APP;
}

export {
    yupInitializer,
    setLocalStorage,
    getLocalStorage,
    addOrReplaceObjectPropertyInResponse,
    compareGenerator,
    redirectBasedOnUserRole
};
