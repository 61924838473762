/* eslint-disable class-methods-use-this */
import axiosServices from 'utils/axios';
import { isUndefined } from 'lodash';

class ProfileService {
    async update(user, userId) {
        const url = isUndefined(userId) ? 'profile/update' : `admin/user-update/${userId}`;
        return axiosServices.put(url, user).then((response) => {});
    }

    async get(userId) {
        const url = isUndefined(userId) ? 'profile' : `admin/user/${userId}`;
        const response = await axiosServices.get(url);
        return response;
    }

    async changePassword(data) {
        const response = await axiosServices.post('change-password', data);
        return response;
    }

    async subscribe(data) {
        const response = await axiosServices.post('/user/subscribe', data);
        return response;
    }
}

export default new ProfileService();
