import dayjs from 'dayjs';
import { find } from 'lodash';

class QuestionAnswerFinder {
    constructor(currentQuestion, ansData) {
        this.currentQuestion = currentQuestion;
        this.ansData = ansData;
    }

    GenericQuestion() {
        return this.ansData.answer || '';
    }

    McqQuestion() {
        return this.ansData.answer ? find(this.currentQuestion.choices, { id: Number(this.ansData.answer.value) }).choice : '';
    }

    DateQuestion() {
        return this.ansData.answer ? dayjs(this.ansData.answer).format(this.currentQuestion.meta?.date_format.toUpperCase()) : '';
    }

    DropdownQuestion() {
        return this.ansData.answer ? find(this.currentQuestion.choices, { id: Number(this.ansData.answer.value) }).choice : '';
    }

    ImagePickerQuestion() {
        return this.ansData.answer ? find(this.currentQuestion.choices, { id: Number(this.ansData.answer.value) }).choice : '';
    }

    CountryQuestion() {
        return this.ansData.answer ? this.ansData.answer.label : '';
    }

    StateQuestion() {
        return this.ansData.answer ? this.ansData.answer.label : '';
    }

    BooleanQuestion() {
        if (this.ansData.answer) {
            const yesLabel = this.currentQuestion.meta.yesLabel;
            const noLabel = this.currentQuestion.meta.noLabel;
            // return Number(this.ansData.answer) === 1 ? yesLabel : noLabel;
            return this.ansData.answer;
        }
        return this.ansData.answer ? this.ansData.answer.label : '';
    }
}

export default QuestionAnswerFinder;
