import { useState, useEffect } from 'react';
import SurveyService from 'services/survey.service';
import PropTypes from 'prop-types';
import { useParams, Link } from 'react-router-dom';
import { Tabs, Tab, Box, Typography, Grid, Button } from '@mui/material';
import LayersTwoToneIcon from '@mui/icons-material/ArrowBackTwoTone';
import DetailedReport from './detailed-report';
import Summary from './detailed-report/summary';
import ResponseSummary from './detailed-report/ResponseSummary';
import ResponseInsights from './detailed-report/ResponseInsights';
import DownloadableResponse from './detailed-report/DownloadableResponse';

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div role="tabpanel" hidden={value !== index} id={`survey-tabpanel-${index}`} aria-labelledby={`survey-tab-${index}`} {...other}>
            {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired
};

function a11yProps(index) {
    return {
        id: `survey-tab-${index}`,
        'aria-controls': `survey-tabpanel-${index}`
    };
}

const getPercentWidth = (w = 100, n = 60, x = 30) => {
    const percentWidth = Math.round((x / (w / n)) * 100);
    return `${percentWidth}%`;
};
const colWidth = getPercentWidth();
const yearArray = [];

for (let i = 0; i < 60; i += 1) {
    yearArray.push(i);
}

const Report = () => {
    const [value, setValue] = useState(0);
    const { surveyToken } = useParams();
    const [reportSummary, setReportSummary] = useState();
    const [responses, setResponses] = useState();
    const [noReponseText, setNoReponseText] = useState('No Response was was given for this question');
    const [survey, setSurvey] = useState({
        survey_title: '',
        survey_description: '',
        quota: 0,
        start_date: null,
        end_date: null,
        survey_type: '',
        survey_status: '',
        show_in_listing: true,
        meta: {
            save_as_template: false,
            public_survey: true,
            anon_survey: false
        }
    });
    const loadSurveyInfo = async () => {
        const response = await SurveyService.show(surveyToken);
        setSurvey(response.data);
    };

    useEffect(() => {
        loadSurveyInfo();
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const data = {
        series: [
            {
                data: yearArray
            },
            {
                data: yearArray
            }
        ],

        options: {
            chart: {
                width: '100px',
                height: '10000px',
                type: 'bar',
                zoom: {
                    enabled: true,
                    type: 'x',
                    autoScaleYaxis: false,
                    zoomedArea: {
                        fill: {
                            color: '#90CAF9',
                            opacity: 0.4
                        },
                        stroke: {
                            color: '#0D47A1',
                            opacity: 0.4,
                            width: 1
                        }
                    }
                }
            },
            plotOptions: {
                bar: {
                    horizontal: true,
                    columnWidth: colWidth,
                    barHeight: '70%',
                    dataLabels: {
                        position: 'top'
                    }
                }
            },
            dataLabels: {
                enabled: true,
                offsetX: -6,
                style: {
                    fontSize: '12px',
                    colors: ['#fff']
                }
            },
            stroke: {
                show: true,
                width: 0,
                colors: ['#fff']
            },
            tooltip: {
                shared: true,
                intersect: false
            },
            xaxis: {
                categories: yearArray
            }
        }
    };

    return (
        <>
            <Grid container direction="row" justifyContent="space-between" alignItems="center" mb={2}>
                <Grid item xs={6}>
                    <Typography variant="h3">Report for [ {`${survey.survey_title}`} ]</Typography>
                </Grid>
                <Grid item xs={6} textAlign="right">
                    <Button
                        startIcon={<LayersTwoToneIcon />}
                        component={Link}
                        color="secondary"
                        to={`/surveys/${survey.survey_token}/view`}
                    >
                        Back to overview
                    </Button>
                </Grid>
            </Grid>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs value={value} onChange={handleChange} variant="fullWidth">
                    <Tab label="Summary" {...a11yProps(0)} />
                    <Tab label="Detailed" {...a11yProps(1)} />
                    <Tab label="All Responses" {...a11yProps(2)} />
                    {survey.survey_type === 'Assessment' && <Tab label="Insights" {...a11yProps(3)} />}
                </Tabs>
            </Box>

            <TabPanel value={value} index={0}>
                <Summary />
            </TabPanel>
            <TabPanel value={value} index={1}>
                <DetailedReport responses={responses} responsesStarted={reportSummary && reportSummary.started} />
            </TabPanel>
            <TabPanel value={value} index={2}>
                <ResponseSummary responses={responses} reportSummary={reportSummary} />
            </TabPanel>
            <TabPanel value={value} index={3}>
                {survey.survey_type === 'Assessment' && <ResponseInsights responses={responses} reportSummary={reportSummary} />}
            </TabPanel>
        </>
    );
};

export default Report;
