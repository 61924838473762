/* eslint-disable react/self-closing-comp */
import {
    Button,
    Card,
    CardActions,
    CardContent,
    Dialog,
    DialogActions,
    DialogContent,
    Grid,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    Typography
} from '@mui/material';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import SurveyService from 'services/survey.service';
import useOnePageSurveyStore from 'zustand_store/useOnePageSurveyStore';
import snackcaseToPascalcase from 'utils/helperFunctions';
import useQuestionDrawerStore from 'zustand_store/useQuestionDrawerStrore';
import Item from '../Item';

// ==============================|| Main Template ||============================== //

const QuestionThemes = () => {
    const [templates, useTemplates] = useState([
        {
            name: 'Default Theme',
            background: '#fff',
            color: '#000'
        },
        {
            name: 'Dark Theme',
            background: '#000',
            color: '#fff'
        }
    ]);

    return (
        <Item
            style={{
                border: '0'
            }}
        >
            <Typography variant="h5" align="left">
                Question Themes
            </Typography>
            <List>
                {templates?.map((template, index) => (
                    <Card sx={{ margin: '10px 0' }} key={index}>
                        <CardContent
                            sx={{
                                background: template.background,
                                color: template.color,
                                padding: '10px',
                                borderBottom: '1px solid #ddd'
                            }}
                        >
                            <Typography gutterBottom>Question</Typography>
                            <Typography variant="h5" component="div" sx={{ color: template.color }}>
                                Answer
                            </Typography>
                        </CardContent>
                        <CardActions sx={{ padding: '10px' }}>
                            <Button size="small" variant="outlined">
                                {template.name}
                            </Button>
                        </CardActions>
                    </Card>
                ))}
            </List>
        </Item>
    );
};

export default QuestionThemes;
