import dayjs from 'dayjs';

/* eslint-disable */
class QuestionTypeReportGenerator {
    constructor(question) {
        this.question = question;
    }

    GenericQuestion(ans_data) {
        return ans_data.answer ?? '';
    }

    McqQuestion(ans_data) {
        if (ans_data.answer?.data) {
            return ans_data.answer.data.map((choice) => choice.choiceLabel).join(', ');
        }

        // For MCQ question (single)
        return ans_data.answer && this.question.choices?.find(choice => choice.id === Number(ans_data.answer.value))?.choice
            ? this.question.choices?.find(choice => choice.id === Number(ans_data.answer.value))?.choice
            : '';
    }

    DateQuestion(ans_data){
        return ans_data.answer ? dayjs(ans_data.answer).format(this.question.meta?.date_format?.toUpperCase()) : '';
    }

    CommentQuestion(ans_data) {
        if (ans_data.answer) {
            const selectedChoice = ans_data.answer.selectedChoices[0];
            const text = `Choice Label: ${selectedChoice.choiceLabel} | Choice Text: ${selectedChoice.choiceText}`;
            return text;
        }
        return ans_data.answer ? '' : '';
    }

    DropdownQuestion(ans_data) {
        if (ans_data.answer && ans_data.answer.choice) {
            return ans_data.answer.choice;
        }
        if (ans_data.answer && !ans_data.answer.choice) {
            return ans_data.answer;
        }
        return '';
    }

    ImagePickerQuestion(ans_data) {
        // return ans_data.answer ? SurveyQuestionChoice.find(ans_data.answer).choice : '';
        return ans_data.answer && this.question.choices?.find(choice => choice.id === Number(ans_data.answer))?.choice
            ? this.question.choices?.find(choice => choice.id === Number(ans_data.answer))?.choice
            : '';
    }

    CountryQuestion(ans_data) {
        return ans_data.answer ? ans_data.answer.label : '';
    }

    StateQuestion(ans_data) {
        return ans_data.answer ? ans_data.answer.label : '';
    }

    BooleanQuestion(ans_data) {
        if (ans_data.answer) {
            const yesLabel = this.question.meta.yesLabel;
            const noLabel = this.question.meta.noLabel;
            return Number(ans_data.answer) === 1 ? yesLabel : noLabel;
        }
        return ans_data.answer ? ans_data.answer.label : '';
    }

    NameQuestion(ans_data) {
        return ans_data.answer ? `${ans_data.answer?.first_name} ${ans_data.answer?.last_name}`: '';
    }
}

export default QuestionTypeReportGenerator;
