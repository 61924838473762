/* eslint-disable class-methods-use-this */
import axiosServices from 'utils/axios';

class SurveyService {
    async create(data) {
        const response = await axiosServices.post('surveys/create', data);
        return response;
    }

    async getSurveyType() {
        const response = await axiosServices.get('surveys/survey_types');
        return response;
    }

    async list(params) {
        const response = await axiosServices.get(`surveys?${params}`);
        return response;
    }

    async show(surveyToken) {
        const response = await axiosServices.get(`surveys/show/${surveyToken}`);
        return response;
    }

    async addQuestionCategory(data) {
        const response = await axiosServices.post('surveys/add-question-cat', data);
        return response;
    }

    async getQuestionTypes(surveyToken = null) {
        const response = await axiosServices.get(`surveys/question-types`, {
            params: {
                surveytoken: `${surveyToken}`
            }
        });
        return response;
    }

    async getAllQuestion(surveyToken) {
        const response = await axiosServices.get(`survey-templates/${surveyToken}/questions`);
        return response;
    }

    async cloneTemplate(surveyToken) {
        const userTimezone = Intl.DateTimeFormat().resolvedOptions()?.timeZone;
        const response = await axiosServices.post(`/survey-templates/${surveyToken}/clone`, {
            userTimezone
        });
        return response;
    }

    async update(surveyToken, values) {
        const response = await axiosServices.put(`/surveys/update/${surveyToken}`, values);
        return response;
    }

    async delete(surveyToken) {
        const response = await axiosServices.delete(`/surveys/delete/${surveyToken}`);
        return response;
    }

    async deleteQuestion(questionId, surveyToken) {
        const response = await axiosServices.delete(`/surveys/${surveyToken}/questions/${questionId}/delete`);
        return response;
    }

    async duplicateQuestion(questionId, surveyToken, order = null) {
        const response = await axiosServices.post(`/surveys/${surveyToken}/questions/${questionId}/duplicate`, { order });
        return response;
    }

    async duplicate(surveyToken) {
        const response = await axiosServices.post(`/surveys/${surveyToken}/duplicate`);
        return response;
    }

    async showQuestions(surveyToken, params) {
        const response = await axiosServices.get(`/surveys/${surveyToken}/questions?${params}`);
        return response;
    }

    async getProfileSurveyQuestion() {
        const response = await axiosServices.get(`/surveys/41272d02-4a0b-482b-beac-862133295854/questions`);
        // here second parameter is user id which won't be hard coded
        return response;
    }

    async postSurveyRestrictions(surveyToken, ruleEngine) {
        const response = await axiosServices.post(`/surveys/${surveyToken}/save-restrictions`, { ruleEngine });
        return response;
    }

    async getRestrictions() {
        const response = await axiosServices.get(`/restrictions`);
        return response;
    }

    async getProfileSurvey() {
        const response = await axiosServices.get(`/get-profile-survey`);
        return response;
    }

    async getCoreRestrictions() {
        const response = await axiosServices.get(`/surveys/get-core-restrictions`);
        return response;
    }

    async filterUserRestrictions(selectedRestriction) {
        const response = await axiosServices.post(`/surveys/filter-user-restrictions`, selectedRestriction);
        return response;
    }

    async getCountries() {
        const response = await axiosServices.get(`/countries`);
        return response;
    }

    async getCounties() {
        const response = await axiosServices.get(`/counties`);
        return response;
    }

    async getOccupations() {
        const response = await axiosServices.get(`/occupations`);
        return response;
    }

    async getStates() {
        const response = await axiosServices.get(`/states`);
        return response;
    }

    async getQuestionRuleType() {
        const response = await axiosServices.get(`settings/question-rule-type`);
        return response;
    }

    async getShortLink(surveyToken) {
        const response = await axiosServices.get(`/surveys/get-short-link/${surveyToken}`);
        return response;
    }

    async getTemplate(slug) {
        const response = await axiosServices.get(`survey-templates/${slug}`);
        return response;
    }

    async getTemplateCategories() {
        const response = await axiosServices.get(`/surveys/survey-template-categories`);
        return response;
    }

    async getReportSummary(surveyToken, signal) {
        const response = await axiosServices.get(`/reports/${surveyToken}/summary`, {
            signal
        });
        return response;
    }

    async publishingHandler(surveyToken, values) {
        const response = await axiosServices.put(`/surveys/update/${surveyToken}`, values);
        return response;
    }

    async getQuesitonData(surveyToken, questionId) {
        const response = await axiosServices.get(`surveys/single-preview/${surveyToken}/${questionId}`);
        return response;
    }

    async getGraphResponse(surveyToken) {
        const response = await axiosServices.get(`surveys/${surveyToken}/graph-responses`);
        return response;
    }

    async getDetailedResponse(surveyToken, pageNum = 1) {
        const response = await axiosServices.get(`surveys/${surveyToken}/detailed-responses?page=${pageNum}`);
        return response;
    }

    async getDownloadableResponses(surveyToken, pageNum = 1) {
        const response = await axiosServices.get(`surveys/${surveyToken}/downloadable-responses?page=${pageNum}`);
        return response;
    }

    async requestReportSurvey(surveyToken) {
        const response = await axiosServices.get(`surveys/${surveyToken}/request-report`);
        return response;
    }

    async showMoreResponses(surveyToken, qID) {
        const response = await axiosServices.get(`surveys/${surveyToken}/question/${qID}/show-more-responses`);
        return response;
    }

    async surveyHandler(shortCode) {
        const response = await axiosServices.get(`/s/${shortCode}`);
        return response;
    }

    async getQuestionCat(surveyToken) {
        const response = await axiosServices.get(`surveys/get-question-cat/${surveyToken}`);
        return response;
    }

    async deleteQuestionCat(surveyToken, categoryName) {
        const response = await axiosServices.delete(`surveys/delete-question-cat/${surveyToken}/${categoryName}`);
        return response;
    }
}

export default new SurveyService();
