import React, { useEffect, useState } from 'react';
import { useStripe, Elements, useElements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';

const stripePromise = loadStripe(
    'pk_test_51MOT6BDGcW35QhgMV3e35GxtDBhrcTsm79ekFKBcx1JhyMELKYsOnn8M7VfQwlTj9o7GkeMIDwD7dlNspgoRJ8x3004I7mj2DU'
);

export default function SubscriptionComplete() {
    const stripe = useStripe();
    const elements = useElements();
    const [paymentIntent, setPaymentIntent] = useState();

    const [message, setMessage] = useState(null);
    const [clientSecret, setClientSecret] = useState(null);

    useEffect(() => {
        if (!stripe) {
            return;
        }

        setClientSecret(new URLSearchParams(window.location.search).get('payment_intent_client_secret'));

        if (!clientSecret) {
            return;
        }

        stripe.retrievePaymentIntent(clientSecret).then(({ paymentIntent }) => {
            switch (paymentIntent.status) {
                case 'succeeded':
                    setMessage('Payment succeeded!');
                    break;
                case 'processing':
                    setMessage('Your payment is processing.');
                    break;
                case 'requires_payment_method':
                    setMessage('Your payment was not successful, please try again.');
                    break;
                default:
                    setMessage('Something went wrong.');
                    break;
            }
        });
    }, [stripe, clientSecret]);

    return (
        <>
            <Elements stripe={stripePromise} />
        </>
    );
}
