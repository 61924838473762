// assets
import {
    IconBrandChrome,
    IconHelp,
    IconDashboard,
    IconCreditCard,
    IconUser,
    IconChartBar,
    IconCurrencyDollar,
    IconGitPullRequest
} from '@tabler/icons';
import Menus from './AdminMenus';

// constant
const icons = {
    IconBrandChrome,
    IconHelp,
    IconDashboard,
    IconChartBar,
    IconCreditCard,
    IconCurrencyDollar,
    IconUser,
    IconGitPullRequest
};

// ==============================|| SAMPLE PAGE & DOCUMENTATION MENU ITEMS ||============================== //

const AdminMenuSidebar = {
    id: 'admin-menu-list',
    type: 'group',
    children: Menus
};

export default AdminMenuSidebar;
