import React, { useState } from 'react';
import PropTypes from 'prop-types';
import DrawerRadio from './DrawerRadio';

const AlphabeticOrder = ({ formik }) => {
    // Default Value
    const [alphabeticOrder, setAlphabeticOrder] = useState(false);

    const props = {
        formik,
        mainValue: alphabeticOrder,
        mainValueSetter: setAlphabeticOrder,
        name: 'meta.alphabetic_order',
        label: 'Alphabetic Order'
    };

    return <DrawerRadio {...props} />;
};
AlphabeticOrder.propTypes = {
    formik: PropTypes.object
};
export default AlphabeticOrder;
