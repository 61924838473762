import { TextField } from '@mui/material';
import { isUndefined } from 'lodash';
import PropTypes from 'prop-types';
import { useState } from 'react';
import Emitter from 'utils/emitter';

const AnswerVariable = ({ formik }) => {
    // Default Value
    const [answerVariable, setAnswerVariable] = useState('');

    const valueSetter = (formik) => {
        if (formik && formik.values && isUndefined(formik.values?.meta?.answer_variable)) {
            // add
            formik.setFieldValue('meta.answer_variable', answerVariable);
        } else if (!isUndefined(formik.values?.meta?.answer_variable)) {
            // edit
            setAnswerVariable(formik.values?.meta?.answer_variable);
        }
    };

    Emitter.on('FORMIK_RENDERED', (formik) => valueSetter(formik));

    const handleChange = (event) => {
        formik.setFieldValue('meta.answer_variable', event.target.value);
        setAnswerVariable(event.target.value);
    };

    return (
        <TextField
            onChange={handleChange}
            name="meta.answer_variable"
            value={answerVariable}
            id="standard-basic"
            label="Answer Variable"
            fullWidth
            InputLabelProps={{
                shrink: true
            }}
            sx={{ mt: 1 }}
            variant="outlined"
        />
    );
};

AnswerVariable.propTypes = {
    formik: PropTypes.object
};

export default AnswerVariable;
