import { useState } from 'react';
import PropTypes from 'prop-types';
import DrawerRadio from './DrawerRadio';

const Required = ({ formik }) => {
    // Default Value
    const [required, setRequired] = useState(true);

    const props = {
        formik,
        mainValue: required,
        mainValueSetter: setRequired,
        name: 'meta.required',
        label: 'Required'
    };

    return <DrawerRadio {...props} />;
};
Required.propTypes = {
    formik: PropTypes.object
};
export default Required;
