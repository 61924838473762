import {
    IconAdjustments,
    IconAlignCenter,
    IconBuildingCastle,
    IconCalendarTime,
    IconCircleHalf2,
    IconFiles,
    IconFlag,
    IconGridPattern,
    IconInbox,
    IconLayoutAlignCenter,
    IconLayoutGrid,
    IconLayoutGridAdd,
    IconListCheck,
    IconMail,
    IconMessageCircle,
    IconPhone,
    IconPhoto,
    IconScreenShare,
    IconSelect,
    IconStar,
    IconTextWrap,
    IconWriting
} from '@tabler/icons';

const iconData = [
    {
        name: 'short_question',
        icon: IconAlignCenter
    },
    {
        name: 'long_question',
        icon: IconTextWrap
    },
    {
        name: 'dropdown_question',
        icon: IconSelect
    },
    {
        name: 'boolean_question',
        icon: IconCircleHalf2
    },
    {
        name: 'matrix_question',
        icon: IconLayoutGrid
    },
    {
        name: 'matrix_dynamic_question',
        icon: IconLayoutAlignCenter
    },
    {
        name: 'matrix_dropdown_question',
        icon: IconLayoutGridAdd
    },
    {
        name: 'mcq_question',
        icon: IconListCheck
    },
    {
        name: 'rating_question',
        icon: IconStar
    },
    {
        name: 'files_uploading_question',
        icon: IconFiles
    },
    {
        name: 'image_picker_question',
        icon: IconPhoto
    },
    {
        name: 'phone_number_question',
        icon: IconPhone
    },
    {
        name: 'email_question',
        icon: IconMail
    },
    {
        name: 'opinion_scale_question',
        icon: IconAdjustments
    },
    {
        name: 'country_question',
        icon: IconFlag
    },
    {
        name: 'state_question',
        icon: IconBuildingCastle
    },
    {
        name: 'message_question',
        icon: IconScreenShare
    },
    {
        name: 'name_question',
        icon: IconWriting
    },
    {
        name: 'date_question',
        icon: IconCalendarTime
    }
];

export default iconData;
