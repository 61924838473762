/* eslint-disable class-methods-use-this */
import config from 'config';
import axiosServices from 'utils/axios';

class ReportService {
    async list(params) {
        const response = await axiosServices.get(`surveys/my-requests-report?${params}`);
        return response;
    }

    async fullDetailsDetailedAnswer(surveyToken) {
        const response = await axiosServices.get(`surveys/${surveyToken}/full-details-response`);
        return response;
    }

    async syncSurveyResponseToGoogleSheet(surveyToken) {
        const response = await axiosServices.post(`sync-survey-response-to-google-sheet/${surveyToken}`);
        return response;
    }

    async checkGoogleAccountConnectivity(surveyToken) {
        const response = await axiosServices.get(`check-google-account-connectivity-on-integration/${surveyToken}`);
        return response;
    }

    async deleteGoogleSheetIntegration(surveyToken) {
        const response = await axiosServices.delete(`google-sheet-integration/${surveyToken}`);
        return response;
    }

    async adminReportSummary() {
        const response = await axiosServices.get(`admin/report-summary`);
        return response;
    }

    async getSolrStatistics() {
        const response = await axiosServices.get(`admin/solr-report`);
        return response;
    }

    async reindexSolr(checkDeleteSolr) {
        const response = await axiosServices.post(`admin/rebuilt-solr`, { deleteData: checkDeleteSolr });
        return response;
    }

    async download(name, userConfig = {}) {
        const response = await axiosServices.get(`surveys/report/download/${name}`, {
            ...config,
            ...userConfig
        });
        return response;
    }

    async downloadMediaResponse(data, userConfig = {}) {
        const response = await axiosServices.post(
            `surveys/report/download/media_response`,
            {
                survey_id: Number(data?.surveyId),
                question_id: Number(data?.questionId),
                respondent_id: Number(data?.respondentId)
            },
            {
                ...config,
                ...userConfig
            }
        );
        return response;
    }
}

export default new ReportService();
