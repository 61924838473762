import { TextField } from '@mui/material';
import { isUndefined } from 'lodash';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import Emitter from 'utils/emitter';

const RequiredColumn = ({ formik }) => {
    // Default Value
    const [requiredColumn, setRequiredColumn] = useState(1);
    const [requiredCurrentState, setRequiredCurrentState] = useState(true);
    const [allowMultipleCurrentState, setAllowMultipleCurrentState] = useState(true);
    const [colCount, setColCount] = useState(1);

    const valueSetter = (formik) => {
        // Only for Matrix (Checkbox) Question
        if (formik?.values?.meta.identifier === 'MatrixQuestion' || typeof formik?.values?.meta?.allow_multiple !== 'undefined') {
            setAllowMultipleCurrentState(formik?.values?.meta?.allow_multiple);
        }
        setRequiredCurrentState(formik?.values?.meta?.required);

        if (formik && formik.values && isUndefined(formik.values?.meta?.required_column)) {
            // add
            formik.setFieldValue('meta.required_column', requiredColumn);
        } else if (!isUndefined(formik.values?.meta?.required_column)) {
            // edit
            setRequiredColumn(formik.values.meta.required_column);
        }
    };

    Emitter.on('FORMIK_RENDERED', (formik) => valueSetter(formik));
    Emitter.on('TABLE_COL_CHANGED', (colCount) => setColCount(colCount));

    const handleNumberChange = (event) => {
        formik.setFieldValue('meta.required_column', Number(event.target.value));
    };

    useEffect(() => {
        if (!requiredCurrentState && !formik?.values?.meta.required_column) {
            setRequiredColumn(0);
            formik.setFieldValue('meta.required_column', 0);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [requiredCurrentState]);

    useEffect(() => {
        if (!allowMultipleCurrentState && !formik?.values?.meta.required_column) {
            setRequiredColumn(0);
            formik.setFieldValue('meta.required_column', 0);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [allowMultipleCurrentState]);

    return (
        <>
            {(requiredCurrentState && allowMultipleCurrentState) ||
            (formik?.values?.question_type?.identifier === 'matrix_question' && requiredCurrentState) ||
            (formik?.values?.question_type?.identifier === 'matrix_dropdown_question' && requiredCurrentState) ||
            (formik?.values?.question_type?.identifier === 'matrix_dynamic_question' && requiredCurrentState) ? (
                <TextField
                    onChange={handleNumberChange}
                    name="meta.required_column"
                    value={requiredColumn}
                    id="standard-basic"
                    fullWidth
                    m={1}
                    placeholder="Required Column"
                    variant="outlined"
                    type="number"
                    disabled={!requiredCurrentState}
                    label="Required Column"
                />
            ) : null}
        </>
    );
};
RequiredColumn.propTypes = {
    formik: PropTypes.object
};
export default RequiredColumn;
