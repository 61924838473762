import { FormControl, FormControlLabel, FormLabel, Radio, RadioGroup, Switch, TextField } from '@mui/material';
import { IconLayout, IconLayout2, IconLayoutSidebar, IconLayoutSidebarRight } from '@tabler/icons';
import { isEmpty, isUndefined } from 'lodash';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import Emitter from 'utils/emitter';

const Layout = ({ formik }) => {
    // Default Value
    const [layout, setLayout] = useState('rtl');

    const valueSetter = (formik) => {
        if (formik && formik.values && isEmpty(formik.values?.meta.layout_direction)) {
            console.log('add ->');
            // add
            formik.setFieldValue('meta.layout_direction', layout);
        } else if (!isEmpty(formik.values?.meta.layout_direction)) {
            console.log('edit ->');
            // edit
            setLayout(formik.values.meta.layout_direction);
        }
    };

    Emitter.on('FORMIK_RENDERED', (formik) => valueSetter(formik));

    const handleChange = (event) => {
        formik.setFieldValue('meta.layout_direction', event.target.value);
        setLayout(event.target.value);
    };

    return (
        <>
            {formik.values?.meta?.layout_direction && (
                <FormControl>
                    <FormLabel id="demo-radio-buttons-group-label">Layout</FormLabel>
                    <RadioGroup
                        aria-labelledby="demo-radio-buttons-group-label"
                        // defaultValue="rtl"
                        value={formik.values?.meta?.layout_direction}
                        name="radio-buttons-group"
                        onChange={handleChange}
                    >
                        <FormControlLabel value="rtl" control={<Radio />} label={<IconLayoutSidebarRight />} />

                        <FormControlLabel value="ltr" control={<Radio />} label={<IconLayoutSidebar />} />
                    </RadioGroup>
                </FormControl>
            )}
        </>
    );
};
Layout.propTypes = {
    formik: PropTypes.object
};
export default Layout;
