import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

// material-ui
import { useTheme } from '@mui/material/styles';
import { Button, Card, CardContent, Grid, Typography } from '@mui/material';

// assets
import ArrowRightAltRoundedIcon from '@mui/icons-material/ArrowRightAltRounded';

// ==============================|| BILL CARD ||============================== //

const BillCard = ({ primary, secondary, tertiary, link, linkHref, color, bg, secondaryButton }) => {
    const theme = useTheme();
    return (
        <Card sx={{ borderLeft: '10px solid', borderColor: color, background: bg }}>
            <CardContent>
                <Grid container spacing={0}>
                    <Grid item xs={12}>
                        <Typography
                            variant="body1"
                            sx={{
                                mb: 1,
                                color: theme.palette.grey[700]
                            }}
                        >
                            {primary}
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="h2" sx={{ fontWeight: 500, mb: 1.5, color: theme.palette.grey[800] }}>
                            {secondary}{' '}
                            {tertiary && (
                                <Typography sx={{ p: 0 }} variant="h6" component="span">
                                    {tertiary}
                                </Typography>
                            )}
                        </Typography>
                    </Grid>
                    <Grid item xs={8}>
                        <Button
                            variant="text"
                            disableElevation
                            disableRipple
                            href={linkHref}
                            sx={{
                                color,
                                p: 0,
                                '&:hover': { bgcolor: 'transparent' }
                            }}
                            endIcon={<ArrowRightAltRoundedIcon />}
                        >
                            {link}
                        </Button>
                    </Grid>
                    <Grid item xs={4} textAlign="end">
                        {secondaryButton && <>{secondaryButton}</>}
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    );
};

BillCard.propTypes = {
    primary: PropTypes.string,
    secondary: PropTypes.string,
    tertiary: PropTypes.any,
    link: PropTypes.string,
    secondaryButton: PropTypes.any,
    linkHref: PropTypes.string,
    color: PropTypes.string,
    bg: PropTypes.string
};

export default BillCard;
