/* eslint-disable react/self-closing-comp */
import { Box, Button, Card, CardContent, Grid, Typography, Tooltip } from '@mui/material';
import { useEffect, useState } from 'react';
import { IconPlus, IconCirclePlus } from '@tabler/icons';
import QuestionTypes from './QuestionTypes';
import PropTypes from 'prop-types';

// ==============================|| Main Template ||============================== //

const QuestionTypeModal = ({ formik }) => {
    const [open, setOpen] = useState(false);

    return (
        <>
            <Tooltip title="Add a Qustion" placement="right">
                <Button
                    sx={{ padding: '3px', minWidth: 'auto', float: 'right', margin: '0' }}
                    variant="contained"
                    size="small"
                    onClick={() => setOpen(true)}
                >
                    <IconCirclePlus size={18} />
                </Button>
            </Tooltip>
            {open ? <QuestionTypes open={open} onClose={() => setOpen(false)} formik={formik} /> : null}
        </>
    );
};

QuestionTypeModal.propTypes = {
    formik: PropTypes.any
};

export default QuestionTypeModal;
