const Style = () => (
    <style>
        {`
          [contenteditable=true]:empty:before {
            content: attr(placeholder);
            pointer-events: none;
            display: block; /* For Firefox */
          }
        `}
    </style>
);

export default Style;
