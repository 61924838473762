import { Typography, CardMedia } from '@mui/material';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import useGlobalModal from 'hooks/useGlobalModal';

const GlobalModal = () => {
    const { title, content, open, closeGlobalModal } = useGlobalModal();
    const handleClose = () => {
        closeGlobalModal();
    };

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            fullWidth
            maxWidth="lg"
        >
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    <CardMedia component="iframe" frameBorder={0} height="600" src={content} />
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} autoFocus disableRipple>
                    Close
                </Button>
            </DialogActions>
        </Dialog>
    );
};
export default GlobalModal;
