import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import DrawerRadio from './DrawerRadio';
import { useParams } from 'react-router-dom';
import SurveyService from 'services/survey.service';

const WelcomeScreen = ({ formik }) => {
    // Default Value
    const [welcomeScreen, setWelcomeScreen] = useState(false);
    const [disabled, setDisabled] = useState(false);
    const { surveyToken } = useParams();
    const fetchSurvey = async () => {
        const { data: response } = await SurveyService.showQuestions(surveyToken);
        const hasWelcomeScreen = response.data.map((question) => question?.meta?.welcome_screen);
        if (hasWelcomeScreen?.length > 0) {
            setDisabled(true);
        }
    };
    useEffect(() => {
        fetchSurvey();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const props = {
        formik,
        mainValue: welcomeScreen,
        mainValueSetter: setWelcomeScreen,
        name: 'meta.welcome_screen',
        label: 'Welcome Screen',
        disabled
    };

    return <DrawerRadio {...props} />;
};
WelcomeScreen.propTypes = {
    formik: PropTypes.object
};
export default WelcomeScreen;
