import React, { useState } from 'react';
import PropTypes from 'prop-types';
import DrawerRadio from './DrawerRadio';

const AllowComments = ({ formik }) => {
    // Default Value
    const [allow, setAllowComments] = useState(false);

    const props = {
        formik,
        mainValue: allow,
        mainValueSetter: setAllowComments,
        name: 'meta.allow_comments',
        label: 'Allow Comments'
    };

    return <DrawerRadio {...props} />;
};
AllowComments.propTypes = {
    formik: PropTypes.object
};
export default AllowComments;
