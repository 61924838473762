/* eslint-disable class-methods-use-this */
import axiosServices from 'utils/axios';
import config from '../config';

class GeneralService {
    async storeImage(data, userConfig = {}) {
        const response = await axiosServices.post('store-image', data, { ...config, ...userConfig });
        return response;
    }

    async getLibraryImages(userConfig = {}) {
        console.log(userConfig);
        const response = await axiosServices.get('get-media-library', { ...config, ...userConfig });
        return response;
    }

    async addMedia(data, userConfig = {}) {
        const response = await axiosServices.post('add-media', data, { ...userConfig });
        return response;
    }

    async updateNotificationSettings(data) {
        const response = await axiosServices.post('notification-settings/update', data);
        return response;
    }
}

export default new GeneralService();
