import React, { useState } from 'react';
import PropTypes from 'prop-types';
import DrawerRadio from './DrawerRadio';
import { isUndefined } from 'lodash';

const IsActive = ({ formik }) => {
    // Default Value
    const [isActive, setIsActive] = useState(true);

    const props = {
        formik,
        mainValue: isActive,
        mainValueSetter: setIsActive,
        name: 'meta.is_active',
        label: 'Is Active'
    };

    return <DrawerRadio {...props} />;
};
IsActive.propTypes = {
    formik: PropTypes.object
};
export default IsActive;
