// material-ui
import { useEffect, useRef, useState } from 'react';
import {
    Alert,
    AlertTitle,
    Button,
    CardActions,
    Grid,
    Chip,
    Typography,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Card,
    CardContent,
    List,
    ListItem,
    ListItemText
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useConfirm } from 'material-ui-confirm';
import { useSnackbar } from 'notistack';
import PaymentService from 'services/payment.service';
import dayjs from 'dayjs';
import Pusher from 'pusher-js';
import useAuth from 'hooks/useAuth';
import BillCard from 'ui-component/cards/BillCard';
import SubCard from 'ui-component/cards/SubCard';
import { useLocation, useNavigate } from 'react-router-dom';
import { DataGrid } from '@mui/x-data-grid';
import ChatBubbleTwoToneIcon from '@mui/icons-material/ChatBubbleTwoTone';
import PaymentMethods from './PaymentMethods';

const Membership = () => {
    const theme = useTheme();
    const { enqueueSnackbar } = useSnackbar();
    const [subscriptions, setSubscriptions] = useState({});
    const [payments, setPayments] = useState([]);
    const confirm = useConfirm();
    const navigate = useNavigate();
    const [subscriptionUpdated, setSubscriptionUpdated] = useState(false);
    const [fetched, setFetched] = useState(false);
    const [isPaymentIncomplete, setIsPaymentIncomplete] = useState(false);
    const [isSubLoading, setIsSubLoading] = useState(true);
    const [stripeStatus, setStripeStatus] = useState('');
    const [totalPayments, setTotalPayments] = useState('0');
    const [currentAdditionalPackages, setCurrentAdditionalPackages] = useState([]);
    const [showExtraResponsePackage, setShowExtraResponsePackage] = useState(false);
    const { user } = useAuth();
    const { state } = useLocation();
    const fieldDefaultAttrs = {
        flex: 1,
        sortable: true,
        searchable: true
    };

    const columns = useRef([
        {
            field: 'created_at',
            headerName: 'Date',
            ...fieldDefaultAttrs,
            sortable: true,
            searchable: false,
            renderCell: (param) => dayjs(param?.value).format('MMM DD, YYYY')
        },
        {
            field: 'amount',
            headerName: 'Price',
            ...fieldDefaultAttrs,
            searchable: false
        },
        {
            field: 'payment_type',
            headerName: 'Payment',
            ...fieldDefaultAttrs,
            searchable: false,
            renderCell: (param) => <Chip chipcolor="orange" label={param?.value} size="small" />
        },
        {
            field: 'meta',
            headerName: 'Status',
            ...fieldDefaultAttrs,
            searchable: true,
            renderCell: (param) => (
                <Chip
                    label={param?.value?.status ? param?.value?.status : 'Success'}
                    color={param?.value?.status ? 'error' : 'success'}
                    size="small"
                />
            )
        }
    ]);

    const upgradePackage = async (priceId) => {
        if (stripeStatus === 'none') {
            // Experiment is going on
            const { data: sessionData } = await PaymentService.createCheckoutSession({ price_id: priceId });
            window.location.href = sessionData.url;
            // Previous Experiment code ends here
        } else {
            const { data } = await PaymentService.swapCustomerPlan({ price_id: priceId, stripe_status: stripeStatus });
            navigate('/profile/handle-membership');
        }

        /**
         * Subscribe the user with the price_id here
         * and move to payment page
         */
        // const {
        //     data: { clientSecret, subscriptionId, redirectTo }
        // } = await PaymentService.subscribe({ price_id: priceId });
        // setSubscriptionData({ subscriptionId, clientSecret, redirectTo });
    };

    const getSubscription = async () => {
        const { data: paymentData } = await PaymentService.getSubscriptions();
        setFetched(true);
        setSubscriptions(paymentData.subscriptions);
        setPayments(paymentData.payments);
        setTotalPayments(paymentData.total_payments);
        setSubscriptionUpdated(false);
    };

    const cancelMembership = async () => {
        confirm({ description: 'Are you sure that you want to cancel the subscription ? ' }).then(async () => {
            const { data } = await PaymentService.cancelSubscription({ id: subscriptions.id });
            /**
             * Error handle
             */
            setSubscriptions({}); // Optimistic UI
            setTimeout(() => {
                setSubscriptionUpdated(true);
            }, 3000);
            enqueueSnackbar(data.msg, { variant: data.code, autoHideDuration: 3000 });
        });
    };

    const subscriptionExpired = (timestamp) => dayjs.unix(timestamp).diff(dayjs()) <= 0;

    const cardActions = ({ noCancel }) => (
        <CardActions sx={{ p: 0, pt: 3, mb: 2 }}>
            <>{noCancel}</>
            <Grid spacing={2} container justifyContent="flex-start">
                <Grid item>
                    <Button variant="contained" onClick={() => navigate('/packages')}>
                        Upgrade
                    </Button>
                </Grid>
                {!noCancel && (
                    <Grid item>
                        <Button variant="outlined" color="error" onClick={cancelMembership}>
                            Cancel
                        </Button>
                    </Grid>
                )}
            </Grid>
        </CardActions>
    );

    const pusher = new Pusher('971dc76faa89074b989b', {
        cluster: 'ap2',
        encrypted: true
    });

    const handleNewNotification = async (data) => {
        setIsSubLoading(!data.charging_status);

        if (data.charging_status) {
            setSubscriptionUpdated(true);
            setIsPaymentIncomplete(false);
        }
        window.location.href = '/profile/membership';

        // Handle the new notification data
        // getSubscription();
    };

    const getCurrentPlanPackages = async () => {
        // const { data: planPackages } = await PaymentService.getCurrentPlanPackages();
        const { data } = await PaymentService.getCurrentPlanPackages();
        console.log(data);
        setCurrentAdditionalPackages(data);
    };

    const checkIncompletePayment = async () => {
        const { data } = await PaymentService.checkIncompletePayment();
        setStripeStatus(data?.stripe_status);

        if (data?.stripe_status === 'incomplete') {
            setIsPaymentIncomplete(true);
        }
    };

    useEffect(() => {
        const channel = pusher.subscribe('stripe-us');
        channel.bind('App\\Events\\SubscriptionPackageUpdate', handleNewNotification);

        checkIncompletePayment();
        setIsSubLoading(false);

        // return () => {
        //     channel.unbind('new-package');
        //     pusher.unsubscribe('user-id-package');
        // };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        getSubscription();
    }, [subscriptionUpdated]);

    useEffect(() => {
        if (state?.message) {
            enqueueSnackbar(state.message, {
                variant: 'success',
                autoHideDuration: 3000
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state]);

    useEffect(() => {
        getCurrentPlanPackages();
    }, []);

    return (
        <>
            <Grid container justifyContent="space-between">
                <Grid item>
                    <Typography variant="h3" align="left" pt={1} mb={3}>
                        Membership Plan &amp; Billing
                    </Typography>
                </Grid>

                {/* <Grid item>
                    <Typography variant="h3" align="right" pt={1} mb={3}>
                        <Button
                            variant="contained"
                            onClick={() => setShowExtraResponsePackage((prevValue) => !prevValue)}
                            fullWidth
                            startIcon={<ChatBubbleTwoToneIcon />}
                        >
                            Add Extra Response
                        </Button>
                    </Typography>
                </Grid> */}
            </Grid>

            {showExtraResponsePackage && (
                <Grid item xs={12} mb={3}>
                    <Grid container spacing={2}>
                        {currentAdditionalPackages &&
                            currentAdditionalPackages.length > 0 &&
                            Array.isArray(currentAdditionalPackages) &&
                            currentAdditionalPackages?.map((additionalPackage) => (
                                <Grid item md={4} sx={{ margin: '0 auto', maxWidth: '300px' }}>
                                    <Card variant="outlined" sx={{ border: '1px solid #ddd', textAlign: 'center' }}>
                                        <CardContent>
                                            <Typography color="text.secondary" gutterBottom>
                                                {additionalPackage?.product?.name}
                                            </Typography>
                                            <Typography variant="h2" component="div" sx={{ mb: 3.5 }}>
                                                ${additionalPackage?.unit_amount}
                                            </Typography>
                                            <Typography sx={{ mb: 1.5 }} color="text.secondary">
                                                {additionalPackage?.plan_responses} Responses
                                            </Typography>
                                            <Typography variant="body2" sx={{ margin: '0 auto', textAlign: 'center' }}>
                                                <List dense>
                                                    {additionalPackage?.features?.map((feature) => (
                                                        <ListItem sx={{ textAlign: 'center' }}>
                                                            <ListItemText primary={feature} />
                                                        </ListItem>
                                                    ))}
                                                </List>
                                            </Typography>
                                        </CardContent>
                                        <CardActions>
                                            <Button
                                                variant="contained"
                                                size="small"
                                                sx={{ margin: '0 auto' }}
                                                onClick={() => upgradePackage(additionalPackage.id)}
                                            >
                                                Add
                                            </Button>
                                        </CardActions>
                                    </Card>
                                </Grid>
                            ))}
                    </Grid>
                </Grid>
            )}

            {((fetched && subscriptions && !subscriptions.id) ||
                (fetched && !subscriptions) ||
                (fetched && subscriptions && subscriptionExpired(subscriptions.current_period_end))) &&
                !isPaymentIncomplete &&
                !subscriptionUpdated && (
                    <Grid container direction="column" spacing={3}>
                        <Grid item alignContent="left" xs={12}>
                            <Alert severity="error" icon={false}>
                                <AlertTitle>
                                    Important
                                    {subscriptions.ended_at &&
                                        subscriptions.ended_at !== null &&
                                        subscriptions.ended_at < dayjs().unix() &&
                                        ': Your subscription has expired.'}
                                </AlertTitle>
                                You currently do not have any active membership.
                            </Alert>
                            {cardActions({ noCancel: true })}
                        </Grid>
                    </Grid>
                )}

            {isPaymentIncomplete ||
                (isSubLoading && (
                    <Grid container direction="column" spacing={3}>
                        <Grid item alignContent="left" xs={12}>
                            <Alert severity="info" icon={false}>
                                <AlertTitle>Please wait for a while...</AlertTitle>
                                We are working to activate your membership.
                            </Alert>
                        </Grid>
                    </Grid>
                ))}

            <Grid container spacing={2}>
                {!isSubLoading && fetched && subscriptions && subscriptions.id && (
                    <>
                        <Grid item xs={12} sm={6} md={6}>
                            <BillCard
                                primary="Plan"
                                secondary={subscriptions?.product_name}
                                tertiary={
                                    <>
                                        / {stripeStatus === 'trialing' ? 'Trial Ends on: ' : 'Next charge will be on: '}
                                        {dayjs.unix(subscriptions.current_period_end).format('MMM DD, YYYY')}
                                    </>
                                }
                                linkHref="/packages"
                                link="Upgrade"
                                secondaryButton={
                                    !subscriptionExpired(subscriptions.current_period_end) && stripeStatus !== 'trialing' ? (
                                        <Button
                                            variant="text"
                                            disableElevation
                                            disableRipple
                                            onClick={cancelMembership}
                                            sx={{
                                                p: 0,
                                                color: theme.palette.error.dark,
                                                m: 0,
                                                '&:hover': { bgcolor: 'transparent' }
                                            }}
                                        >
                                            Cancel
                                        </Button>
                                    ) : (
                                        ''
                                    )
                                }
                                color={theme.palette.success.dark}
                                bg={theme.palette.success.light}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} md={6}>
                            <BillCard
                                primary="Total Payments"
                                secondary={`$${totalPayments}`}
                                link="View All"
                                linkHref="#payment_details"
                                color={theme.palette.warning.dark}
                                bg={theme.palette.warning.light}
                            />
                        </Grid>
                    </>
                )}

                {(fetched && subscriptions && !subscriptions.id) || (fetched && !subscriptions) ? null : (
                    <Grid item xs={6}>
                        <PaymentMethods />
                    </Grid>
                )}
                <Grid item xs={6}>
                    <Card style={{ height: 720, width: '100%' }} id="payment_details">
                        {payments.length > 0 && (
                            <DataGrid
                                rows={payments}
                                columns={columns.current}
                                initialState={{
                                    pagination: {
                                        paginationModel: { page: 0, pageSize: 10 }
                                    }
                                }}
                                pageSizeOptions={[5, 10]}
                            />
                        )}
                    </Card>
                </Grid>
            </Grid>
        </>
    );
};

export default Membership;
