import PropTypes from 'prop-types';
import { createContext, useEffect, useReducer } from 'react';

// third-party

// reducer - state management
import { ACCOUNT_INITIALIZE, LOGIN, LOGOUT } from 'store/actions';
import accountReducer from 'store/accountReducer';

import { API_BASE } from 'config';
// project imports
import axios from 'utils/axios';
import Loader from 'ui-component/Loader';
import { redirectBasedOnUserRole } from 'utils/helpers';

// constant
const initialState = {
    isLoggedIn: false,
    isInitialized: false,
    user: null
};

axios.defaults.baseURL = API_BASE;

// const verifyToken = (serviceToken) => {
//     if (!serviceToken) {
//         return false;
//     }
//     const decoded = jwtDecode(serviceToken);
//     return decoded.exp > Date.now() / 1000;
// };

const setSession = (serviceToken) => {
    if (serviceToken) {
        localStorage.setItem('serviceToken', serviceToken);
        axios.defaults.headers.common.Authorization = `Bearer ${serviceToken}`;
    } else {
        localStorage.removeItem('serviceToken');
        delete axios.defaults.headers.common.Authorization;
    }
};

const JWTContext = createContext({
    ...initialState,
    login: () => Promise.resolve(),
    logout: () => {}
});

export const JWTProvider = ({ children }) => {
    const [state, dispatch] = useReducer(accountReducer, initialState);

    const login = async (email, password) => {
        const response = await axios.post('/login', {
            email,
            password,
            role: 'Client'
        });

        const serviceToken = response.data.token;
        setSession(serviceToken);
        // WIP: WIP
        // const user = {};
        const profileResponse = await axios.get('/profile');
        const user = profileResponse.data;

        dispatch({
            type: LOGIN,
            payload: {
                user
            }
        });
    };

    const logout = () => {
        setSession(null);
        dispatch({ type: LOGOUT });
    };

    useEffect(() => {
        const init = async () => {
            try {
                const serviceToken = window.localStorage.getItem('serviceToken');
                if (serviceToken) {
                    setSession(serviceToken);
                    const response = await axios.get('/profile');
                    const user = response.data;

                    dispatch({
                        type: ACCOUNT_INITIALIZE,
                        payload: {
                            isLoggedIn: true,
                            user
                        }
                    });
                } else {
                    dispatch({
                        type: ACCOUNT_INITIALIZE,
                        payload: {
                            isLoggedIn: false,
                            user: null
                        }
                    });
                }
            } catch (err) {
                dispatch({
                    type: ACCOUNT_INITIALIZE,
                    payload: {
                        isLoggedIn: false,
                        user: null
                    }
                });
            }
        };

        init();
    }, []);

    if (!state.isInitialized) {
        return <Loader />;
    }

    return <JWTContext.Provider value={{ ...state, login, logout }}>{children}</JWTContext.Provider>;
};

JWTProvider.propTypes = {
    children: PropTypes.node.isRequired
};

export default JWTContext;
