/* eslint-disable */
import React, { useState } from 'react';
import { MenuItem, Select, Grid, IconButton, TextField } from '@mui/material';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import { useFormikContext, FastField } from 'formik';
import useRestrictionStore from 'zustand_store/useRestrictionStore';

const Condition = (props) => {
    const formik = useFormikContext();
    const { allQuesData, allQtitle, choiceMap, index, ruleIndex, conditionIndex, survey, removeCondition, remove } = props;
    const len = formik.values.restrictions[index].allRules[ruleIndex].cond.length;
    const [currentSelectedQuestionIdentifier, setCurrentSelectedQuestionIdentifier] = useState('short_question');
    const logicalOperatorTemplates = useRestrictionStore((state) => state.logicalOperatorTemplates);

    // const [findTemplate, setFindTemplate] = useState(logicalOperatorTemplates[currentSelectedQuestionIdentifier]({ ...props, formik }));

    // useEffect(() => {
    //     setFindTemplate(logicalOperatorTemplates[currentSelectedQuestionIdentifier]({ ...props, formik }));
    // }, [currentSelectedQuestionIdentifier]);

    const questionIdentifierFinder = (id) => allQuesData.find((ques) => ques.id === id)?.question_type?.identifier;

    return (
        <>
            <Grid container spacing={2}>
                <Grid item xs={1}>
                    <IconButton aria-label="delete" color="error" onClick={() => removeCondition(remove, conditionIndex)} >
                        <RemoveCircleIcon />
                    </IconButton>
                </Grid>
                <Grid item xs={5}>
                    <FastField  
                        size="small"
                        color="secondary"
                        placeholder="Select .."
                        as={Select}
                        fullWidth
                        SelectDisplayProps={{ 'data-testid': 'condition-question' }}
                        // SelectProps={{
                        //     SelectDisplayProps: {
                        //         'data-testid': 'condition-question'
                        //     }
                        // }}    
                        // value={formik.values.restrictions[index][ruleIndex][conditionIndex].question}
                        // onChange={(event) => {
                        //     setCurrentSelectedQuestionIdentifier(allQuesData.find(ques => ques.id === formik.values.restrictions[[index]][[ruleIndex]].cond[[conditionIndex]].ques)?.question_type?.identifier)
                        //     formik.setFieldValue(`restrictions[${[index]}][${[ruleIndex]}].cond[${[conditionIndex]}].ques`, event.target.value);
                        // }}
                        name={`restrictions[${[index]}].allRules[${[ruleIndex]}].cond[${[conditionIndex]}].ques`}
                    >
                        {allQuesData.slice(0, index + 1).map((questionData, index) => (
                            <MenuItem value={questionData.id} key={index}>
                                {questionData.question_title}
                            </MenuItem>
                        ))}
                    </FastField >
                </Grid>

                {/* This generates Conditional Operator Selectbox */}

                {questionIdentifierFinder(formik.values.restrictions[[index]].allRules[[ruleIndex]].cond[[conditionIndex]].ques)
                    ? logicalOperatorTemplates[
                        questionIdentifierFinder(formik.values.restrictions[[index]].allRules[[ruleIndex]].cond[[conditionIndex]].ques)
                    ]({ ...props, formik })
                    : ''}

                <Grid item xs={1}>
                    &nbsp;
                </Grid>
                {conditionIndex + 1 !== len && (
                    <Grid item xs={2} mb={2}>
                        <FastField
                            size="small"
                            as={Select}
                            SelectDisplayProps={{ 'data-testid': 'logical-operators-selectbox' }}
                    
                            // value={formik.values.restrictions[index][ruleIndex][conditionIndex].andOr}
                            // onChange={formik.handleChange}
                            fullWidth
                            // sx={{ maxWidth: 155 }}
                            name={`restrictions[${[index]}].allRules[${[ruleIndex]}].cond[${[conditionIndex]}].logicalOp`}
                        >
                            <MenuItem value="or">or</MenuItem>
                            <MenuItem value="and"> and</MenuItem>
                        </FastField>
                    </Grid>
                )}
            </Grid>
        </>
    );
};

export default React.memo(Condition);
