import React from 'react';
import { MenuItem, Select, Grid, Typography, TextField, Button } from '@mui/material';
import PropTypes from 'prop-types';
import { useFormik, useFormikContext, FastField } from 'formik';

const NumberChoice = ({ allQuesData, choiceMap, ruleIndex, index, conditionIndex, selectedOption }) => {
    const one = 1;
    const formik = useFormikContext();

    // assignmentOp = Assignments Operator
    return (
        <>
            <Grid item xs={2} mb={4}>
                <Select
                    value={formik.values.restrictions[index].allRules[ruleIndex].cond[conditionIndex].assignmentOp}
                    onChange={formik.handleChange}
                    name={`restrictions[${[index]}].allRules[${[ruleIndex]}].cond[${[conditionIndex]}].assignmentOp`}
                    fullWidth
                    SelectDisplayProps={{ 'data-testid': 'conditional-operator-select' }}
                >
                    <MenuItem value="equal"> is </MenuItem>
                    <MenuItem value="not equal"> is not </MenuItem>
                    <MenuItem value="greater than"> Greater than</MenuItem>
                    <MenuItem value="less than"> Less than </MenuItem>
                </Select>
            </Grid>
            <Grid item xs={4}>
                <TextField
                    value={formik.values.restrictions[index].allRules[ruleIndex].cond[conditionIndex].val}
                    type="number"
                    onChange={formik.handleChange}
                    name={`restrictions[${[index]}].allRules[${[ruleIndex]}].cond[${[conditionIndex]}].val`}
                    fullWidth
                    SelectDisplayProps={{ 'data-testid': 'condition-question-select' }}
                />
            </Grid>
        </>
    );
};
NumberChoice.propTypes = {
    allQuesData: PropTypes.object,
    choiceMap: PropTypes.object,
    index: PropTypes.number,
    ruleIndex: PropTypes.number,
    conditionIndex: PropTypes.number,
    selectedOption: PropTypes.number
};

export default NumberChoice;
