import Qs from 'qs';

/**
 * Transform the parameters to
 * jQuery DataTable's compatible request object for Yajra
 */

export default function transformToJqDatatable(options) {
    const orderIndex = options.columns.findIndex((obj) => obj.field === options.pagination.sortBy);
    const searchIndex = options.columns.findIndex((obj) => obj.field === options.pagination.searchBy);

    const requestObj = {
        columns: [],
        order: [],
        search: {
            column: '',
            value: ''
        },
        extra: {}
    };
    // eslint-disable-next-line array-callback-return
    options.columns.map((v, i) => {
        requestObj.columns[i] = {
            data: v.field,
            orderable: v.sortable ? 'true' : 'false',
            ...v
        };
    });

    requestObj.start = options.pagination.page * options.pagination.rowsPerPage;
    requestObj.length = options.pagination.rowsPerPage;
    requestObj.search.value = options.pagination.filter;
    requestObj.order.push({
        column: orderIndex,
        dir: options.pagination.direction
    });

    // console.log(requestObj.columns);
    if (searchIndex >= 0) {
        requestObj.columns[searchIndex].search = { value: options.pagination.singleFilterValue };
    }
    // console.log(options);
    requestObj.extra = options.extra || {};

    return Qs.stringify(requestObj);
}
