import PropTypes from 'prop-types';

// material-ui
import { useTheme } from '@mui/material/styles';
import { Divider, List, Typography } from '@mui/material';

import NavCollapse from '../NavCollapse';
import { useSelector } from 'react-redux';
import MainNavItem from '../NavItem/MainNavItem';
import useAuth from 'hooks/useAuth';
import { isUndefined } from 'lodash';

// ==============================|| SIDEBAR MENU LIST GROUP ||============================== //

const MainNavGroup = ({ item }) => {
    const theme = useTheme();
    const { drawerOpen } = useSelector((state) => state.menu);
    const { user } = useAuth();

    /**
     * Remove menus if there're role associated and user doesn't have it
     */
    if (item.children.length) {
        item.children = item.children.filter((menuItem) => {
            let filterItem = null;
            if (isUndefined(menuItem.role)) {
                filterItem = menuItem;
            }
            if (!isUndefined(menuItem.role)) {
                if (user.user_role.includes(menuItem.role)) {
                    filterItem = menuItem;
                }
            }
            return filterItem;
        });
    }

    // menu list collapse & items
    const items = item.children?.map((menu) => {
        switch (menu.type) {
            case 'collapse':
                return <NavCollapse key={menu.id} menu={menu} level={1} />;
            case 'item':
                return <MainNavItem key={menu.id} item={menu} level={1} />;
            default:
                return (
                    <Typography key={menu.id} variant="h6" color="error" align="center">
                        Menu Items Error
                    </Typography>
                );
        }
    });

    return (
        <>
            <List
                subheader={
                    item.title && (
                        <Typography variant="caption" sx={{ ...theme.typography.menuCaption }} display="block" gutterBottom>
                            {item.title}
                            {item.caption && (
                                <Typography variant="caption" sx={{ ...theme.typography.subMenuCaption }} display="block" gutterBottom>
                                    {item.caption}
                                </Typography>
                            )}
                        </Typography>
                    )
                }
            >
                {items}
            </List>

            {/* group divider */}
            <Divider sx={{ mt: 0.25, mb: 1.25 }} />
        </>
    );
};

MainNavGroup.propTypes = {
    item: PropTypes.object
};

export default MainNavGroup;
