import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import DrawerRadio from './DrawerRadio';

const Others = ({ formik }) => {
    // Default Value
    const [others, setOthers] = useState(false);

    const props = {
        formik,
        mainValue: others,
        mainValueSetter: setOthers,
        name: 'meta.others',
        label: 'Others'
    };

    return <DrawerRadio {...props} />;
};
Others.propTypes = {
    formik: PropTypes.object
};
export default Others;
