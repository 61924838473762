import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import DrawerRadio from './DrawerRadio';
import DrawerDropdown from './DrawerDropdown';
import { useParams } from 'react-router-dom';
import SurveyService from 'services/survey.service';

const Category = ({ formik }) => {
    // Default Value
    const [category, setCategory] = useState([]);
    const [categories, setCategories] = useState([]);
    const { surveyToken } = useParams();

    const fetchCategories = async () => {
        const { data } = await SurveyService.getQuestionCat(surveyToken);
        setCategories(data.map((category) => category.name.en));
    };
    useEffect(() => {
        fetchCategories();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const props = {
        formik,
        mainValue: formik?.values?.meta?.category ?? '',
        mainValueSetter: setCategory,
        name: 'meta.category',
        label: 'Select Category',
        dropdowns: categories
    };

    return <DrawerDropdown {...props} />;
};

Category.propTypes = {
    formik: PropTypes.object
};
export default Category;
