import { Backdrop, CircularProgress } from '@mui/material';
import PropTypes from 'prop-types';

const Loader = ({ loaderOpen }) => {
    const handleLoaderToggle = () => {};
    return (
        <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={loaderOpen} onClick={handleLoaderToggle}>
            <CircularProgress color="inherit" />
        </Backdrop>
    );
};
Loader.propTypes = {
    loaderOpen: PropTypes.bool
};
export default Loader;
