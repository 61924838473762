import React, { useState } from 'react';
import { Card, CardContent, CardActions, IconButton, Typography, Grid, Button, CardMedia, Box, Alert } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import VisaImage from 'assets/images/icons/visa.png';
import { CardElement, Elements, PaymentElement, useElements, useStripe } from '@stripe/react-stripe-js';
import PropTypes from 'prop-types';
import { loadStripe } from '@stripe/stripe-js';
import { useTheme } from '@emotion/react';
import PaymentService from 'services/payment.service';
import { useSnackbar } from 'notistack';
import useAuth from 'hooks/useAuth';

const AddCardForm = ({ clientSecret, setCardAddingState, setAddingButtonLoadingState, setCards, fetchPaymentIntent }) => {
    const stripe = useStripe();
    const elements = useElements();
    const { enqueueSnackbar } = useSnackbar();

    const [errorMessage, setErrorMessage] = useState('');
    const theme = useTheme();
    const { user } = useAuth();

    const handleSubmit = async (e) => {
        e.preventDefault();
        setAddingButtonLoadingState(true);

        const { setupIntent, error } = await stripe.confirmCardSetup(clientSecret.toString(), {
            payment_method: {
                type: 'card',
                card: elements.getElement(CardElement),
                billing_details: { name: 'Card Name' }
            }
        });

        if (error) {
            setErrorMessage(error.message);
            setAddingButtonLoadingState(false);
            fetchPaymentIntent();
        } else {
            setErrorMessage(null);
            // Handle successful setup here
            const { data } = await PaymentService.addPaymentMethodToUser({ card: setupIntent.payment_method });

            setCards(data);
            setAddingButtonLoadingState(false);

            setCardAddingState(false);
            enqueueSnackbar('Card added successfully!', {
                variant: 'success',
                autoHideDuration: 3000
            });
        }
    };

    const cardElementOptions = {
        style: {
            base: {
                fontSize: '28px', // Adjust the font size here
                color: '#32325d',
                '::placeholder': {
                    color: '#aab7c4'
                },
                padding: '10px 12px' // Adjust padding here
            },
            invalid: {
                color: '#fa755a'
            }
        },
        hidePostalCode: true
    };

    return (
        <form id="payment-form" onSubmit={handleSubmit}>
            {/* <PaymentElement id="payment-element" /> */}
            <CardElement options={cardElementOptions} />
            {/* <button type="submit" disabled={!stripe || !elements}>
                Confirm Card Setup
            </button> */}
            {errorMessage && (
                <Alert severity="error" style={{ marginTop: '15px' }}>
                    {errorMessage}
                </Alert>
            )}
        </form>
    );
};

AddCardForm.propTypes = {
    clientSecret: PropTypes.string.isRequired,
    setCardAddingState: PropTypes.func.isRequired,
    setAddingButtonLoadingState: PropTypes.func.isRequired,
    setCards: PropTypes.func.isRequired,
    fetchPaymentIntent: PropTypes.func.isRequired
};

export default AddCardForm;
