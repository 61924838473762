import React, { useState } from 'react';
import DrawerRadio from './DrawerRadio';
import PropTypes from 'prop-types';

const Randomize = ({ formik }) => {
    // Default Value
    const [randomize, setRandomize] = useState(false);

    const props = {
        formik,
        mainValue: randomize,
        mainValueSetter: setRandomize,
        name: 'meta.randomize',
        label: 'Randomized'
    };

    return <DrawerRadio {...props} />;
};

Randomize.propTypes = {
    formik: PropTypes.object
};
export default Randomize;
