import { useEffect, useState } from 'react';
import { Grid, Paper, LinearProgress, Typography } from '@mui/material';
import { DoneAll } from '@mui/icons-material';
import AvTimerIcon from '@mui/icons-material/AvTimer';
import TimelapseIcon from '@mui/icons-material/Timelapse';
import RemoveRedEyeTwoToneIcon from '@mui/icons-material/RemoveRedEyeTwoTone';
import DashboardIcon from '@mui/icons-material/Dashboard';
import UserCountCard from 'ui-component/cards/UserCountCard';
import ReportCard from 'ui-component/cards/ReportCard';
import InvertColorsOffIcon from '@mui/icons-material/InvertColorsOff';
import SurveyService from 'services/survey.service';
import { useParams } from 'react-router-dom';
import { Box } from '@mui/system';
import { toSafeInteger } from 'lodash';
import ListSkeleton from 'views/ListSkeleton';

const Summary = () => {
    const [surveyMeta, setSurveyMeta] = useState({});
    const [isLoading, setIsLoading] = useState(true);

    const [reportSummary, setReportSummary] = useState({
        avg_completion_time: 0,
        completed: 0,
        completion_rate: 0,
        started: 0,
        views: 0
    });

    const { surveyToken } = useParams();
    const loadSurveyReportSummary = async () => {
        const response = await SurveyService.getReportSummary(surveyToken);
        setIsLoading(false);
        setReportSummary(response?.data?.report_summary);
        setSurveyMeta(response?.data?.survey_meta);
    };

    useEffect(() => {
        loadSurveyReportSummary();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            {isLoading ? (
                <ListSkeleton number={10} />
            ) : (
                <>
                    <Grid container spacing={1}>
                        <Grid xs={12} item>
                            <Typography variant="h4" color="text.primary" sx={{ marginBottom: '5px' }}>
                                Quota Used ({surveyMeta.quota - surveyMeta.remaining_quota} / {surveyMeta.quota}){' '}
                                {100 - toSafeInteger(((surveyMeta.remaining_quota / surveyMeta.quota) * 100).toPrecision(2))}%
                            </Typography>
                        </Grid>
                        <Grid xs={12} item>
                            <LinearProgress
                                variant="determinate"
                                value={100 - (surveyMeta.remaining_quota / surveyMeta.quota) * 100}
                                sx={{ marginBottom: '15px', height: '10px', borderRadius: '10px' }}
                            />
                        </Grid>
                    </Grid>

                    {surveyMeta?.restrictions?.length > 0 && (
                        <Typography variant="h3" color="text.secondary" sx={{ marginBottom: '15px' }}>
                            Slots:
                        </Typography>
                    )}

                    {surveyMeta?.restrictions?.map((restriction, index) => (
                        <Grid container spacing={2}>
                            <Grid xs={2} item>
                                <Typography variant="h5" color="text.secondary" sx={{ marginBottom: '15px' }}>
                                    Remaining Slot {index + 1}: <br /> (
                                    {restriction?.restriction_rules?.map((rule) => `${rule.name} : ${rule.value}`)?.join(' and ')})
                                </Typography>
                            </Grid>
                            <Grid xs={9} item>
                                <LinearProgress
                                    variant="determinate"
                                    value={(restriction?.remaining_allocated_survey / restriction?.allocated_survey) * 100}
                                    sx={{ marginBottom: '15px', height: '25px', borderRadius: '5px' }}
                                />
                            </Grid>
                            <Grid xs={1} item>
                                <Typography variant="h5" color="text.secondary" sx={{ marginBottom: '15px' }}>
                                    {(restriction?.remaining_allocated_survey / restriction?.allocated_survey) * 100}%
                                </Typography>
                            </Grid>
                        </Grid>
                    ))}

                    <Grid container spacing={2}>
                        <Grid item xs={12} lg={4} sm={6}>
                            <Paper elevation={0} variant="outlined">
                                <ReportCard
                                    secondary="Views"
                                    primary={reportSummary ? reportSummary.views : ''}
                                    iconPrimary={RemoveRedEyeTwoToneIcon}
                                />
                            </Paper>
                        </Grid>
                        <Grid item xs={12} lg={4} sm={6}>
                            <Paper elevation={0} variant="outlined">
                                <ReportCard
                                    secondary="Started"
                                    primary={reportSummary ? reportSummary.started : ''}
                                    iconPrimary={DashboardIcon}
                                />
                            </Paper>
                        </Grid>
                        <Grid item xs={12} lg={4} sm={6}>
                            <Paper elevation={0} variant="outlined">
                                <ReportCard
                                    secondary="Completed"
                                    primary={reportSummary ? reportSummary.completed : ''}
                                    iconPrimary={DoneAll}
                                />
                            </Paper>
                        </Grid>
                        <Grid item xs={12} lg={4} sm={6}>
                            <Paper elevation={0} variant="outlined">
                                <ReportCard
                                    secondary="Avg completion time"
                                    primary={reportSummary ? reportSummary.avg_completion_time : ''}
                                    iconPrimary={AvTimerIcon}
                                />
                            </Paper>
                        </Grid>
                        <Grid item xs={12} lg={4} sm={6}>
                            <Paper elevation={0} variant="outlined">
                                <ReportCard
                                    secondary="Completion rate"
                                    primary={
                                        reportSummary && reportSummary.completed > 0
                                            ? `${Math.round((reportSummary.completed / reportSummary.started).toPrecision(2) * 100)}%`
                                            : '0%'
                                    }
                                    iconPrimary={TimelapseIcon}
                                />
                            </Paper>
                        </Grid>
                        <Grid item xs={12} lg={4} sm={6}>
                            <Paper elevation={0} variant="outlined">
                                <ReportCard
                                    secondary="Drop Off rate"
                                    primary={
                                        reportSummary && reportSummary.completed > 0
                                            ? `${((1 - (reportSummary.completed / reportSummary.started).toPrecision(2)) * 100).toPrecision(
                                                  2
                                              )}%`
                                            : '0%'
                                    }
                                    iconPrimary={InvertColorsOffIcon}
                                />
                            </Paper>
                        </Grid>
                    </Grid>
                </>
            )}
        </>
    );
};
export default Summary;
