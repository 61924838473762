/* eslint-disable no-return-assign */
/* eslint-disable react/self-closing-comp */
import { Box, Button, Grid, Link, Typography, useMediaQuery } from '@mui/material';
import GoogleSheet from 'assets/images/icons/Google_Sheets_Logo.svg';
import Item from './Item';
import AnimateButton from 'ui-component/extended/AnimateButton';
import { useTheme } from '@mui/material/styles';
import useScriptRef from 'hooks/useScriptRef';
import useConfig from 'hooks/useConfig';
import RegistrationService from 'services/registration.service';
import { useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import ReportService from 'services/report.service';
import { LoadingButton } from '@mui/lab';
import { useSnackbar } from 'notistack';
import { useConfirm } from 'material-ui-confirm';
import PropTypes from 'prop-types';

const Integration = () => {
    const theme = useTheme();
    const confirm = useConfirm();

    const { surveyToken } = useParams();
    const [isGoogleAccountConnected, setIsGoogleAccountConnected] = useState(false);
    const [syncButtonLoading, setSyncButtonLoading] = useState(false);
    const [googleAccount, setGoogleAccount] = useState(null);
    const { enqueueSnackbar } = useSnackbar();

    const googleSheetHandler = async () => {
        try {
            window.localStorage.setItem('attemptedGoogleSheetSurveyToken', surveyToken);
            const { data } = await RegistrationService.googleSheetLogin(surveyToken);
            window.location.href = data.url;
        } catch (err) {
            console.error(err);
        }
    };

    const checkGoogleAccountConnectivity = async () => {
        const { data: googleAccount } = await ReportService.checkGoogleAccountConnectivity(surveyToken);
        setIsGoogleAccountConnected(googleAccount.status);

        if (googleAccount.status) {
            setGoogleAccount(googleAccount.data);
        }
    };

    const googleSheetSync = async () => {
        setSyncButtonLoading(true);
        const { data } = await ReportService.syncSurveyResponseToGoogleSheet(surveyToken);
        setSyncButtonLoading(false);
        enqueueSnackbar('Google Sheet successfully synced with your current response(s)', {
            variant: 'success',
            autoHideDuration: 3000
        });
    };

    const deleteIntegration = async () => {
        confirm({ description: 'Are you sure that you want to revoked your google sheet permissions? ' }).then(async () => {
            const { data } = await ReportService.deleteGoogleSheetIntegration(surveyToken);
            console.log(data);
            enqueueSnackbar('Google Sheets API permissions have been successfully revoked', {
                variant: 'success',
                autoHideDuration: 3000
            });
            window.location.href = `${window.location.href}?tab=integration`;
        });
    };

    useEffect(() => {
        checkGoogleAccountConnectivity();
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <Box sx={{ flexGrow: 1 }}>
            <Grid container spacing={2}>
                <Grid
                    xs={12}
                    sm={12}
                    md={12}
                    sx={{
                        background: '#fff',
                        borderTopLeftRadius: '10px',
                        borderBottomLeftRadius: '10px'
                    }}
                >
                    <Item>
                        <Box sx={{ p: 2 }}>
                            <Grid container spacing={2}>
                                <Grid item>
                                    <img src={GoogleSheet} alt="googleSheet" width={36} height={36} />
                                </Grid>
                                <Grid item xs>
                                    <Typography
                                        variant="h3"
                                        style={{
                                            fontFamily:
                                                'BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif'
                                        }}
                                    >
                                        Google Sheets {isGoogleAccountConnected && `(${googleAccount?.email})`}
                                    </Typography>
                                    <Typography
                                        variant="body1"
                                        style={{
                                            fontFamily:
                                                'BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif'
                                        }}
                                    >
                                        Send your data straight to Google Sheets.
                                        {isGoogleAccountConnected ? 'Syncs when you click Sync Now.' : 'Syncs as results come in'}
                                        {isGoogleAccountConnected && (
                                            <Link
                                                sx={{ cursor: 'pointer' }}
                                                onClick={() => window.open(googleAccount?.meta?.spreadsheet_url, '_blank')}
                                            >
                                                View your Spreadsheet
                                            </Link>
                                        )}
                                    </Typography>
                                </Grid>
                                <Grid item xs>
                                    {isGoogleAccountConnected ? (
                                        <>
                                            <LoadingButton
                                                disableElevation
                                                onClick={googleSheetSync}
                                                size="large"
                                                variant="contained"
                                                sx={{
                                                    color: 'black.700',
                                                    backgroundColor: '#222',
                                                    borderColor: 'transparent',
                                                    marginRight: '20px'
                                                }}
                                                loading={syncButtonLoading}
                                            >
                                                Sync Now
                                            </LoadingButton>

                                            <LoadingButton
                                                disableElevation
                                                onClick={deleteIntegration}
                                                size="large"
                                                variant="contained"
                                                sx={{
                                                    color: 'black.700',
                                                    backgroundColor: '#222',
                                                    borderColor: 'transparent'
                                                }}
                                            >
                                                Delete Integration
                                            </LoadingButton>
                                        </>
                                    ) : (
                                        <Button
                                            disableElevation
                                            onClick={googleSheetHandler}
                                            size="large"
                                            variant="contained"
                                            sx={{
                                                color: 'black.700',
                                                backgroundColor: '#222',
                                                borderColor: 'transparent'
                                            }}
                                        >
                                            Connect
                                        </Button>
                                    )}
                                </Grid>
                            </Grid>
                        </Box>

                        {/* <AnimateButton>
                            <Button
                                disableElevation
                                fullWidth
                                onClick={googleHandler}
                                size="large"
                                variant="outlined"
                                sx={{
                                    color: 'grey.700',
                                    backgroundColor: theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.grey[50],
                                    borderColor: theme.palette.mode === 'dark' ? theme.palette.dark.light + 20 : theme.palette.grey[100]
                                }}
                            >
                            </Button>
                        </AnimateButton> */}
                    </Item>
                </Grid>
            </Grid>
        </Box>
    );
};

// Integration.propTypes = {
// };

export default Integration;
