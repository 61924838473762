/* eslint-disable no-return-assign */
/* eslint-disable react/self-closing-comp */
import { Box, Button, Grid, IconButton, Link, TextField, Typography, useMediaQuery } from '@mui/material';
import GoogleSheet from 'assets/images/icons/Google_Sheets_Logo.svg';
import Item from './Item';
import AnimateButton from 'ui-component/extended/AnimateButton';
import { useTheme } from '@mui/material/styles';
import useScriptRef from 'hooks/useScriptRef';
import useConfig from 'hooks/useConfig';
import RegistrationService from 'services/registration.service';
import { useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import ReportService from 'services/report.service';
import { LoadingButton } from '@mui/lab';
import { useSnackbar } from 'notistack';
import { useConfirm } from 'material-ui-confirm';
import { IconBrandFacebook, IconBrandLinkedin, IconBrandTwitter, IconCopy, IconMail, IconMessage, IconRecordMail } from '@tabler/icons';
import PropTypes from 'prop-types';

const Share = ({ survey }) => {
    const [respondentAppName, setRespondentAppName] = useState(process.env.REACT_APP_RESPONDENT_APP);
    const confirm = useConfirm();
    const [shareLinkAppName, setShareLinkAppName] = useState(process.env.REACT_APP_SHARE_DOMAIN);
    const { enqueueSnackbar } = useSnackbar();
    const surveyShortUrl = `${shareLinkAppName}/${survey?.meta?.short_code}`;
    const twitterText = `Could you take a moment to fill in this surveyflip?%0A→ ${surveyShortUrl}%0A%0AWe would really appreciate it!`;
    const emailSubject = 'Could you take a moment to fill in this surveyflip?';

    const { surveyToken } = useParams();
    const unsecuredCopyToClipboard = (text) => {
        const textArea = document.createElement('textarea');
        textArea.value = text;
        document.body.appendChild(textArea);
        textArea.focus();
        textArea.select();
        try {
            document.execCommand('copy');
        } catch (err) {
            console.error('Unable to copy to clipboard', err);
        }
        document.body.removeChild(textArea);
    };

    const copyText = (entryText) => {
        // window.navigator.clipboard.writeText(entryText);
        unsecuredCopyToClipboard(entryText);
        enqueueSnackbar('Short Link Copied', { variant: 'success', autoHideDuration: 3000 });
    };

    const handleCustomizeLink = (event) => {
        event.preventDefault();
        enqueueSnackbar('This is a premium feature.', { variant: 'warning', autoHideDuration: 3000 });
    };

    return (
        <Box sx={{ flexGrow: 1 }}>
            <Grid container spacing={2}>
                <Grid
                    xs={12}
                    sm={12}
                    md={12}
                    sx={{
                        background: '#fff',
                        borderTopLeftRadius: '10px',
                        borderBottomLeftRadius: '10px'
                    }}
                >
                    <Item>
                        <Box sx={{ pb: 2, pt: 4, pl: 4 }}>
                            <Grid container>
                                <Grid item xs={3}>
                                    <TextField size="small" value={`${shareLinkAppName}/${survey?.meta?.short_code}`} fullWidth />
                                </Grid>
                                <Grid item xs={9}>
                                    <Button
                                        variant="contained"
                                        startIcon={<IconCopy />}
                                        disableElevation
                                        onClick={() => copyText(`${shareLinkAppName}/${survey?.meta?.short_code}`)}
                                        sx={{
                                            borderTopLeftRadius: '0px',
                                            paddingTop: '8px',
                                            paddingBottom: '8px',
                                            borderBottomLeftRadius: '0px',
                                            marginLeft: '-6px'
                                        }}
                                        size="small"
                                    >
                                        Copy Link
                                    </Button>
                                    <Link
                                        href="#"
                                        onClick={handleCustomizeLink}
                                        color="inherit"
                                        style={{ marginTop: '10px', marginLeft: '10px' }}
                                    >
                                        Customize link
                                    </Link>
                                </Grid>
                            </Grid>
                            <Grid container spacing={2} mt={0.01}>
                                <Grid item md={6}>
                                    <IconButton
                                        color="primary"
                                        component="a"
                                        href={`https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(surveyShortUrl)}`}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        <IconBrandFacebook />
                                    </IconButton>
                                    <IconButton
                                        component="a"
                                        href={`https://www.linkedin.com/sharing/share-offsite/?url=${encodeURIComponent(surveyShortUrl)}`}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        color="primary"
                                    >
                                        <IconBrandLinkedin />
                                    </IconButton>
                                    <IconButton
                                        component="a"
                                        href={`https://twitter.com/intent/tweet?url=${encodeURIComponent(twitterText)}`}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        color="primary"
                                    >
                                        <IconBrandTwitter />
                                    </IconButton>
                                    <IconButton component="a" href={`mailto:?subject=${encodeURIComponent(emailSubject)}`} color="primary">
                                        <IconMail />
                                    </IconButton>
                                </Grid>
                            </Grid>
                        </Box>
                    </Item>
                </Grid>
            </Grid>
        </Box>
    );
};

Share.propTypes = {
    survey: PropTypes.object
};

export default Share;
