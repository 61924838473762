/* eslint-disable react/destructuring-assignment */
import PropTypes from 'prop-types';

// material-ui
import { Box, Drawer, DialogContent, DialogTitle, Typography, Grid, LinearProgress, Chip } from '@mui/material';
import QuestionTypeReportGenerator from 'utils/QuestionTypeReportGenerator';
import MainCard from 'ui-component/cards/MainCard';

const UserAssessment = ({ open, handleSidebarOpenClose, answerId, currentAnswer }) => {
    const questionTypeReportGenerator = (selectedQuestionTypeIdentifier, answers, question) => {
        const typeGenerator = new QuestionTypeReportGenerator(question);

        if (typeof typeGenerator[selectedQuestionTypeIdentifier] === 'function') {
            return typeGenerator[selectedQuestionTypeIdentifier](answers);
        }
        return typeGenerator.GenericQuestion(answers);
    };

    const findQuestion = (id) => currentAnswer.questions.find((ques) => ques.id === id);

    return (
        <Drawer
            ModalProps={{
                keepMounted: true
            }}
            open={open}
            anchor="right"
            variant="temporary"
            keepMounted
            PaperProps={{
                sx: {
                    maxWidth: 1000
                }
            }}
            onClose={() => handleSidebarOpenClose(false, answerId)}
        >
            {open && (
                <>
                    <DialogTitle sx={{ backgroundColor: '#eceff1' }}>Overall Information</DialogTitle>
                    <DialogContent>
                        <Box sx={{ m: 2 }} textAlign="center">
                            <Typography variant="h2">
                                {currentAnswer?.respondent?.first_name} {currentAnswer?.respondent?.last_name}
                            </Typography>
                            <Typography variant="p">{currentAnswer?.respondent?.email}</Typography>
                        </Box>

                        <Box sx={{ mb: 3, mt: 3 }}>
                            <Typography variant="h5" mt={1}>
                                Status:{' '}
                                {currentAnswer?.completed === 1 ? (
                                    <Chip size="small" sx={{ bgcolor: '#4caf50', color: 'white', fontWeight: 'bold' }} label="Completed" />
                                ) : (
                                    <Chip size="small" sx={{ bgcolor: '#e65100', color: 'white', fontWeight: 'bold' }} label="Incomplete" />
                                )}
                            </Typography>
                            <Typography variant="h5" mt={1}>
                                Time Taken: {currentAnswer?.completion_time}
                            </Typography>
                        </Box>
                        <Grid container spacing={18}>
                            <Grid item md={4}>
                                <Typography variant="h5" mb={1}>
                                    Performance:{' '}
                                </Typography>
                                <Typography>Score: </Typography>
                                <Typography variant="h1">{currentAnswer?.score}%</Typography>
                            </Grid>
                            <Grid item md={8}>
                                <Typography variant="h5" mb={1}>
                                    Skill Scores:
                                </Typography>
                                {currentAnswer?.skill_score?.map((category) => (
                                    <>
                                        <Typography>{category.category}</Typography>

                                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                            <Box sx={{ width: '100%', mr: 1 }}>
                                                <LinearProgress
                                                    variant="determinate"
                                                    color={category.scores > 50 ? 'success' : 'error'}
                                                    value={category.scores}
                                                    sx={{
                                                        height: 10,
                                                        borderRadius: 5
                                                    }}
                                                />
                                            </Box>
                                            <Box sx={{ minWidth: 35 }}>
                                                <Typography
                                                    variant="body2"
                                                    color={category.scores > 50 ? 'success' : 'error'}
                                                >{`${category.scores}%`}</Typography>
                                            </Box>
                                        </Box>
                                    </>
                                ))}
                            </Grid>
                        </Grid>

                        <Box sx={{ mt: 5 }}>
                            <Typography variant="h4" textAlign="left" sx={{ mb: 1, fontWeight: 'light' }}>
                                Candidate Answers
                            </Typography>
                            {currentAnswer?.answer_texts?.map((answer, index) => (
                                <Grid
                                    container
                                    mb={2}
                                    key={index}
                                    sx={{
                                        backgroundColor: '#F5F5F5',
                                        padding: '10px'
                                    }}
                                >
                                    <Grid item>
                                        <Grid container>
                                            <Grid item>
                                                <Typography color="#333" variant="h4">
                                                    {`${index + 1}. `}
                                                    {findQuestion(answer?.qID)?.question_title} &nbsp;
                                                </Typography>
                                                <Typography color="#333" variant="h6" ml={2} mt={1}>
                                                    Answer
                                                </Typography>
                                                <Typography color="#333" variant="p" ml={2}>
                                                    {questionTypeReportGenerator(answer?.identifier, answer, findQuestion(answer?.qID))}
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            ))}
                        </Box>
                    </DialogContent>
                </>
            )}
        </Drawer>
    );
};

UserAssessment.propTypes = {
    open: PropTypes.bool,
    answerId: PropTypes.number,
    handleSidebarOpenClose: PropTypes.func,
    currentAnswer: PropTypes.object
};

export default UserAssessment;
