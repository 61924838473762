import { DataGrid } from '@mui/x-data-grid';
import PropTypes from 'prop-types';
import { Card, Grid, Alert } from '@mui/material';

const ListView = ({
    rows,
    pageSize,
    onPageSizeChange,
    columns,
    paginationProp,
    totalRowCount,
    handlePageChange,
    handleSortChange,
    handleFilterChange,
    isLoading
}) => {
    const NoRowsOverlay = () => (
        <Grid item>
            <Alert severity="error">No surveys found.</Alert>
        </Grid>
    );

    return (
        <Card sx={{ marginTop: 2 }}>
            <DataGrid
                rows={rows}
                pagination
                paginationMode="server"
                sortingMode="server"
                filterMode="server"
                autoHeight
                pageSize={pageSize}
                onPageSizeChange={(newPageSize) => onPageSizeChange(newPageSize)}
                columns={columns.current}
                rowsPerPageOptions={[5, 10, 15]}
                sx={{
                    'div.MuiDataGrid-cell': {
                        borderBottom: '1px solid #e0e0e0'
                    }
                }}
                disableColumnMenu
                disableSelectionOnClick
                page={paginationProp.page}
                rowCount={totalRowCount}
                onPageChange={handlePageChange}
                components={{ NoRowsOverlay }}
                onSortModelChange={handleSortChange}
                onFilterModelChange={handleFilterChange}
                sortingOrder={['desc', 'asc']}
                loading={isLoading}
            />
        </Card>
    );
};
ListView.propTypes = {
    isLoading: PropTypes.bool,
    rows: PropTypes.array,
    pageSize: PropTypes.number,
    onPageSizeChange: PropTypes.func,
    columns: PropTypes.object,
    paginationProp: PropTypes.object,
    totalRowCount: PropTypes.number,
    handlePageChange: PropTypes.func,
    handleSortChange: PropTypes.func,
    handleFilterChange: PropTypes.func
};
export default ListView;
