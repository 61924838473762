import { createSlice } from '@reduxjs/toolkit';

// initial state
const initialState = {
    openItem: ['dashboard'],
    openMainItem: ['dashboard'],
    openChildItem: [],
    drawerOpen: false
};

// ==============================|| SLICE - MENU ||============================== //

const menu = createSlice({
    name: 'menu',
    initialState,
    reducers: {
        activeItem(state, action) {
            state.openItem = action.payload;
        },
        activeMainItem(state, action) {
            state.openMainItem = action.payload;
        },
        activeChildItem(state, action) {
            state.openChildItem = action.payload;
        },
        openDrawer(state, action) {
            state.drawerOpen = action.payload;
        }
    }
});

export default menu.reducer;

export const { activeItem, openDrawer, activeMainItem, activeChildItem } = menu.actions;
