/* eslint-disable react/self-closing-comp */
import {
    Button,
    Card,
    CardContent,
    Dialog,
    DialogActions,
    DialogContent,
    Grid,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    Typography
} from '@mui/material';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import SurveyService from 'services/survey.service';
import useOnePageSurveyStore from 'zustand_store/useOnePageSurveyStore';
import snackcaseToPascalcase from 'utils/helperFunctions';
import useQuestionDrawerStore from 'zustand_store/useQuestionDrawerStrore';
import Item from '../Item';
import { LoadingButton } from '@mui/lab';

// ==============================|| Main Template ||============================== //

const QuestionOptions = ({ survey, formik }) => {
    const drawerOptions = useQuestionDrawerStore((state) => state.ruleset);
    const onePageDrawerTemplates = useQuestionDrawerStore((state) => state.onePageTemplates);
    const surveyRestrictions = useQuestionDrawerStore((state) => state.surveyRestrictions);
    const selectedQuestionTypeIdentifier = useOnePageSurveyStore((state) => state.currentSelectedQuestionIdentifier);

    return (
        <>
            <Item>
                <Typography variant="h5" align="left">
                    Question Options
                </Typography>
                <List>
                    {selectedQuestionTypeIdentifier &&
                        drawerOptions[snackcaseToPascalcase(selectedQuestionTypeIdentifier)].map(
                            (identifier, index) =>
                                (!!surveyRestrictions[identifier] && surveyRestrictions[identifier].includes(survey.survey_type) && (
                                    <ListItem sx={{ paddingLeft: '10px' }} key={index}>
                                        {onePageDrawerTemplates[identifier](formik)}
                                    </ListItem>
                                )) ||
                                (!surveyRestrictions[identifier] && (
                                    <ListItem sx={{ paddingLeft: '10px' }} key={index}>
                                        {onePageDrawerTemplates[identifier](formik)}
                                    </ListItem>
                                ))
                        )}
                </List>
            </Item>
        </>
    );
};

QuestionOptions.propTypes = {
    survey: PropTypes.object,
    formik: PropTypes.any
};

export default QuestionOptions;
