import { Grid, Skeleton } from '@mui/material';
import PropTypes from 'prop-types';
import { range } from 'lodash';

export default function GridSkeleton({ number }) {
    return (
        <Grid container>
            {range(0, number).map((num, index) => (
                <Grid item xs={2} key={index}>
                    <Skeleton sx={{ marginTop: '20px', marginRight: '20px' }} variant="rect" height={200} />
                </Grid>
            ))}
        </Grid>
    );
}
GridSkeleton.propTypes = {
    number: PropTypes.number
};
