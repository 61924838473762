/* eslint-disable react/self-closing-comp */
import { Button, Grid, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import SurveyService from 'services/survey.service';
import useOnePageSurveyStore from 'zustand_store/useOnePageSurveyStore';

import { IconCopy, IconPresentationAnalytics } from '@tabler/icons';
import Tooltip from '@mui/material/Tooltip';
import { useSnackbar } from 'notistack';
import { useParams } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import QuestionService from 'services/question.service';
import useQuestionStore from 'zustand_store/useQuestionStore';

// ==============================|| Main Template ||============================== //

const Preview = ({ showIcon, showTitle }) => {
    const currentQuestion = useOnePageSurveyStore((state) => state.currentQuestion);
    const currentlyEditingQuestionId = useOnePageSurveyStore((state) => state.currentlyEditingQuestionId);
    const { surveyToken } = useParams();

    const handlePreview = () => {
        window.open(`/${surveyToken}/question-view/${currentlyEditingQuestionId}`, '_blank', 'noopener,noreferrer');
    };

    return (
        <>
            {showIcon && (
                <Tooltip title="Preview Qustion" placement="top">
                    <Button disabled={typeof currentQuestion?.id !== 'number'} onClick={() => handlePreview()}>
                        <IconPresentationAnalytics />
                    </Button>
                </Tooltip>
            )}
            {showTitle && (
                <Typography variant="h6" disabled={typeof currentQuestion?.id !== 'number'} onClick={() => handlePreview()}>
                    Preview
                </Typography>
            )}
        </>
    );
};

Preview.propTypes = {
    showIcon: PropTypes.bool,
    showTitle: PropTypes.bool
};

export default Preview;
