import { first } from 'lodash';
import create from 'zustand';
import { devtools, persist } from 'zustand/middleware';
import { v4 as uuidv4 } from 'uuid';

const onePageSurveyStore = (set, get) => ({
    // State Variables
    currentSelectedQuestionIdentifier: null,
    questionTypes: [],
    questions: [],
    currentlyEditingQuestionTitle: '',
    currentlyEditingQuestionId: '',
    currentQuestion: {},
    // Example Of mutation
    SET_SELECTED_QUESTION_IDENTIFIER: (identifier) => {
        set((state) => {
            state.currentSelectedQuestionIdentifier = identifier;
        });
    },
    MUTATE_QUESTIONS_ORDER: (changedOrderQuestions) => {
        set((state) => {
            const changedQuestionIds = changedOrderQuestions.map((ques) => ques.id);

            state.questions = get().questions.map((question) => {
                if (changedQuestionIds.includes(question.id)) {
                    const newOrder = changedOrderQuestions.find((ques) => ques.id === question.id)?.order;
                    return {
                        ...question,
                        order: newOrder
                    };
                }
                return question;
            });
        });
    },
    SET_CURRENT_QUESTION: (question) => {
        set((state) => {
            state.currentlyEditingQuestionId = question?.id;
            state.currentQuestion = question;
        });
    },
    RESET_CURRENT_QUESTION: (question) => {
        set((state) => {
            state.currentlyEditingQuestionTitle = '';
            state.currentlyEditingQuestionId = '';
            state.currentQuestion = {};
            state.currentSelectedQuestionIdentifier = null;
        });
    },
    SET_FIRST_QUESTION_AS_CURRENT_QUESTION: (question) => {
        set((state) => {
            const firstQuestion = first(get().questions);
            state.currentlyEditingQuestionTitle = firstQuestion?.question_title;
            state.currentlyEditingQuestionId = firstQuestion?.id;
            state.currentQuestion = firstQuestion;
            state.currentSelectedQuestionIdentifier = firstQuestion?.question_type?.identifier;
        });
    },
    SET_CURRENT_QUESTION_TITLE: (title) => {
        set((state) => {
            state.currentlyEditingQuestionTitle = title;
        });
    },
    SET_CURRENT_QUESTION_ID: (id) => {
        set((state) => {
            state.currentlyEditingQuestionId = id;
            state.currentQuestion = get().questions?.find((question) => question?.id === get().currentlyEditingQuestionId);
        });
    },
    SET_QUESTION_TYPES: (questionTypes) => {
        set((state) => {
            state.questionTypes = questionTypes;
        });
    },
    SET_QUESTIONS: (questions) => {
        set((state) => {
            state.questions = questions;
        });
    },
    ADD_QUESTION: (question) => {
        set((state) => {
            if (get().questions.length > 0) {
                state.questions = [...get().questions, { ...question }];
            } else {
                state.questions = [{ ...question }];
            }
            state.currentQuestion = { ...question };
        });
    },
    UPDATE_QUESTION: (id, newQuestionData) => {
        set((state) => {
            state.questions = get().questions?.map((question) => {
                if (question?.id === id) {
                    return newQuestionData;
                }
                return question;
            });
        });
    },
    SYNC_QUESTION_WITH_FORMIK: (newQuestionData) => {
        set((state) => {
            state.questions = get().questions?.map((question) => {
                if (question?.id === get().currentQuestion?.id) {
                    return newQuestionData;
                }
                return question;
            });
            state.currentQuestion = newQuestionData;
        });
    },
    DELETE_QUESTION: (id) => {
        set((state) => {
            state.questions = get().questions?.filter((question) => id !== question.id);
        });
    },
    DUPLICATE_CURRENT_QUESTION: () => {
        set((state) => {
            const currentQuestion = get().currentQuestion;
            const questions = get().questions;
            const newQuestionId = uuidv4();
            const newQuestion = {
                ...currentQuestion,
                id: newQuestionId,
                question_title: `Copy of ${currentQuestion.question_title}`,
                order: questions?.length + 1
            };
            get().ADD_QUESTION(newQuestion);
            // get().SET_CURRENT_QUESTION_ID(newQuestion?.id);
            // get().SET_SELECTED_QUESTION_IDENTIFIER(newQuestion?.question_type?.identifier);

            // state.currentQuestion = newQuestion;

            // state.currentlyEditingQuestionTitle = newQuestion?.question_title;
            // state.currentlyEditingQuestionId = newQuestionId;
            // state.currentSelectedQuestionIdentifier = newQuestion?.question_type?.identifier;
        });
    },
    // Example of action/getter
    getCurrentSelectedQuestionIdentifier: () => get().currentSelectedQuestionIdentifier
});

const useOnePageSurveyStore = create(
    devtools(
        persist(onePageSurveyStore, {
            name: 'onePageSurvey'
        })
    )
);

export default useOnePageSurveyStore;
