import React, { lazy, useEffect } from 'react';
import {
    Alert,
    AlertTitle,
    Box,
    Card,
    Typography,
    Grid,
    Button,
    Pagination,
    IconButton,
    Link,
    Backdrop,
    CircularProgress
} from '@mui/material';
import Loadable from 'ui-component/Loadable';
import SurveyService from 'services/survey.service';
import { useParams } from 'react-router-dom';
import ReportService from 'services/report.service';
import { first } from 'lodash';
import QuestionTypeReportGenerator from 'utils/QuestionTypeReportGenerator';
import { IconDownload } from '@tabler/icons';
import { useSnackbar } from 'notistack';
import ListSkeleton from 'views/ListSkeleton';

const ResponseSummary = () => {
    const { surveyToken } = useParams();
    const [responses, setResponses] = React.useState([]);
    const [showMore, setShowMore] = React.useState(false);
    const [userResponses, setUserResponses] = React.useState([]);
    const [isLoading, setIsLoading] = React.useState(true);
    const [paginationCurrentPage, setPaginationCurrentPage] = React.useState(1);
    const { enqueueSnackbar } = useSnackbar();
    const [loaderOpen, setLoaderOpen] = React.useState(false);
    const filterQuestionTypes = ['MatrixQuestion', 'MatrixRubricQuestion', 'MatrixDropdownQuestion', 'MatrixDynamicQuestion'];
    const handleLoaderClose = () => {
        setLoaderOpen(false);
    };
    const handleLoaderOpen = () => {
        setLoaderOpen(true);
    };

    const questionTypeComponent = (selectedQuestionTypeIdentifier, data) => {
        const Component = Loadable(lazy(() => import(`views/preview/summaryQuestionsTypes/${selectedQuestionTypeIdentifier}`)));
        return <Component data={data} />;
    };

    const questionTypeReportGenerator = (selectedQuestionTypeIdentifier, answers, question) => {
        const typeGenertor = new QuestionTypeReportGenerator(question);

        if (typeof typeGenertor[selectedQuestionTypeIdentifier] === 'function') {
            return typeGenertor[selectedQuestionTypeIdentifier](answers);
        }
        console.log(typeof typeGenertor.GenericQuestion(answers), ' => ', selectedQuestionTypeIdentifier);
        return typeGenertor.GenericQuestion(answers);
    };

    const fetchResponses = async (pageNum = 1) => {
        setIsLoading(true);
        const resp = await SurveyService.getDetailedResponse(surveyToken, pageNum);
        setResponses(resp.data);
        // setUserResponses(first(resp.data.survey_responses.user_responses) ?? null);
        setUserResponses(resp.data.respondent_responses ?? null);

        // const response = await SurveyService.getReportSummary(surveyToken);
        // setReportSummary(response.data);
        // console.log('2', response.data);
        setIsLoading(false);
    };

    const handleDownload = async (fileName, surveyId, questionId, respondentId) => {
        handleLoaderOpen();
        try {
            const response = await ReportService.downloadMediaResponse(
                {
                    surveyId,
                    questionId,
                    respondentId
                },
                {
                    responseType: 'blob',
                    fileName
                }
            );
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', response?.config?.fileName);
            document.body.appendChild(link);
            link.click();
            handleLoaderClose();
            enqueueSnackbar('File Successfully Downloaded.', { variant: 'success' });
        } catch (e) {
            handleLoaderClose();
            enqueueSnackbar('Error occurred while trying to download the file', { variant: 'error' });
        }
    };

    const showHideHandler = async () => {
        setShowMore((prevValue) => !prevValue);
        setResponses({});
        if (!showMore) {
            const { data } = await ReportService.fullDetailsDetailedAnswer(surveyToken);
            setResponses(data);
        } else {
            fetchResponses();
        }
    };

    useEffect(() => {
        fetchResponses();
        return function cleanup() {
            console.log('cleaning up');
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            {isLoading ? (
                <ListSkeleton number={10} />
            ) : (
                <>
                    {responses?.respondent_responses?.total > 0 ? (
                        <Grid container justifyContent="space-between">
                            <Grid item>
                                <Typography variant="h4">
                                    {first(userResponses?.data)?.respondent?.first_name} {first(userResponses?.data)?.respondent?.last_name}
                                    &apos;s Response
                                </Typography>
                            </Grid>
                            <Grid item>
                                <Pagination
                                    page={paginationCurrentPage}
                                    count={userResponses?.total}
                                    color="primary"
                                    onChange={(el, page) => {
                                        setPaginationCurrentPage(page);
                                        fetchResponses(page);
                                    }}
                                />
                            </Grid>
                        </Grid>
                    ) : (
                        <>
                            {!isLoading && (
                                <Alert severity="error" variant="standard">
                                    <AlertTitle>Info</AlertTitle>
                                    Sorry, there are no public responses for this survey yet.
                                </Alert>
                            )}
                        </>
                    )}
                </>
            )}
            {isLoading ? (
                <ListSkeleton number={10} />
            ) : (
                <Card variant="outline" sx={{ marginTop: '20px', clear: 'both' }}>
                    {responses &&
                        userResponses &&
                        !!userResponses?.data &&
                        first(userResponses?.data)
                            ?.answer_texts?.filter((answer) => !filterQuestionTypes.includes(answer?.identifier))
                            ?.map((answer, index) => (
                                <Grid
                                    container
                                    mb={2}
                                    key={index}
                                    sx={{
                                        backgroundColor: '#F5F5F5',
                                        padding: '10px'
                                    }}
                                >
                                    <Grid item>
                                        <Grid container>
                                            <Grid item>
                                                <Typography color="#333" variant="h4">
                                                    {`${index + 1}. `}
                                                    {answer?.question?.question_title} &nbsp;
                                                </Typography>
                                                <Typography color="#333" variant="h6" ml={2} mt={1}>
                                                    Answer
                                                </Typography>
                                                <Typography color="#333" variant="p" ml={2}>
                                                    {questionTypeReportGenerator(answer?.identifier, answer, answer?.question)}
                                                </Typography>
                                                <br />
                                                {answer?.identifier === 'FilesUploadingQuestion' && (
                                                    <Button
                                                        style={{ marginRight: '3px' }}
                                                        variant="secondary"
                                                        // color="success"
                                                        size="small"
                                                        component={Link}
                                                        onClick={() =>
                                                            handleDownload(
                                                                answer?.answer,
                                                                answer?.question?.survey_id,
                                                                answer?.qID,
                                                                first(userResponses?.data)?.respondent_id
                                                            )
                                                        }
                                                    >
                                                        <IconDownload />
                                                        <Typography>Download</Typography>
                                                    </Button>
                                                )}
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            ))}
                    <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={loaderOpen}>
                        <CircularProgress color="inherit" />
                    </Backdrop>
                </Card>
            )}
        </>
    );
};

ResponseSummary.propTypes = {};

export default ResponseSummary;
