import React, { Fragment } from 'react';
import { Grid, Select, MenuItem, Typography, Button, InputLabel } from '@mui/material';
import { useFormikContext, FastField, FormikProvider, FieldArray } from 'formik';
import Add from '@mui/icons-material/Add';
import Condition from './Condition';
import PropTypes from 'prop-types';

const AllCondition = (props) => {
    const { allQuesData, allQtitle, index, ruleIndex } = props;
    const addCondition = React.useCallback((push) => {
        push({ ques: '', assignmentOp: '', val: '', logicalOp: '' });
    }, []);

    const removeCondition = React.useCallback((remove, index) => {
        remove(index);
    }, []);

    const formik = useFormikContext();
    console.log(formik.values.restrictions);
    // const jumpToIndex = formik.values.restrictions[index][ruleIndex].length - 1;

    return (
        <>
            {formik && formik?.values && (
                <FormikProvider value={formik}>
                    <FieldArray
                        subscription={{}}
                        name={`restrictions[${index}].allRules[${ruleIndex}].cond`}
                        render={({ push, remove }) => (
                            <Fragment key={`restrictions[${index}].allRules[${ruleIndex}].cond`}>
                                <Grid container direction="row" justifyContent="space-between" alignItems="center">
                                    <Grid item xs={1}>
                                        <InputLabel
                                            variant="standard"
                                            sx={{ fontWeight: 'bold', fontSize: 16, marginBottom: '3px' }}
                                            htmlFor="if-condition"
                                        >
                                            Jump to
                                        </InputLabel>
                                    </Grid>
                                    <Grid item xs={3}>
                                        <FastField
                                            size="small"
                                            labelId="if-condition"
                                            id="if-condition"
                                            as={Select}
                                            SelectDisplayProps={{ 'data-testid': 'jump-to-select-box' }}
                                            // value={formik.values.restrictions[index][ruleIndex][jumpToIndex].thenJumpto}
                                            // onChange={formik.handleChange}
                                            fullWidth
                                            name={`restrictions[${[index]}].allRules[${[ruleIndex]}].event.opt`}
                                        >
                                            {allQuesData.slice(index + 1).map((questionData, uniqueKey) => (
                                                <MenuItem value={questionData.id} key={uniqueKey}>
                                                    {questionData.question_title}
                                                </MenuItem>
                                            ))}
                                        </FastField>
                                    </Grid>
                                    <Grid item xs={1}>
                                        <Typography pl={2} sx={{ fontSize: 15, fontWeight: '600' }}>
                                            If
                                        </Typography>
                                    </Grid>

                                    <Grid item xs={7} pt={3} sx={{ textAlign: 'right' }}>
                                        {/* <Button
                                        variant="outlined"
                                        startIcon={<Add />}
                                        onClick={() => {
                                            addCondition(push);
                                        }}
                                    >
                                        Add Condition
                                    </Button> */}
                                        &nbsp;
                                    </Grid>
                                </Grid>
                                <Grid container mt={2}>
                                    <Grid item xs={12}>
                                        {formik.values.restrictions[index].allRules[ruleIndex].cond.map((v, conditionIndex) => (
                                            <Fragment key={conditionIndex}>
                                                <Condition
                                                    {...props}
                                                    removeCondition={removeCondition}
                                                    remove={remove}
                                                    conditionIndex={conditionIndex}
                                                />
                                                {/* <Box mb={4} display="flex" justifyContent="flex-start">
                                                <Button color="error" onClick={() => removeCondition(remove, conditionIndex)}>
                                                    (-) Remove Condition
                                                </Button>
                                            </Box> */}
                                                {/* <Button
                                                variant="outlined"
                                                startIcon={<Add />}
                                                onClick={() => {
                                                    addCondition(push);
                                                }}
                                            >
                                                Add Condition
                                            </Button> */}
                                            </Fragment>
                                        ))}
                                        <Button
                                            variant="outlined"
                                            startIcon={<Add />}
                                            onClick={() => {
                                                addCondition(push);
                                            }}
                                            sx={{ marginTop: 2 }}
                                            role="button"
                                        >
                                            Add Condition
                                        </Button>
                                    </Grid>
                                    <Grid item xs={2}>
                                        <Typography align="right" p={1} sx={{ fontSize: 16 }}>
                                            &nbsp;
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Fragment>
                        )}
                    />
                </FormikProvider>
            )}
        </>
    );
};

AllCondition.propTypes = {
    allQuesData: PropTypes.array,
    allQtitle: PropTypes.func,
    index: PropTypes.number,
    ruleIndex: PropTypes.number
};

export default AllCondition;
