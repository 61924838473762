import { lazy } from 'react';

// project imports
import GuestGuard from 'utils/route-guard/GuestGuard';
import MinimalLayout from 'layout/MinimalLayout';
import NavMotion from 'layout/NavMotion';
import Loadable from 'ui-component/Loadable';
import GoogleSheetLoginHandler from 'views/GoogleSheetLoginHandler';

// login routing
const AuthLogin = Loadable(lazy(() => import('views/pages/authentication/authentication1/Login1')));
const AuthRegister = Loadable(lazy(() => import('views/pages/authentication/authentication1/Register1')));

const AuthRegistration = Loadable(lazy(() => import('views/pages/authentication/authentication1/Register1')));

const AuthForgotPassword = Loadable(lazy(() => import('views/pages/authentication/authentication1/ForgotPassword1')));
const VerifyEmail = Loadable(lazy(() => import('views/pages/authentication/VerifyEmail')));
const ForgetPassword = Loadable(lazy(() => import('views/pages/authentication/ForgetPassword')));
const ResetPassword = Loadable(lazy(() => import('views/pages/authentication/register/ResetPassword')));
const LogoutScreen = Loadable(lazy(() => import('views/pages/authentication/LogoutScreen')));

const ResendMail = Loadable(lazy(() => import('views/pages/authentication/register/ResendMail')));
const GoogleLoginHandler = Loadable(lazy(() => import('views/GoogleLoginHandler')));
const FacebookLoginHandler = Loadable(lazy(() => import('views/FacebookLoginHandler')));
const SsoHandler = Loadable(lazy(() => import('views/SsoHandler')));

// ==============================|| AUTH ROUTING ||============================== //

const LoginRoutes = {
    path: '/',
    element: (
        <NavMotion>
            <GuestGuard>
                <MinimalLayout />
            </GuestGuard>
        </NavMotion>
    ),
    children: [
        {
            path: 'login',
            element: <AuthLogin />
        },
        {
            path: 'register',
            element: <AuthRegister />
        },
        {
            path: 'registration',
            element: <AuthRegistration />
        },
        {
            path: 'successful-registration',
            element: <ResendMail />
        },
        {
            path: 'email/verify/:id/:hash',
            element: <VerifyEmail />
        },
        {
            path: 'logout',
            element: <LogoutScreen />
        },
        {
            path: 'forgot',
            element: <AuthForgotPassword />
        },
        {
            path: 'forgot-password',
            element: <ForgetPassword />
        },
        {
            path: 'reset-password/:token/:email',
            element: <ResetPassword />
        },

        {
            path: 'api/login/google/callback',
            element: <GoogleLoginHandler />
        },
        {
            path: 'api/login/facebook/callback',
            element: <FacebookLoginHandler />
        },
        {
            path: 'sso',
            element: <SsoHandler />
        }
    ]
};

export default LoginRoutes;
