import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { FormControl, FormControlLabel, InputLabel, MenuItem, Select, Switch } from '@mui/material';
import { get, isUndefined } from 'lodash';
import Emitter from 'utils/emitter';

const DrawerDropdown = ({ formik, mainValue, mainValueSetter, name, label, dropdowns }) => {
    const valueSetter = (formik) => {
        if (formik && formik.values && isUndefined(get(formik.values, name))) {
            // add
            formik.setFieldValue(name, mainValue);
        } else if (!isUndefined(get(formik.values, name))) {
            // edit
            mainValueSetter(get(formik.values, name));
        }
    };

    // Emitter.on('FORMIK_RENDERED', (formik) => valueSetter(formik));

    const handleEventChange = (event) => {
        console.log('formik.values: ', formik.values);
        formik.setFieldValue(name, event.target.value);
        mainValueSetter(event.target.value);
    };

    useEffect(() => {
        console.log('formik.values: ', formik.values);
    }, [formik.values]);

    return (
        <FormControl fullWidth>
            <InputLabel id="simple-select-label">{label}</InputLabel>
            <Select
                labelId="simple-select-label"
                id="simple-select"
                name={name}
                value={formik?.values?.meta?.category ?? ''}
                displayEmpty
                label={label}
                onChange={handleEventChange}
                // onChange={formik.handleChange}
            >
                {dropdowns?.map((choice, index) => (
                    <MenuItem value={choice} key={index}>
                        {choice}
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    );
};
DrawerDropdown.propTypes = {
    formik: PropTypes.object,
    mainValue: PropTypes.string,
    mainValueSetter: PropTypes.func,
    name: PropTypes.string,
    label: PropTypes.string,
    dropdowns: PropTypes.any
};
export default DrawerDropdown;
