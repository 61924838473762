import useOnePageSurveyStore from 'zustand_store/useOnePageSurveyStore';
import { v4 as uuidv4 } from 'uuid';
import QuestionService from 'services/question.service';
import { useParams } from 'react-router-dom';

const useQuestionController = () => {
    const SET_SELECTED_QUESTION_IDENTIFIER = useOnePageSurveyStore((state) => state.SET_SELECTED_QUESTION_IDENTIFIER);
    const ADD_QUESTION = useOnePageSurveyStore((state) => state.ADD_QUESTION);
    const questions = useOnePageSurveyStore((state) => state.questions);
    const currentQuestion = useOnePageSurveyStore((state) => state.currentQuestion);
    const questionTypes = useOnePageSurveyStore((state) => state.questionTypes);
    const SET_CURRENT_QUESTION_ID = useOnePageSurveyStore((state) => state.SET_CURRENT_QUESTION_ID);
    const MUTATE_QUESTIONS_ORDER = useOnePageSurveyStore((state) => state.MUTATE_QUESTIONS_ORDER);

    const HandleQuestionAdd = async (identifier, surveyToken) => {
        SET_SELECTED_QUESTION_IDENTIFIER(identifier);

        const selectedIdentifierId = questionTypes.find((question) => question.identifier === identifier)?.id;
        const newQuestionId = uuidv4();

        // find the id of current Question - currentQuestion?.id
        // Change the order of undeneath questions

        const orderChangeQuestions = questions
            ?.filter((question) => question?.order > currentQuestion.order)
            ?.map((question) => ({ id: question?.id, order: question?.order + 1 }));

        // Mass Order Update
        // const { data: response } = await QuestionService.massOrderUpdate(surveyToken, { questions: orderChangeQuestions });

        MUTATE_QUESTIONS_ORDER(orderChangeQuestions);

        // find the Order of current Question
        // Insert Question After That

        ADD_QUESTION({
            id: newQuestionId,
            question_type_id: selectedIdentifierId,
            question_title: '',
            required: true,
            is_active: true,
            order: currentQuestion.order + 1,
            identifier,
            question_type: { identifier },
            meta: {
                is_active: true,
                welcome_screen: false,
                required: true,
                button_text: '',
                allow_multiple: false,
                required_column: 0,
                answer_variable: null,
                max_characters: false
            }
        });
        SET_CURRENT_QUESTION_ID(newQuestionId);
    };

    return {
        HandleQuestionAdd
    };
};

export default useQuestionController;
