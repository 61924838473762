import create from 'zustand';
import { devtools } from 'zustand/middleware';

const useStore = create(
    devtools((set) => ({
        snackbarOpen: false,
        selectedLibraryImage: {},
        mediaLibraryOpen: false,
        userEditDrawerOpen: false,
        reloadGrid: false
    }))
);

export default useStore;
