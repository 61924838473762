import React from 'react';
import PropTypes from 'prop-types';
import { FormControlLabel, Switch } from '@mui/material';
import { get, isUndefined } from 'lodash';
import Emitter from 'utils/emitter';

const DrawerRadio = ({ formik, mainValue, mainValueSetter, name, label, disabled = false }) => {
    const valueSetter = (formik) => {
        if (formik && formik.values && isUndefined(get(formik.values, name))) {
            // add
            formik.setFieldValue(name, mainValue);
        } else if (!isUndefined(get(formik.values, name))) {
            // edit
            mainValueSetter(get(formik.values, name));
        }
    };

    Emitter.on('FORMIK_RENDERED', (formik) => valueSetter(formik));

    const handleChange = (event) => {
        formik.setFieldValue(name, event.target.checked);
        mainValueSetter(event.target.checked);
    };

    return (
        <FormControlLabel
            control={<Switch size="small" color="secondary" checked={mainValue} onChange={handleChange} disabled={disabled} />}
            label={label}
        />
    );
};
DrawerRadio.propTypes = {
    formik: PropTypes.object,
    mainValue: PropTypes.bool,
    mainValueSetter: PropTypes.func,
    name: PropTypes.string,
    label: PropTypes.string,
    disabled: PropTypes.bool
};
export default DrawerRadio;
