/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useEffect, useRef, useState } from 'react';
import { makeStyles } from '@mui/styles';
import { gridSpacing } from 'store/constant';
import PropTypes from 'prop-types';
import { animated, useSpring } from '@react-spring/web';
import SaveSharpIcon from '@mui/icons-material/SaveSharp';
import Add from '@mui/icons-material/Add';
// material-ui
import {
    Button,
    Checkbox,
    Chip,
    FormControl,
    FormControlLabel,
    Grid,
    InputLabel,
    ListSubheader,
    MenuItem,
    OutlinedInput,
    Select,
    Stack,
    Switch,
    TextField,
    Typography
} from '@mui/material';
// project imports
import SurveyService from 'services/survey.service';
import { DateTimePicker, LoadingButton, LocalizationProvider } from '@mui/lab';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
// third-party
import { useFormik } from 'formik';
import * as yup from 'yup';
import { concat, isNull, cloneDeep, initial } from 'lodash';
import { useSnackbar } from 'notistack';
import { Box } from '@mui/system';
import RichTextEditor from 'views/rich-text-editor/ReactQuill';
import useAuth from 'hooks/useAuth';
import { IconUpload } from '@tabler/icons';
import { response } from 'msw';
import GeneralService from 'services/general.service';
import Loader from 'views/components/Loader';
import { Upload } from '@mui/icons-material';
import dayjs from 'dayjs';
import MiscService from 'services/misc.service';

// ==============================|| Style ||============================== //
const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%'
    },
    button: {
        marginTop: theme.spacing(1),
        marginRight: theme.spacing(1)
    },
    actionsContainer: {
        marginBottom: theme.spacing(2)
    },
    resetContainer: {
        padding: theme.spacing(3)
    }
}));

// ==============================|| Main Template ||============================== //
const Form = ({
    initialData,
    surveyAction,
    activeUser = 0,
    activeUsersId = 0,
    activeUserOneMonthAgo = 0,
    activeUserTwoWeekAgo = 0,
    mode,
    isSaveButtonLoading
}) => {
    const classes = useStyles();
    const { user } = useAuth();
    const [minQouta, setMinQouta] = React.useState(
        typeof initialData?.valid_answers_count === 'undefined' || initialData?.valid_answers_count === 0
            ? 1
            : initialData?.valid_answers_count
    );

    const [selectedTimezone, setSelectedTimezone] = React.useState(
        initialData?.meta?.timezone ?? Intl.DateTimeFormat().resolvedOptions().timeZone
    );

    // Formik - form
    const validationSchema = yup.object({
        survey_title: yup.string().required('Survey Name is required'),
        quota: yup
            .number('Quota must be a Number')
            .required('Quota is required')
            // .min(minQouta, `Must be greater than ${minQouta - 1}`)
            .min(minQouta)
            .max(
                user?.meta?.response_remained,
                `You have ${user?.meta?.response_remained ?? 0} response(s) left. ${
                    user?.meta?.response_remained ? 'Please upgrade your plan. ' : ''
                }`
            )
            .typeError('Quota must be a Number'),
        survey_type: yup.string().required('Survey Type is required'),
        survey_status: yup.string().required('Survey Status is required'),
        start_date: yup.date().when([], {
            is: () => mode === 'add',
            then: yup
                .date()
                .transform((v) => (v instanceof Date && !Number.isNaN(v) ? v : null))
                .required('Start Date field is Required')
                .typeError('Start Date field is Required and must be in date format')
                .min(
                    new Date(
                        dayjs(new Date().toLocaleString('en-US', { timeZone: selectedTimezone }))
                            .tz(selectedTimezone)
                            .valueOf()
                    ),
                    'Start date cannot precede the current time.'
                ),
            otherwise: yup.date().notRequired()
        }),
        end_date: yup
            .date()
            .transform((v) => (v instanceof Date && !Number.isNaN(v) ? v : null))
            .required('End Date field is Required')
            .when('start_date', (startDate, schema) => {
                if (startDate) {
                    const endDate = new Date(startDate).getTime();

                    return schema.min(new Date(endDate), 'End date has to be after than start date');
                }

                return schema;
            })
            .typeError('End Date field is Required and must be in date format')
    });
    const [surveyType, setSurveyType] = React.useState({});
    // Initial Survey Statuses (Live status will be added on only edit mode)
    const [surveyStatus, setSurveyStatus] = React.useState(['Development', 'Staging']);
    const [coreRestrictions, setCoreRestrictions] = React.useState(['age', 'gender', 'country']);
    const [survey, setSurvey] = React.useState(initialData);
    const [timezones, setTimezones] = React.useState([]);
    // The Restrictions modified value
    const [restrictionValues, setRestrictionValues] = React.useState([]);
    // The Restrictions that are selected
    const [selectedRestrictions, setSelectedRestrictions] = React.useState([]);
    const [availableActiveUser, setAvailableActiveUser] = React.useState(activeUser);
    const [availableActiveUsersId, setAvailableActiveUsersId] = React.useState(activeUsersId);
    const [availableActiveUserOneMonthAgo, setAvailableActiveUserOneMonthAgo] = React.useState(activeUserOneMonthAgo);
    const [availableActiveUserTwoWeekAgo, setAvailableActiveUserTwoWeekAgo] = React.useState(activeUserTwoWeekAgo);
    const [endOpen, setEndOpen] = React.useState(false);
    const [mainCoreRestrictions, setMainCoreRestrictions] = useState([
        {
            key: 'age',
            values: [],
            label: 'Age'
        },
        {
            key: 'gender',
            values: [{ choice: 'Male' }, { choice: 'Female' }],
            label: 'Gender'
        },
        {
            key: 'country',
            values: [],
            label: 'Country'
        }
    ]);

    const formik = useFormik({
        validationSchema,
        initialValues: survey,
        enableReinitialize: true,
        onSubmit: async (values) => {
            // eslint-disable-next-line no-use-before-define
            if (selectedRestrictions.length === 0) {
                values.meta = {
                    ...values.meta,
                    restrictions: selectedRestrictions,
                    // eslint-disable-next-line no-use-before-define
                    allRestrictionValues
                };
            } else {
                values.meta = {
                    ...values.meta,
                    restrictions: selectedRestrictions,
                    // eslint-disable-next-line no-use-before-define
                    allRestrictionValues,
                    availableActiveUsersId
                };
            }

            surveyAction(values);
        }
    });

    const [style, set] = useSpring(() => ({
        color: '#000000'
    }));

    const handleSplash = () => {
        set({
            color: '#FF0000',
            letterSpacing: 4,
            onRest: () => {
                set({
                    letterSpacing: 0,
                    color: '#000000'
                });
            }
        });
    };

    const [addRestriction, setAddRestriction] = React.useState(false);
    // Main Restrictions by merging profile survey and key
    const [allRestrictionValues, setAllRestrictionValues] = React.useState(formik.values?.meta?.allRestrictionValues ?? []);
    const [restrictionValuesChecker, setRestrictionValuesChecker] = React.useState([]);

    const [restrictions, setRestrictions] = React.useState(formik.values?.meta?.restrictions ?? []);
    // EAV and Core Resriction Information
    const [restrictionsInformation, setRestrictionsInformation] = React.useState([]);
    // MobileDatepicker open state
    const [isOpen, setIsOpen] = React.useState(false);
    const [loaderOpen, setLoaderOpen] = React.useState(false);
    const [templatePreviewImage, setTemplatePreviewImage] = React.useState('');
    const [checkedRestrictionKeys, setCheckedRestrictionKeys] = React.useState([]);

    const selectedRestrictionRef = useRef();
    selectedRestrictionRef.current = allRestrictionValues;
    const { enqueueSnackbar } = useSnackbar();

    const fetchData = async () => {
        const { data } = await SurveyService.getSurveyType();
        setSurveyType(data);
    };

    const fetchRestrictionData = async (initialData) => {
        if (initialData.meta?.restrictions) {
            setAddRestriction(initialData?.meta?.allRestrictionValues.length > 0);
            setSelectedRestrictions(
                initialData.meta.restrictions.map((restriction) => ({
                    id: restriction.id,
                    name: restriction.restrictionType,
                    ...restriction
                }))
            );
            setRestrictionValues(initialData.meta.restrictions);
        }
    };

    const getRestrictionValues = async () => {
        // Set Restriction Information
        const restrictionData = await SurveyService.getRestrictions();
        const restrictionsInformation = concat(restrictionData.data.core_restrictions, restrictionData.data.eav_restrictions);
        setRestrictionsInformation(restrictionsInformation);

        // Main Restrictions by merging profile question choices and key
        const { data: coreRestricitonData } = await SurveyService.getCoreRestrictions();
        setRestrictions(coreRestricitonData);
        setMainCoreRestrictions(coreRestricitonData);
    };

    const handleAddRestriction = () => {
        setAddRestriction(true);
    };

    const handleUploadClick = (event) => {
        const file = event.target.files[0];
        const reader = new FileReader();
        if (file.size / 1024 / 1024 > 5) {
            enqueueSnackbar('Image must be less than 5MB !', { variant: 'error', autoHideDuration: 3000 });
        } else {
            setLoaderOpen(true);
            reader.onloadend = async () => {
                const fd = new FormData();
                fd.append('image', file);

                const { data: imageLink } = await GeneralService.storeImage(fd, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                });
                formik.setFieldValue('meta.template_cover_image', `${process.env.REACT_APP_BACKEND_BASE}/storage/${imageLink}`);
                setLoaderOpen(false);
            };

            reader.readAsDataURL(file);
        }
    };

    const getTimezones = async () => {
        const { data } = await MiscService.getTimezones();
        setTimezones(data.timezones);
    };

    const handleRestrictedUserData = async (currentRestricitonState) => {
        const { data: respondentData } = await SurveyService.filterUserRestrictions(currentRestricitonState);
        setAvailableActiveUser(respondentData?.active_respondent);
        setAvailableActiveUsersId(respondentData?.active_respondents_id);
        setAvailableActiveUserOneMonthAgo(respondentData?.prev_one_month_respondent);
        setAvailableActiveUserTwoWeekAgo(respondentData?.prev_two_week_respondent);
        handleSplash();
    };

    React.useEffect(() => {
        if (isNull(initialData.meta)) {
            // TODO : keep this in some helper constants
            initialData.meta = {
                save_as_template: false,
                public_survey: true,
                show_in_listing: true,
                anon_survey: false
            };
        }
        setSurvey(initialData);
    }, [initialData]); // eslint-disable-line react-hooks/exhaustive-deps

    React.useEffect(() => {
        fetchRestrictionData(initialData);
    }, [initialData.meta]); // eslint-disable-line react-hooks/exhaustive-deps

    React.useEffect(() => {
        fetchData();
        getRestrictionValues();
        getTimezones();
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    React.useEffect(() => {
        if (allRestrictionValues?.length > 0 && restrictions?.length > 0) {
            let currentRestricitonState = cloneDeep(allRestrictionValues);

            currentRestricitonState = currentRestricitonState.map((restrictionValue) => {
                restrictionValue.values = restrictionValue.values.map((val) => val?.id ?? val?.choice ?? val);
                return restrictionValue;
            });
            handleRestrictedUserData(currentRestricitonState);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [restrictions]);

    const handleNestedAccordionCheckbox = async (key, selectedValue) => {
        let currentRestricitonState = '';

        setAllRestrictionValues((prevValue) => {
            const mutableIndex = prevValue.findIndex((d) => d.key === key);

            if (mutableIndex >= 0) {
                // if there is duplicate objects
                const foundDuplicateChoice = selectedValue.find((nnn, index) =>
                    selectedValue.find((x, ind) => x.choice === nnn.choice && index !== ind)
                );
                if (foundDuplicateChoice) {
                    selectedValue = selectedValue.filter((selected) => selected.choice !== foundDuplicateChoice.choice);
                }

                // if (prevValue[mutableIndex].values.find((val) => val.choice === selectedValue.choice)) {
                prevValue[mutableIndex].values = selectedValue;
                // } else {
                // prevValue[mutableIndex].values = [...prevValue[mutableIndex].values, selectedValue];
                // }
            } else {
                // eslint-disable-next-line no-lonely-if
                if (prevValue?.length > 0) {
                    prevValue.push({ key, values: selectedValue });
                } else {
                    prevValue = [{ key, values: selectedValue }];
                }
            }
            currentRestricitonState = cloneDeep(prevValue);

            return prevValue;
        });

        // WIP: RAFID2
        // const { data: respondentData } = await SurveyService.filterUserRestrictions(selectedRestrictionRef.current);
        currentRestricitonState = currentRestricitonState.map((restrictionValue) => {
            restrictionValue.values = restrictionValue.values.map((val) => val?.id ?? val?.choice ?? val);
            return restrictionValue;
        });
        handleRestrictedUserData(currentRestricitonState);
    };

    const testChange = (event, values) => {
        const key = event.target.name;
        handleNestedAccordionCheckbox(key, event.target.value);
    };
    const [expanded, setExpanded] = React.useState(false);

    React.useEffect(() => {
        if (mode === 'add') {
            const timezoneCurrentDateTime = new Date(
                dayjs(new Date().toLocaleString('en-US', { timeZone: selectedTimezone }))
                    .add(2, 'minute')
                    .tz(selectedTimezone)
                    .valueOf()
            );
            formik.setFieldValue('start_date', timezoneCurrentDateTime);
        }

        formik.setFieldValue('meta.timezone', selectedTimezone);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedTimezone]);

    // Additional Changes for Edit Mode
    React.useEffect(() => {
        if (mode === 'edit') {
            setSurveyStatus((prevValue) => [...prevValue, 'Live']);
        }
    }, [mode]);

    return (
        <form className={classes.root} autoComplete="off" onSubmit={formik.handleSubmit}>
            <Grid container spacing={gridSpacing}>
                <Grid item xs={12} md={12}>
                    <TextField
                        fullWidth
                        placeholder="Name of your survey"
                        InputLabelProps={{ shrink: true }}
                        label="Survey Name"
                        value={formik.values.survey_title}
                        onChange={formik.handleChange}
                        name="survey_title"
                        error={formik.touched.survey_title && Boolean(formik.errors.survey_title)}
                        helperText={formik.touched.survey_title && formik.errors.survey_title}
                    />
                </Grid>
                <Grid item xs={12} md={12}>
                    <TextField
                        multiline
                        rows={3}
                        fullWidth
                        InputLabelProps={{ shrink: true }}
                        placeholder="Description of your survey"
                        label="Description"
                        value={formik.values.survey_description ? formik.values.survey_description : ''}
                        onChange={formik.handleChange}
                        name="survey_description"
                        error={formik.touched.survey_description && Boolean(formik.errors.survey_description)}
                        helperText={formik.touched.survey_description && formik.errors.survey_description}
                    />
                </Grid>
                <Grid item xs={12} md={4}>
                    <TextField
                        label="Select Timezone"
                        name="meta.timezone"
                        onChange={(event) => {
                            setSelectedTimezone(event.target.value);
                        }}
                        value={formik.values?.meta?.timezone}
                        error={formik.touched?.meta?.timezone && Boolean(formik.errors?.meta?.timezone)}
                        helperText={formik.touched?.meta?.timezone && formik.errors?.meta?.timezone}
                        fullWidth
                        select
                        focused={mode === 'edit'}
                        // disabled={mode === 'edit'}
                    >
                        {timezones.map((timezone) => (
                            <MenuItem key={timezone} value={timezone}>
                                {timezone}
                            </MenuItem>
                        ))}
                    </TextField>
                </Grid>
                <Grid item xs={12} md={4}>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <Stack spacing={3}>
                            <DateTimePicker
                                open={isOpen}
                                onOpen={() => setIsOpen(true)}
                                onClose={() => setIsOpen(false)}
                                type="text"
                                renderInput={(props) => (
                                    <TextField
                                        name="start_date"
                                        type="text"
                                        fullWidth
                                        onClick={() => setIsOpen(true)}
                                        {...props}
                                        error={formik.touched.start_date && Boolean(formik.errors.start_date)}
                                        helperText={formik.touched.start_date && formik.errors.start_date}
                                    />
                                )}
                                label="Start Date"
                                value={formik.values.start_date}
                                onChange={(value) => {
                                    formik.setFieldValue('start_date', value);
                                }}
                                // disabled={mode === 'edit'}
                            />
                        </Stack>
                    </LocalizationProvider>
                </Grid>
                <Grid item xs={12} md={4}>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <Stack spacing={3}>
                            <DateTimePicker
                                open={endOpen}
                                onOpen={() => setEndOpen(true)}
                                onClose={() => setEndOpen(false)}
                                type="text"
                                renderInput={(props) => (
                                    <TextField
                                        name="end_date"
                                        type="text"
                                        fullWidth
                                        onClick={() => setEndOpen(true)}
                                        {...props}
                                        error={formik.touched.end_date && Boolean(formik.errors.end_date)}
                                        helperText={formik.touched.end_date && formik.errors.end_date}
                                    />
                                )}
                                label="End Date"
                                value={formik.values.end_date}
                                onChange={(value) => {
                                    formik.setFieldValue('end_date', value);
                                }}
                            />
                        </Stack>
                    </LocalizationProvider>
                </Grid>
                <Grid item xs={12} md={3}>
                    <TextField
                        fullWidth
                        label="Quota"
                        type="number"
                        inputProps={{ min: '0' }}
                        value={formik.values.quota}
                        onChange={formik.handleChange}
                        name="quota"
                        error={formik.touched.quota && Boolean(formik.errors.quota)}
                        helperText={formik.touched.quota && formik.errors.quota}
                        disabled={mode === 'edit' && survey.survey_prize > 0}
                    />
                </Grid>
                <Grid item xs={12} md={3}>
                    <TextField
                        id="outlined-select-experience"
                        select
                        fullWidth
                        label="Survey Type"
                        value={formik.values.survey_type}
                        onChange={formik.handleChange}
                        name="survey_type"
                        error={formik.touched.survey_type && Boolean(formik.errors.survey_type)}
                        helperText={formik.touched.survey_type && formik.errors.survey_type}
                    >
                        <ListSubheader>Survey Type</ListSubheader>
                        {Object.values(surveyType).map((value, index) => (
                            <MenuItem key={index} value={value}>
                                {value}
                            </MenuItem>
                        ))}
                    </TextField>
                </Grid>
                <Grid item xs={12} md={3}>
                    <TextField
                        id="outlined-select-experience"
                        select
                        fullWidth
                        label="Survey Status"
                        value={formik.values.survey_status}
                        defaultValue="Development"
                        onChange={formik.handleChange}
                        name="survey_status"
                        error={formik.touched.survey_status && Boolean(formik.errors.survey_status)}
                        helperText={formik.touched.survey_status && formik.errors.survey_status}
                    >
                        <ListSubheader> Survey Status</ListSubheader>
                        {surveyStatus.map((value, index) => (
                            <MenuItem key={index} value={value}>
                                {value}
                            </MenuItem>
                        ))}
                    </TextField>
                </Grid>
                <Grid item xs={12} md={3}>
                    <TextField
                        fullWidth
                        label="Survey Prize"
                        type="number"
                        inputProps={{ min: '0' }}
                        value={formik.values.survey_prize}
                        onChange={formik.handleChange}
                        name="survey_prize"
                        error={formik.touched.survey_prize && Boolean(formik.errors.survey_prize)}
                        helperText={formik.touched.survey_prize && formik.errors.survey_prize}
                    />
                </Grid>
                <Grid item xs={12} md={12}>
                    <label htmlFor="editor">Thank you Message:</label>

                    <RichTextEditor name="meta.thankyou_text" formik={formik} value={formik.values?.meta?.thankyou_text} />
                </Grid>
                <Grid item xs={12} md={12}>
                    <TextField
                        fullWidth
                        label="Redirect url (when completed)"
                        type="text"
                        value={formik.values.meta.redirection_url}
                        onChange={formik.handleChange}
                        name="meta.redirection_url"
                        error={formik.touched.redirection_url && Boolean(formik.errors.redirection_url)}
                        helperText={formik.touched.redirection_url && formik.errors.redirection_url}
                    />
                </Grid>
                {/* {user?.user_role.includes('Super Admin') && ( */}
                <Grid item xs={12} md={12}>
                    <Grid
                        style={{
                            alignItem: 'center',
                            height: '75%',
                            width: '100%'
                        }}
                    >
                        <Loader loaderOpen={loaderOpen} />
                        <Button startIcon={<Upload />} variant="outlined" component="label">
                            Upload a cover image for Survey (This will be your Image preview in Social Media)
                            <input accept="image/*" id="upload" type="file" onChange={(event) => handleUploadClick(event)} hidden />
                        </Button>

                        {formik?.values?.meta?.template_cover_image && (
                            <Grid
                                style={{
                                    margin: '20px',
                                    height: '200px',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    overflow: 'hidden'
                                }}
                            >
                                <img
                                    src={formik?.values?.meta?.template_cover_image}
                                    style={{
                                        display: 'block',
                                        maxWidth: '100%',
                                        maxHeight: '100%',
                                        minHeight: '1px'
                                    }}
                                    alt="images"
                                />
                            </Grid>
                        )}
                    </Grid>
                </Grid>
                <Grid item xs={12} md={12}>
                    {!addRestriction ? (
                        <Button sx={{ textTransform: 'none' }} startIcon={<Add />} variant="outlined" onClick={handleAddRestriction}>
                            Add Restriction(s)
                        </Button>
                    ) : (
                        <>
                            <Typography variant="h3" component="h3" gutterBottom xs={{ marginBottom: 2 }}>
                                <animated.div
                                    style={{
                                        ...style
                                    }}
                                >
                                    Members:
                                </animated.div>
                            </Typography>

                            <Grid container alignItems="stretch" justifyContent="space-between" spacing={1}>
                                <Grid item xs={12}>
                                    <Typography variant="h5" component="h5" xs={{ marginBottom: '20px' }}>
                                        Total Active Respondents: {availableActiveUser || 0}
                                    </Typography>
                                    <Typography variant="h5" component="h5" xs={{ marginBottom: '20px' }}>
                                        Total Active Within 1 Month: {availableActiveUserOneMonthAgo || 0}
                                    </Typography>

                                    <Typography variant="h5" component="h5" xs={{ marginBottom: '20px' }}>
                                        Total Active Within 2 weeks: {availableActiveUserTwoWeekAgo || 0}
                                    </Typography>
                                </Grid>
                            </Grid>

                            <Typography variant="h3" component="h3" gutterBottom mt={2}>
                                Restrictions:
                            </Typography>

                            <Grid container alignItems="stretch" justifyContent="space-between" spacing={1}>
                                <Grid item xs={12}>
                                    {restrictions.length > 0 &&
                                        restrictions?.map(
                                            (value, index) =>
                                                coreRestrictions.includes(value.key) && (
                                                    <FormControl sx={{ m: 1, width: 300 }} key={index}>
                                                        <InputLabel id="demo-multiple-chip-label">{value.label}</InputLabel>
                                                        {selectedRestrictionRef.current?.length > 0 && (
                                                            <Select
                                                                multiple
                                                                defaultValue={
                                                                    selectedRestrictionRef.current.find((val) => val.key === value.key)
                                                                        ?.values ?? []
                                                                }
                                                                value={
                                                                    selectedRestrictionRef.current.find((val) => val.key === value.key)
                                                                        ?.values ?? []
                                                                }
                                                                onChange={(event) => testChange(event, value.values)}
                                                                name={value.key}
                                                                input={<OutlinedInput id="select-multiple-chip" label="Chip" />}
                                                                renderValue={(selected) => (
                                                                    <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                                                        {selected.map((value) => (
                                                                            <Chip key={value?.id ?? value?.choice} label={value.choice} />
                                                                        ))}
                                                                    </Box>
                                                                )}
                                                                // MenuProps={MenuProps}
                                                            >
                                                                {value?.values?.map((choice, i) => (
                                                                    <MenuItem key={i} value={choice}>
                                                                        {choice.choice}
                                                                    </MenuItem>
                                                                ))}
                                                            </Select>
                                                        )}

                                                        {selectedRestrictionRef.current?.length === 0 && (
                                                            <Select
                                                                labelId="demo-multiple-chip-label"
                                                                id="demo-multiple-chip"
                                                                multiple
                                                                defaultValue={
                                                                    selectedRestrictionRef.current.find((val) => val.key === value.key)
                                                                        ?.values ?? []
                                                                }
                                                                value={
                                                                    selectedRestrictionRef.current.find((val) => val.key === value.key)
                                                                        ?.values ?? []
                                                                }
                                                                onChange={(event) => testChange(event, value.values)}
                                                                name={value.key}
                                                                input={<OutlinedInput id="select-multiple-chip" label="Chip" />}
                                                                renderValue={(selected) => (
                                                                    <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                                                        {selected.map((value) => (
                                                                            <Chip key={value?.id ?? value?.choice} label={value.choice} />
                                                                        ))}
                                                                    </Box>
                                                                )}
                                                                // MenuProps={MenuProps}
                                                            >
                                                                {value?.values?.map((choice, i) => (
                                                                    <MenuItem key={i} value={choice}>
                                                                        {choice.choice}
                                                                    </MenuItem>
                                                                ))}
                                                            </Select>
                                                        )}
                                                    </FormControl>
                                                )
                                        )}
                                </Grid>
                            </Grid>
                        </>
                    )}
                </Grid>
            </Grid>

            <Grid item xs={12}>
                <Grid container mt={3}>
                    <Grid item>
                        <FormControlLabel
                            control={
                                <Switch
                                    checked={Boolean(formik.values.show_in_listing)}
                                    value={formik.values.show_in_listing}
                                    onChange={(event, value) => {
                                        const newValue = value ? 1 : 0;
                                        formik.setFieldValue('show_in_listing', newValue);
                                    }}
                                    name="show_in_listing"
                                />
                            }
                            label="Show in listing"
                        />
                    </Grid>
                    {user?.user_role.includes('Super Admin') && (
                        <Grid item>
                            <FormControlLabel
                                control={
                                    <Switch
                                        checked={formik.values.meta.save_as_template}
                                        value={formik.values.meta.save_as_template}
                                        onChange={formik.handleChange}
                                        name="meta.save_as_template"
                                    />
                                }
                                label="Save as template"
                            />
                        </Grid>
                    )}
                    <Grid item>
                        <FormControlLabel
                            control={
                                <Switch
                                    checked={formik.values.meta.public_survey}
                                    value={formik.values.meta.public_survey}
                                    onChange={(event) => {
                                        formik.setFieldValue('meta.anon_survey', !event.currentTarget.checked);
                                        formik.setFieldValue('meta.public_survey', event.currentTarget.checked);
                                    }}
                                    name="meta.public_survey"
                                />
                            }
                            label="Public Survey"
                        />
                    </Grid>
                    <Grid item>
                        <FormControlLabel
                            control={
                                <Switch
                                    checked={formik.values.meta.anon_survey}
                                    value={formik.values.meta.anon_survey}
                                    onChange={(event) => {
                                        formik.setFieldValue('meta.public_survey', !event.currentTarget.checked);
                                        formik.setFieldValue('meta.anon_survey', event.currentTarget.checked);
                                    }}
                                    name="meta.anon_survey"
                                />
                            }
                            label="Anonymous Survey"
                        />
                    </Grid>
                </Grid>

                {user?.meta?.logo && user?.meta?.logo !== '' && (
                    <Grid item xs={12} md={12} mt={3}>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={Boolean(formik.values?.meta?.logo)}
                                    value={formik.values?.meta?.logo}
                                    onChange={formik.handleChange}
                                    name="meta.logo"
                                />
                            }
                            label="Include Logo"
                        />
                    </Grid>
                )}

                <Grid item xs={12} mt={3}>
                    <LoadingButton
                        startIcon={<SaveSharpIcon />}
                        variant="contained"
                        type="submit"
                        size="medium"
                        disabled={mode === 'edit' && survey.survey_prize > 0 && !survey?.meta?.prized_survey_paid}
                        loading={isSaveButtonLoading}
                        loadingPosition="start"
                    >
                        Save
                    </LoadingButton>
                </Grid>
            </Grid>
        </form>
    );
};
Form.propTypes = {
    initialData: PropTypes.object,
    surveyAction: PropTypes.func,
    activeUser: PropTypes.number,
    activeUsersId: PropTypes.number,
    activeUserOneMonthAgo: PropTypes.number,
    activeUserTwoWeekAgo: PropTypes.number,
    mode: PropTypes.string,
    isSaveButtonLoading: PropTypes.bool
};
export default Form;
