import React, { useEffect, useState } from 'react';
import { Card, CardContent, CardActions, IconButton, Typography, Grid, Button, CardMedia, Box, Radio } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import VisaImage from 'assets/images/icons/visa.png';
import MastercardImage from 'assets/images/icons/mastercard.png';
import { CardElement, Elements, PaymentElement, useElements, useStripe } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import AddCardForm from './AddCardForm';
import PaymentService from 'services/payment.service';
import { LoadingButton } from '@mui/lab';
import dayjs from 'dayjs';
import { useConfirm } from 'material-ui-confirm';
import { useSnackbar } from 'notistack';

const PaymentMethods = () => {
    const confirm = useConfirm();

    const [cards, setCards] = useState([]);
    const [cardAddingState, setCardAddingState] = useState(false);
    const [addingButtonLoadingState, setAddingButtonLoadingState] = useState(false);

    const [stripePromise, setStripePromise] = useState(null);
    const [clientSecret, setClientSecret] = useState(null);
    const [defaultPayment, setDefaultPayment] = useState(false);
    const { enqueueSnackbar } = useSnackbar();

    const options = {
        clientSecret
        // appearance
    };

    const fetchPaymentIntent = async () => {
        const { data } = await PaymentService.createPaymentIntentForUser();
        setStripePromise(loadStripe(data.stripe_key));
        setClientSecret(data.intent.client_secret);
    };

    const handleAddingCard = () => {
        setCardAddingState((prevValue) => !prevValue);
        fetchPaymentIntent();
    };

    const fetchPaymentCards = async () => {
        const { data } = await PaymentService.getPaymentCards();
        setCards(data.cards);
        setDefaultPayment(data.default_payment_method);
    };

    const handleDeleteCard = async (stripeId) => {
        confirm({ description: 'Are you sure that you want to delete this card? ' }).then(async () => {
            try {
                const { data } = await PaymentService.deletePaymentMethod(stripeId);
                enqueueSnackbar('Card deleted!', {
                    variant: 'success',
                    autoHideDuration: 3000
                });
                setCards(cards.filter((card) => card.id !== stripeId));
            } catch (error) {
                console.error('Failed to delete payment method:', error);
            }
        });
    };

    const handleDefaultCardSelection = async (stripeId) => {
        setDefaultPayment(stripeId);

        try {
            const { data } = await PaymentService.setDefaultPaymentMethod(stripeId);

            enqueueSnackbar('Card set as default payment system!', {
                variant: 'success',
                autoHideDuration: 3000
            });
        } catch (error) {
            console.error('Failed to set default payment method:', error);
        }
    };

    useEffect(() => {
        fetchPaymentIntent();
        fetchPaymentCards();
    }, []);

    return (
        <div style={{ padding: 32, backgroundColor: 'rgba(106, 106, 137, 0.04)' }}>
            <Box display="flex" justifyContent="space-between" alignItems="center" mb={3}>
                <Typography variant="h3">Payment Methods</Typography>
                <Button variant="contained" color="primary" onClick={handleAddingCard} disabled={cardAddingState}>
                    Add Card
                </Button>
            </Box>

            {cardAddingState && clientSecret && (
                <>
                    <Elements options={options} stripe={stripePromise} style={{ marginTop: '20px' }}>
                        <AddCardForm
                            clientSecret={clientSecret}
                            setCardAddingState={setCardAddingState}
                            setAddingButtonLoadingState={setAddingButtonLoadingState}
                            setCards={setCards}
                            fetchPaymentIntent={fetchPaymentIntent}
                        />
                    </Elements>

                    <Box display="flex" justifyContent="flex-end" mt={2} gap={2}>
                        <LoadingButton
                            variant="contained"
                            color="primary"
                            type="submit"
                            form="payment-form"
                            loading={addingButtonLoadingState}
                        >
                            Save Card
                        </LoadingButton>
                        <Button variant="outlined" color="secondary" onClick={() => setCardAddingState(false)}>
                            Cancel
                        </Button>
                    </Box>
                </>
            )}

            <Grid container spacing={2} mt={2}>
                {cards?.map((card) => (
                    <Grid item xs={12} key={card.id}>
                        <Card p={1}>
                            <CardContent
                                style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: '0px 20px' }}
                            >
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    <CardMedia
                                        component="img"
                                        image={card.card.brand === 'visa' ? VisaImage : MastercardImage}
                                        title={card.card.brand}
                                        style={{ width: '80px', marginRight: '20px' }}
                                    />

                                    <Typography variant="h4">
                                        {card.card.brand} •••• {card.card.last4} <br />{' '}
                                        <small style={{ color: '#bbb' }}>
                                            Expires {dayjs(`${card.card.exp_year}-${card.card.exp_month}-01`).format('MMMM YYYY')}
                                        </small>
                                    </Typography>
                                </div>
                                <CardActions>
                                    <Radio
                                        checked={defaultPayment === card.id}
                                        onChange={() => handleDefaultCardSelection(card.id)}
                                        value={card.id}
                                        name="card-selection"
                                        inputProps={{ 'aria-label': 'Select card' }}
                                    />
                                    <IconButton aria-label="delete" onClick={() => handleDeleteCard(card.id)}>
                                        <DeleteIcon />
                                    </IconButton>
                                    {/* <IconButton aria-label="more options">
                                        <MoreVertIcon />
                                    </IconButton> */}
                                </CardActions>
                            </CardContent>
                        </Card>
                    </Grid>
                ))}
            </Grid>
        </div>
    );
};

export default PaymentMethods;
