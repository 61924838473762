import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { MenuItem, Select, Grid, TextField } from '@mui/material';

const TextChoice = ({ formik, choiceMap, index, ruleIndex, conditionIndex, selectedOption }) => {
    const [uniqueIndex, setUniqueIndex] = useState(0);
    const one = 1;
    // const formik = useFormikContext();
    // assignmentOp = Assignments Operator
    return (
        <>
            {choiceMap[formik.values.restrictions[index].allRules[ruleIndex].cond[conditionIndex]?.ques] && (
                <>
                    <Grid item xs={3}>
                        <Select
                            color="success"
                            value={formik.values.restrictions[index].allRules[ruleIndex].cond[conditionIndex].assignmentOp}
                            onChange={formik.handleChange}
                            name={`restrictions[${[index]}].allRules[${[ruleIndex]}].cond[${[conditionIndex]}].assignmentOp`}
                            fullWidth
                            size="small"
                            SelectDisplayProps={{ 'data-testid': 'conditional-operator-select' }}
                        >
                            <MenuItem value="equal"> is </MenuItem>
                            <MenuItem value="not equal"> is not </MenuItem>
                        </Select>
                    </Grid>
                    <Grid item xs={3}>
                        <Select
                            key={conditionIndex}
                            size="small"
                            value={formik.values.restrictions[index].allRules[ruleIndex].cond[conditionIndex].val}
                            onChange={formik.handleChange}
                            name={`restrictions[${[index]}].allRules[${[ruleIndex]}].cond[${[conditionIndex]}].val`}
                            fullWidth
                            SelectDisplayProps={{ 'data-testid': 'condition-question-select' }}
                        >
                            {choiceMap[formik.values.restrictions[index].allRules[ruleIndex].cond[conditionIndex].ques].map(
                                (el, uniqueKey) => (
                                    <MenuItem value={el.choice} key={uniqueKey}>
                                        {el.choice}
                                    </MenuItem>
                                )
                            )}
                        </Select>
                    </Grid>
                </>
            )}

            {!choiceMap[formik.values.restrictions[index].allRules[ruleIndex].cond[conditionIndex]?.ques] && (
                <>
                    <Grid item xs={3}>
                        <Select
                            size="small"
                            value={formik.values.restrictions[index].allRules[ruleIndex].cond[conditionIndex].assignmentOp}
                            onChange={formik.handleChange}
                            name={`restrictions[${[index]}].allRules[${[ruleIndex]}].cond[${[conditionIndex]}].assignmentOp`}
                            fullWidth
                        >
                            <MenuItem value="equal"> is </MenuItem>
                            <MenuItem value="not equal"> is not </MenuItem>
                            <MenuItem value="starts with"> starts with </MenuItem>
                            <MenuItem value="doesn not starts with"> does not start with </MenuItem>
                            <MenuItem value="contains">contains </MenuItem>
                            <MenuItem value="does not contain"> does not contain </MenuItem>
                        </Select>
                    </Grid>
                    <Grid item xs={3}>
                        <TextField
                            size="small"
                            value={formik.values.restrictions[index].allRules[ruleIndex].cond[conditionIndex].val}
                            onChange={formik.handleChange}
                            name={`restrictions[${[index]}].allRules[${[ruleIndex]}].cond[${[conditionIndex]}].val`}
                            fullWidth
                        />
                    </Grid>
                </>
            )}
        </>
    );
};

TextChoice.propTypes = {
    formik: PropTypes.object,
    choiceMap: PropTypes.object,
    index: PropTypes.number,
    ruleIndex: PropTypes.number,
    conditionIndex: PropTypes.number,
    selectedOption: PropTypes.number
};

export default TextChoice;
