// GlobalModalContext.js
import { createContext, useState } from 'react';
import PropTypes from 'prop-types';

const initialState = {
    content: '',
    title: 'Information'
};

const GlobalModalContext = createContext({
    ...initialState,
    openGlobalModal: () => {},
    closeGlobalModal: () => {}
});

export const GlobalHelpProvider = ({ children }) => {
    const [content, setContent] = useState('');
    const [title, setTitle] = useState('Information');
    const [open, setOpen] = useState(false);
    const openGlobalModal = ({ title, content }) => {
        setTitle(title);
        setContent(content);
        setOpen(true);
    };
    const closeGlobalModal = () => {
        setOpen(false);
    };
    return (
        <GlobalModalContext.Provider
            value={{
                title,
                content,
                open,
                openGlobalModal,
                closeGlobalModal
            }}
        >
            {children}
        </GlobalModalContext.Provider>
    );
};

GlobalHelpProvider.propTypes = {
    children: PropTypes.any
};

export default GlobalModalContext;
