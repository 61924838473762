import React, { useMemo } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';

// material-ui
import { styled, useTheme } from '@mui/material/styles';
import { AppBar, Box, Container, Toolbar, useMediaQuery } from '@mui/material';

// project imports
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import Header from './Header';
import Sidebar from './Sidebar';
import useConfig from 'hooks/useConfig';
import { drawerWidth } from 'store/constant';
import { openDrawer } from 'store/slices/menu';
import GlobalModal from 'ui-component/GlobalModal';
import { useDispatch, useSelector } from 'store';

// assets
import { IconMenu2 } from '@tabler/icons';
import PrimaryIconSidebar from './Sidebar/PrimaryIconSidebar';

// styles
const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(({ theme, open }) => ({
    ...theme.typography.mainContent,
    ...(!open && {
        borderBottomLeftRadius: 0,
        borderBottomRightRadius: 0,
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.shorter
        }),
        [theme.breakpoints.up('md')]: {
            marginLeft: -(drawerWidth - 100),
            width: `calc(100% - ${drawerWidth}px)`
        },
        [theme.breakpoints.down('md')]: {
            marginLeft: '80px',
            width: `calc(100% - ${drawerWidth}px)`,
            padding: '16px'
        },
        [theme.breakpoints.down('sm')]: {
            marginLeft: '80px',
            width: `calc(100% - ${drawerWidth}px)`,
            padding: '16px',
            marginRight: '10px'
        }
    }),
    ...(open && {
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.shorter
        }),
        marginLeft: 0,
        borderBottomLeftRadius: 0,
        borderBottomRightRadius: 0,
        width: `calc(100% - ${drawerWidth}px)`,
        [theme.breakpoints.down('md')]: {
            marginLeft: '20px'
        },
        [theme.breakpoints.down('sm')]: {
            marginLeft: '10px'
        }
    })
}));

// ==============================|| MAIN LAYOUT ||============================== //

const MainLayout = () => {
    const theme = useTheme();
    const matchDownMd = useMediaQuery(theme.breakpoints.down('lg'));

    const dispatch = useDispatch();
    const { drawerOpen } = useSelector((state) => state.menu);
    const { container } = useConfig();
    const navigate = useNavigate();
    const matchUpMd = useMediaQuery(theme.breakpoints.up('md'));

    React.useEffect(() => {
        dispatch(openDrawer(!matchDownMd));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [matchDownMd]);

    const header = useMemo(
        () => (
            <Toolbar
                sx={{
                    [theme.breakpoints.up('sm')]: {
                        paddingLeft: 0,
                        paddingTop: 0,
                        paddingBottom: 0
                    }
                }}
            >
                <Header />
            </Toolbar>
        ),
        [theme.breakpoints]
    );

    return (
        <Box sx={{ display: 'flex', bgcolor: '#f6f8fc' }}>
            {/* <CssBaseline /> */}
            {/* header */}
            <AppBar
                enableColorOnDark
                position="fixed"
                color="inherit"
                elevation={0}
                sx={{
                    left: '80px',
                    // borderBottom: '1px solid #eee',
                    margin: 0,
                    width: 'calc( 100% - 80px)',
                    bgcolor: '#f6f8fc',
                    transition: drawerOpen ? theme.transitions.create('width') : 'none'
                }}
            >
                {header}
            </AppBar>
            <Box
                sx={{
                    background: '#f2f4f7',
                    display: 'flex',
                    zIndex: '999',
                    position: 'fixed',
                    height: '100vh',
                    width: '70px',
                    borderRight: '1px solid #eee '
                }}
            >
                <Stack direction="column" spacing={1} sx={{ width: '100%' }}>
                    <IconButton
                        aria-label="menu"
                        sx={{
                            pt: '20px',
                            pb: '26px',

                            borderRadius: '0',
                            transition: 'all .2s ease-in-out',
                            background: 'transparent',
                            color: '#000000',
                            '&:hover': {
                                background: '#ede7f6',
                                borderRadius: '0'
                            }
                        }}
                        onClick={() => dispatch(openDrawer(!drawerOpen))}
                        // onClick={() => console.log('Clicking...')}
                    >
                        <IconMenu2 stroke={1} size="1.5rem" />
                    </IconButton>
                    <PrimaryIconSidebar />
                </Stack>
            </Box>
            {/* drawer */}
            <GlobalModal />
            <Sidebar />
            {/* main content */}
            <Main theme={theme} open={drawerOpen} sx={{ backgroundColor: '#FFFFFF' }}>
                {/* breadcrumb */}
                {container && (
                    <Container maxWidth="lg">
                        <Outlet />
                    </Container>
                )}
                {!container && <Outlet />}
            </Main>
        </Box>
    );
};

export default MainLayout;
