import React, { useState, useEffect } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import { useNavigate, useLocation } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import paymentService from 'services/payment.service';

import CheckoutForm from './CheckoutForm';

export default function Upgrade() {
    const { enqueueSnackbar } = useSnackbar();
    const [clientSecret, setClientSecret] = useState('');
    const [subscriptionId, setSubscriptionId] = useState('');
    const [stripePromise, setStripePromise] = useState('');
    const location = useLocation();
    const navigate = useNavigate();

    const getIntent = async () => {
        if (location.state === null) {
            enqueueSnackbar('Wrong url. Redirecting to Packages', { variant: 'error', autoHideDuration: 2500 });
            setTimeout(() => {
                navigate({ pathname: '/packages' });
            }, 1500);
        } else {
            setClientSecret(location.state.clientSecret);
            setSubscriptionId(location.state.subscriptionId);
        }
    };

    useEffect(() => {
        paymentService.getConfig({ key: 'stripe_key' }).then((response) => {
            setStripePromise(loadStripe(response.data.stripe_key));
            getIntent();
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const appearance = {
        theme: 'flat'
    };
    const options = {
        clientSecret,
        subscriptionId,
        appearance
    };

    return (
        <div className="App">
            {clientSecret && (
                <Elements options={options} stripe={stripePromise}>
                    <CheckoutForm props={options} />
                </Elements>
            )}
        </div>
    );
}
