import {
    Button,
    Dialog,
    CardMedia,
    DialogActions,
    DialogContent,
    Checkbox,
    DialogContentText,
    DialogTitle,
    ImageList,
    ImageListItem,
    Box,
    Tab
} from '@mui/material';

import TabList from '@mui/lab/TabList';
import useStore from 'zustand_store/useStore';
import TabContext from '@mui/lab/TabContext';
import TabPanel from '@mui/lab/TabPanel';
import React, { useState, useMemo } from 'react';
import Dropzone, { useDropzone } from 'react-dropzone';
import { useSnackbar } from 'notistack';
import GeneralService from 'services/general.service';

export default function MediaLibray() {
    const mediaLibraryOpen = useStore((state) => state.mediaLibraryOpen);
    const [value, setValue] = React.useState('1');
    const [fetchImages, setFetchImages] = React.useState(false);
    const { enqueueSnackbar } = useSnackbar();
    const [scroll, setScroll] = React.useState('paper');
    const [images, setImages] = useState([]);

    const handleTabChange = (event, newValue) => {
        setValue(newValue);
    };
    const handleChecked = (index) => {
        const updatedCheckedState = images.map((item, i) => {
            if (i === index) {
                item.checked = !item.checked;
            } else {
                item.checked = false;
            }
            return item;
        });
        setImages(updatedCheckedState);
    };

    const handleClose = (flag) => {
        if (flag !== false) {
            const selectedImage = images.filter((item) => item.checked);
            if (selectedImage?.length) {
                useStore.setState({ mediaLibraryOpen: false, selectedLibraryImage: selectedImage });
            } else {
                useStore.setState({ mediaLibraryOpen: false });
            }
        } else {
            useStore.setState({ mediaLibraryOpen: false });
        }
    };

    React.useEffect(() => {
        if (mediaLibraryOpen) {
            GeneralService.getLibraryImages({}).then((response) => {
                setImages(response.data.images);
            });
        }
        // eslint-disable-next-line
    }, [mediaLibraryOpen, fetchImages]);

    const baseStyle = {
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: '20px',
        borderWidth: 2,
        borderRadius: 5,
        borderColor: '#BBB',
        borderStyle: 'dashed',
        backgroundColor: '#fff2ca',
        color: '#666',
        outline: 'none',
        transition: 'border .24s ease-in-out'
    };

    const focusedStyle = {
        borderColor: '#2196f3'
    };

    const acceptStyle = {
        borderColor: '#00e676'
    };

    const rejectStyle = {
        borderColor: '#ff1744'
    };

    const { getRootProps, getInputProps, isFocused, isDragAccept, isDragReject } = useDropzone({
        multiple: false,
        accept: {
            'image/jpeg': ['.jpeg', '.png']
        }
    });

    const style = useMemo(
        () => ({
            ...baseStyle,
            ...(isFocused ? focusedStyle : {}),
            ...(isDragAccept ? acceptStyle : {}),
            ...(isDragReject ? rejectStyle : {})
        }),
        // eslint-disable-next-line
        [isFocused, isDragAccept, isDragReject]
    );

    const handleUpload = async (acceptedFiles, rejectedFiles) => {
        const fd = new FormData();
        acceptedFiles.forEach((file) => {
            fd.append('img', file);
        });
        const {
            data: { code, imgId, msg }
        } = await GeneralService.addMedia(fd, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });
        if (code === 'success') {
            GeneralService.getLibraryImages({ headers: { single_img: imgId } }).then((response) => {
                setImages([response.data.image, ...images]);
                enqueueSnackbar(msg, { variant: 'success' });
            });
        }
    };

    const handleAddImg = () => {
        GeneralService.getLibraryImages({ headers: { single_img: 33 } }).then((response) => {
            setImages([response.data.image, ...images]);
            // setTimeout(() => {
            // }, 200);
        });
    };

    return (
        <>
            <Dialog
                open={mediaLibraryOpen}
                maxWidth="md"
                disableEscapeKeyDown
                scroll={scroll}
                aria-labelledby="scroll-dialog-title"
                aria-describedby="scroll-dialog-description"
            >
                <DialogTitle id="scroll-dialog-title" sx={{ paddingTop: 1 }}>
                    Select Image
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="scroll-dialog-description" component="span">
                        <Box sx={{ width: '100%', typography: 'body1' }}>
                            <TabContext value={value}>
                                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                    <TabList variant="fullWidth" onChange={handleTabChange}>
                                        <Tab label="Media Library" value="1" />
                                        <Tab label="Upload" value="2" />
                                    </TabList>
                                </Box>
                                <TabPanel value="2" sx={{ minWidth: 550 }}>
                                    <Dropzone onDrop={handleUpload} maxWidth={2000} maxFiles={1} maxSize={5000000}>
                                        {({ getRootProps, getInputProps }) => (
                                            <section className="container">
                                                <div {...getRootProps({ style })}>
                                                    <input {...getInputProps()} />
                                                    <p>Drag n drop some files here, or click to select files</p>
                                                </div>
                                            </section>
                                        )}
                                    </Dropzone>
                                </TabPanel>
                                <TabPanel value="1" sx={{ minWidth: 550 }}>
                                    <ImageList sx={{ height: 450 }} cols={4} rowHeight={140} gap={10}>
                                        {images.map((item, index) => (
                                            <ImageListItem key={index}>
                                                <Checkbox
                                                    checked={item.checked}
                                                    onClick={() => handleChecked(index)}
                                                    color="success"
                                                    sx={{
                                                        position: 'absolute',
                                                        right: 0
                                                    }}
                                                />
                                                <CardMedia
                                                    sx={{ margin: 0, padding: 0 }}
                                                    component="img"
                                                    height="140"
                                                    onClick={() => handleChecked(index)}
                                                    image={`${item.thumb}?w=164&h=140&fit=crop&auto=format`}
                                                />
                                            </ImageListItem>
                                        ))}
                                    </ImageList>
                                </TabPanel>
                            </TabContext>
                        </Box>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => handleClose(true)} variant="contained">
                        Insert
                    </Button>
                    <Button onClick={() => handleClose(false)} variant="text" color="error">
                        Cancel
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
}
