import React, { Fragment } from 'react';
import { Button, Grid, MenuItem, Select, Typography } from '@mui/material';
import { useFormikContext, FieldArray, FormikProvider, FastField } from 'formik';
import MainCard from 'ui-component/cards/MainCard';
import AllCondition from './AllCondition';
import PropTypes from 'prop-types';

const RuleComponent = (props) => {
    const { allQuesData, allQtitle, choiceMap, index, survey } = props;

    const addRule = React.useCallback((push, title) => {
        push({
            // cond: ['habijabi'],
            cond: [{ ques: '', assignmentOp: '', val: '', logicalOp: '' }],
            event: { typ: 'Jump To', opt: '' }
        });
    }, []);

    const removeRule = React.useCallback((remove, index) => {
        remove(index);
    }, []);
    const formik = useFormikContext();

    const lastQuestionValidator = (formik, index, value, ruleIndex) => {
        const result =
            formik.values?.restrictions[index].length - 1 === ruleIndex &&
            allQtitle()[index] !== allQuesData?.find((v) => v.id === value.cond[0].ques)?.question_title;

        return result;
    };

    return (
        <FormikProvider value={formik}>
            <FieldArray
                subscription={{}}
                name={`restrictions[${index}].allRules`}
                render={({ push, remove }) => (
                    <MainCard key={Math.random} mt={4} style={{ border: '0', margin: '15px' }}>
                        <Typography align="left" variant="h4" mb={2}>
                            {allQtitle()[index]}
                        </Typography>

                        {formik.values?.restrictions[index]?.allRules?.map((value, ruleIndex) => (
                            <Fragment key={ruleIndex}>
                                <Grid container>
                                    <Grid item xs={12}>
                                        <Typography sx={{ borderBottom: 1, borderColor: '#ccc' }} variant="h5" pb={1} mb={3}>
                                            {`Rule ${ruleIndex + 1}`}.
                                        </Typography>
                                    </Grid>
                                </Grid>

                                <AllCondition {...props} ruleIndex={ruleIndex} />
                                <Button
                                    color="error"
                                    onClick={() => removeRule(remove, ruleIndex)}
                                    role="button"
                                    aria-label="Remove Rule Button"
                                >
                                    (—) Remove Rule
                                </Button>
                            </Fragment>
                        ))}
                        <Button
                            variant="text"
                            role="button"
                            onClick={() => {
                                addRule(push, allQtitle()[index]);
                            }}
                        >
                            (+) Add Rule
                        </Button>

                        <Grid container mt={3}>
                            <Grid item xs={2}>
                                <Typography variant="h5" pb={1} mt={1}>
                                    Always Jump To
                                </Typography>
                            </Grid>
                            <Grid item xs={10}>
                                <FastField
                                    size="small"
                                    labelId="if-condition"
                                    id="if-condition"
                                    as={Select}
                                    SelectDisplayProps={{ 'data-testid': 'always-jump-to-select-box' }}
                                    value={formik.values.restrictions[index]?.alwaysJumpTo?.qID || '-1'}
                                    defaultValue=""
                                    onChange={formik.handleChange}
                                    fullWidth
                                    name={`restrictions[${index}].alwaysJumpTo.qID`}
                                >
                                    <MenuItem value="-1">Select a Question</MenuItem>
                                    {allQuesData.slice(index + 1).map((questionData, uniqueKey) => (
                                        <MenuItem value={questionData.id} key={uniqueKey}>
                                            {questionData.question_title}
                                        </MenuItem>
                                    ))}
                                    <MenuItem value="0">End Survey</MenuItem>
                                </FastField>
                            </Grid>
                        </Grid>

                        {/* <Button
                            variant="text"
                            role="button"
                            onClick={() => {
                                addRule(push, allQtitle()[index]);
                            }}
                        >
                            (+) Add Rule
                        </Button> */}
                    </MainCard>
                )}
            />
        </FormikProvider>
    );
};

RuleComponent.propTypes = {
    allQuesData: PropTypes.array,
    allQtitle: PropTypes.func,
    choiceMap: PropTypes.object,
    index: PropTypes.number,
    ruleIndex: PropTypes.number,
    survey: PropTypes.object
};

export default React.memo(RuleComponent);
