import React, { useState } from 'react';
import PropTypes from 'prop-types';
import DrawerRadio from './DrawerRadio';

const DisplayVertically = ({ formik }) => {
    // Default Value
    const [displayVertically, setDisplayVertically] = useState(false);

    const props = {
        formik,
        mainValue: displayVertically,
        mainValueSetter: setDisplayVertically,
        name: 'meta.display_vertically',
        label: 'Display Vertically'
    };

    return <DrawerRadio {...props} />;
};
DisplayVertically.propTypes = {
    formik: PropTypes.object
};
export default DisplayVertically;
