import { memo, useEffect, useState } from 'react';

// material-ui
import { Divider, List } from '@mui/material';

// project imports
import { useSelector } from 'react-redux';
import { find, first } from 'lodash';
import Menus from 'menu-items/AdminMenus';
import { dispatch } from 'store';
import { openDrawer } from 'store/slices/menu';
import ChildrenNavItem from './MenuList/NavItem/ChildrenNavItem';

// ==============================|| SIDEBAR MENU LIST ||============================== //

const ChildrenSidebar = () => {
    const { openItem } = useSelector((state) => state.menu);
    const state = useSelector((state) => state);
    const [menu, setMenu] = useState({});
    const [items, setItems] = useState({});

    useEffect(() => {
        if (menu && menu.mainMenu && !menu?.submenus) {
            dispatch(openDrawer(false));
        }
    }, [menu]);

    useEffect(() => {
        const currentMenuId = first(openItem);
        if (find(Menus, { id: currentMenuId })) {
            setMenu(find(Menus, { id: currentMenuId }));
            setItems(find(Menus, { id: currentMenuId })?.submenus?.map((menu) => <ChildrenNavItem key={menu.id} item={menu} level={1} />));
        }
    }, [openItem]);

    return (
        <>
            {menu?.submenus && (
                <>
                    <List>{items}</List>
                    <Divider sx={{ mt: 0.25, mb: 1.25 }} />
                </>
            )}
        </>
    );
};

export default memo(ChildrenSidebar);
