/* eslint-disable class-methods-use-this */
import axiosServices from 'utils/axios';

class RegistrationService {
    async create(data) {
        const response = await axiosServices.post('/registration', data);
        return response;
    }

    async resend(email) {
        const response = await axiosServices.post('/email/verify/resend', { email });
        return response;
    }

    async verifyEmail(id, hash) {
        const response = await axiosServices.get(`/email/verify/${id}/${hash}`);
        return response;
    }

    async forgotPassword(email) {
        const response = await axiosServices.post('/forgot-password', { email });
        return response;
    }

    async resetPassword(data) {
        const response = await axiosServices.post('/reset-password', data);
        return response;
    }

    async googleRegistration() {
        const response = await axiosServices.post('/login/google');
        return response;
    }

    async googleSheetLogin(surveyToken) {
        const response = await axiosServices.post('/google-sheet-login', { survey_token: surveyToken });
        return response;
    }

    async googleCallbackRegistration(extraParameters) {
        const response = await axiosServices.post(`/login/google/callback${extraParameters}`);
        return response;
    }

    async googleSheetCallbackRegistration(extraParameters, surveyToken) {
        const response = await axiosServices.post(`/google-sheet-login-callback${extraParameters}`, { survey_token: surveyToken });
        return response;
    }

    async facebookRegistration() {
        const response = await axiosServices.post('/login/facebook');
        return response;
    }

    async facebookCallbackRegistration(extraParameters) {
        const response = await axiosServices.post(`/login/facebook/callback${extraParameters}`);
        return response;
    }
}

export default new RegistrationService();
