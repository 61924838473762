/* eslint-disable class-methods-use-this */
import axiosServices from 'utils/axios';

class PaymentService {
    async getConfig(data) {
        const response = await axiosServices.get('/payments/config', data);
        return response;
    }

    async deletePaymentMethod(stripeId) {
        const response = await axiosServices.delete(`/payments/delete-payment-card/${stripeId}`);
        return response;
    }

    async addPaymentMethodToUser(data) {
        const response = await axiosServices.post('/payments/add-payment-method', data);
        return response;
    }

    async createPaymentIntentForUser() {
        const response = await axiosServices.get('/payments/create-payment-intent');
        return response;
    }

    async getPaymentCards() {
        const response = await axiosServices.get('/payments/get-payment-cards');
        return response;
    }

    async subscribe(data) {
        const response = await axiosServices.post('/user/subscribe', data);
        return response;
    }

    async cancelTrialAndSubscribe(data) {
        const response = await axiosServices.post('/user/cancel-trial-and-subscribe', data);
        return response;
    }

    async cancelSubscription(data) {
        const response = await axiosServices.post('/user/subscriptions/cancel', data);
        return response;
    }

    async getSubscriptions() {
        const response = await axiosServices.get('/user/subscriptions');
        return response;
    }

    async createSubscriber(data) {
        const response = await axiosServices.post('/user/subscriber/create', data);
        return response;
    }

    async createIntent(data) {
        const response = await axiosServices.post('/user/get-payment-intent', data);
        return response;
    }

    async getPackages() {
        const response = await axiosServices.get('/get-packages');
        return response;
    }

    async getCurrentPlanPackages() {
        const response = await axiosServices.get('/get-current-plan-packages');
        return response;
    }

    async getAllPackages() {
        const response = await axiosServices.post('/get-all-packages');
        return response;
    }

    async checkIncompletePayment() {
        const response = await axiosServices.get('/check-incomplete-payment');
        return response;
    }

    async createCheckoutSession(data) {
        const response = await axiosServices.get(`/create-checkout-session?price_id=${data.price_id}`);
        return response;
    }

    async swapCustomerPlan(data) {
        const response = await axiosServices.get(`/swap-customer-plan?price_id=${data.price_id}&stripe_status=${data.stripe_status}`);
        return response;
    }

    async setDefaultPaymentMethod(stripeId) {
        const response = await axiosServices.post('/payments/set-default-payment', { stripe_id: stripeId });
        return response;
    }
}

export default new PaymentService();
