import { lazy } from 'react';

// project imports
import MainLayout from 'layout/MainLayout';
import AuthGuard from 'utils/route-guard/AuthGuard';
import Loadable from 'ui-component/Loadable';
import AuthFullPageLayout from 'layout/MainLayout/AuthFullPageLayout';
import Builder from 'views/surveys/advance-form/builder';

// sample page routing
const AdvanceSurveyCreate = Loadable(lazy(() => import('views/surveys/advance-form/create')));

// ==============================|| MAIN ROUTING ||============================== //

const AdvanceFormCreateRoute = {
    path: '/',
    element: (
        <AuthGuard>
            <AuthFullPageLayout />
        </AuthGuard>
    ),
    children: [
        {
            path: ':surveyToken/advance-form-create',
            element: <Builder />
        }
    ]
};

export default AdvanceFormCreateRoute;
