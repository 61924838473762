import React, { useState } from 'react';
import DrawerRadio from './DrawerRadio';
import PropTypes from 'prop-types';

const AllowMultiple = ({ formik }) => {
    // Default Value
    const [allowMultiple, setAllowMultiple] = useState(false);

    const props = {
        formik,
        mainValue: allowMultiple,
        mainValueSetter: setAllowMultiple,
        name: 'meta.allow_multiple',
        label: 'Allow Multiple'
    };

    return <DrawerRadio {...props} />;
};
AllowMultiple.propTypes = {
    formik: PropTypes.object
};
export default AllowMultiple;
