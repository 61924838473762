import React, { lazy, useEffect } from 'react';
import { Alert, AlertTitle, Box, Card, Typography, Grid, Button, Link, TextField, IconButton, Tooltip } from '@mui/material';
import Loadable from 'ui-component/Loadable';
import SurveyService from 'services/survey.service';
import { useNavigate, useParams } from 'react-router-dom';
import ReportService from 'services/report.service';
import { Add, Circle, Visibility } from '@mui/icons-material';
import { GridSearchIcon } from '@mui/x-data-grid';
import GridSkeleton from '../GridSkeleton';
import ListView from '../ListView';
import { useFormik } from 'formik';
import transformToJqDatatable from 'utils/data-transformers';
import { useStore } from 'zustand';
import { useSnackbar } from 'notistack';
import AnswerService from 'services/answer.service';
import ListSkeleton from 'views/ListSkeleton';
import dayjs from 'dayjs';
import { IconLiveView } from '@tabler/icons';
import UserAssessment from './UserAssessment';
import { isArray } from 'lodash';

const ResponseInsights = () => {
    const [rows, setRows] = React.useState([]);
    const [confirmOpen, setConfirmOpen] = React.useState(false);
    const [isLoading, setIsLoading] = React.useState(true);
    const [selectedSurveyToken, setSelectedSurveyToken] = React.useState(null);
    const [totalRowCount, setTotalRowCount] = React.useState(0);
    const navigate = useNavigate();
    const { surveyToken } = useParams();
    const { enqueueSnackbar } = useSnackbar();
    const [open, setOpen] = React.useState(false);
    const [answerId, setAnswerId] = React.useState(null);

    const handleConfirmCancel = () => {
        setConfirmOpen(false);
    };

    const handleSidebarOpenClose = (flag, answerId) => {
        setAnswerId(answerId);
        setOpen(flag);
    };

    const fieldDefaultAttrs = {
        flex: 1,
        sortable: true,
        searchable: true
    };

    const columns = React.useRef([
        {
            field: 'respondent',
            headerName: 'Candidate Name',
            ...fieldDefaultAttrs,
            sortable: true,
            searchable: false,
            renderCell: (param) => (
                <Typography>
                    {param?.value?.first_name} {param?.value?.last_name}
                </Typography>
            )
        },
        {
            field: 'completion_time',
            headerName: 'Completed Time',
            ...fieldDefaultAttrs,
            searchable: false
        },
        {
            field: 'score',
            headerName: 'Overall Score',
            ...fieldDefaultAttrs,
            searchable: false,
            renderCell: (param) => <Typography align="center">{param.value}%</Typography>
        },
        {
            field: 'created_at',
            headerName: 'Taken On',
            ...fieldDefaultAttrs,
            searchable: false,
            renderCell: (param) => (
                <Tooltip title={dayjs(param.value).format('MMM DD, YYYY hh:mm A')}>
                    <Typography>{dayjs(param.value).format('MMM DD, YYYY hh:mm A')}</Typography>
                </Tooltip>
            )
        },
        {
            field: 'skill_score',
            headerName: 'Skill Scores',
            ...fieldDefaultAttrs,
            searchable: false,
            renderCell: (param) => (
                <>
                    {isArray(param.value) &&
                        param.value?.map((category) => (
                            <Tooltip title={`${category.category} : ${category.scores}`}>
                                <Circle sx={{ color: category.scores > 50 ? '#04de29' : '#eb0748' }} color="green" />
                            </Tooltip>
                        ))}
                </>
            )
        },
        {
            field: 'id',
            headerName: 'Actions',
            ...fieldDefaultAttrs,
            headerAlign: 'center',
            align: 'center',
            sortable: false,
            renderCell: (params) => (
                <Tooltip title="View">
                    <IconButton size="small" onClick={() => handleSidebarOpenClose(true, params.value)}>
                        <Visibility />
                    </IconButton>
                </Tooltip>
            )
        }
    ]);

    const [paginationProp, setPaginationProp] = React.useState({
        direction: true,
        sortBy: 'created_at',
        searchBy: 'survey_title',
        page: 0,
        rowsPerPage: 10,
        rowsNumber: 10,
        filter: null,
        singleFilterValue: null
    });

    const fetchAnswerScores = async () => {
        setIsLoading(true);
        const params = transformToJqDatatable({ columns: columns.current, pagination: paginationProp });
        const response = await AnswerService.listAnswerScores(surveyToken, params);
        setTotalRowCount(response.data.recordsFiltered);
        setRows(response.data.data);

        setIsLoading(false);
    };

    const handlePageChange = (newPage) => {
        const newPaginationProp = { ...paginationProp, page: newPage };
        setPaginationProp(newPaginationProp);
    };

    const handleSortChange = (data) => {
        if (Array.isArray(data) && data?.length) {
            const newPaginationProp = {
                ...paginationProp,
                sortBy: data[0].field,
                direction: data[0].sort,
                page: 0
            };

            setPaginationProp(newPaginationProp);
        }
    };

    const handleFilterChange = (data) => {
        const newPaginationProp = {
            ...paginationProp,
            searchBy: data.items[0].columnField,
            singleFilterValue: data.items[0].value,
            page: 0
        };
        setPaginationProp(newPaginationProp);
    };

    const handleOnPageSizeChange = (newPageSize) => {
        const newPaginationProp = {
            ...paginationProp,
            rowsPerPage: newPageSize
        };

        setPaginationProp(newPaginationProp);
    };

    const formik = useFormik({
        initialValues: {
            gridSearch: ''
        },
        enableReinitialize: true
    });

    const handleSearch = (formik, value) => {
        if (formik.touched.gridSearch && value?.length > 2) {
            const newPaginationProp = { ...paginationProp, filter: value, page: 0 };
            setPaginationProp(newPaginationProp);
        } else if (formik.touched.gridSearch && value === '') {
            const newPaginationProp = { ...paginationProp, filter: value, page: 0 };
            setPaginationProp(newPaginationProp);
        }
    };

    React.useEffect(() => {
        fetchAnswerScores();
    }, [paginationProp]); // eslint-disable-line react-hooks/exhaustive-deps

    React.useEffect(() => {
        handleSearch(formik, formik.values.gridSearch);
    }, [formik.values.gridSearch]); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <>
            <Grid container justifyContent="space-between">
                <Grid item>
                    <Typography variant="h4">Response Insights</Typography>
                </Grid>
            </Grid>

            {isLoading && <ListSkeleton number={paginationProp.rowsPerPage} />}

            {!isLoading && (
                <ListView
                    pageSize={paginationProp.rowsPerPage}
                    onPageSizeChange={handleOnPageSizeChange}
                    paginationProp={paginationProp}
                    rows={rows}
                    columns={columns}
                    totalRowCount={totalRowCount}
                    handleSortChange={handleSortChange}
                    handleFilterChange={handleFilterChange}
                    handlePageChange={handlePageChange}
                    setSelectedSurveyToken={setSelectedSurveyToken}
                    isLoading={isLoading}
                />
            )}

            {answerId && (
                <UserAssessment
                    open={open}
                    answerId={answerId}
                    handleSidebarOpenClose={handleSidebarOpenClose}
                    currentAnswer={rows.find((row) => row.id === answerId)}
                />
            )}
        </>
    );
};

ResponseInsights.propTypes = {};

export default ResponseInsights;
