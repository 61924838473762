/* eslint-disable class-methods-use-this */
import axiosServices from 'utils/axios';
import config from '../config';

class QuestionService {
    async create(surveyToken, data, userConfig = {}) {
        const response = await axiosServices.post(`surveys/${surveyToken}/questions/create`, data, { ...config, ...userConfig });
        return response;
    }

    async update(surveyToken, questionId, data, userConfig = {}) {
        const response = await axiosServices.put(`surveys/${surveyToken}/questions/${questionId}/update`, data, {
            ...config,
            ...userConfig
        });
        return response;
    }

    async createOrUpdate(surveyToken, data, userConfig = {}) {
        const response = await axiosServices.post(`surveys/${surveyToken}/questions/createOrUpdate`, data, { ...config, ...userConfig });
        return response;
    }

    async massOrderUpdate(surveyToken, data, userConfig = {}) {
        const response = await axiosServices.post(`surveys/${surveyToken}/questions/orderUpdate`, data, { ...config, ...userConfig });
        return response;
    }

    async createSingleimageChoice(surveyToken, questionId, data, userConfig = {}) {
        const response = await axiosServices.post(`surveys/${surveyToken}/questions/${questionId}/choices/create-image-choice`, data, {
            ...config,
            ...userConfig
        });
        return response;
    }

    async updateImageChoices(surveyToken, questionId, data, userConfig = {}) {
        const response = await axiosServices.post(`surveys/${surveyToken}/questions/${questionId}/image-choices/update`, data, {
            ...config,
            ...userConfig
        });
        return response;
    }

    async get(surveyToken, questionId) {
        const response = await axiosServices.get(`surveys/${surveyToken}/questions/${questionId}/preview`);
        return response;
    }

    async deleteChoice(surveyToken, questionId, choiceId) {
        const response = await axiosServices.delete(`surveys/${surveyToken}/questions/${questionId}/choices/${choiceId}/delete`);
        return response;
    }

    async deleteChoiceImage(data) {
        const response = await axiosServices.post(`/image/delete`, data);
        return response;
    }

    // {survey_id}/questions/{question_id}/choices/{choice}/delete
}

export default new QuestionService();
