import PropTypes from 'prop-types';
// material-ui
import { Box, Drawer, DialogContent, DialogTitle, Button, Typography } from '@mui/material';
import Form from 'views/surveys/form';
import { useSnackbar } from 'notistack';
import dayjs from 'dayjs';
import SurveyService from 'services/survey.service';
import { useState, useCallback, useMemo } from 'react';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import { IconX } from '@tabler/icons';
import { useParams } from 'react-router-dom';
import Loader from 'views/components/Loader';

const SurveyViewEdit = ({ open, handleSidebarOpenClose, survey, setOpen, handleSurveyEditSubmit }) => {
    const { enqueueSnackbar } = useSnackbar();
    const [loaderOpen, setLoaderOpen] = useState(false);

    const { surveyToken } = useParams();

    dayjs.extend(utc);
    dayjs.extend(timezone);

    const [availableActiveUser, setAvailableActiveUser] = useState(0);
    const [availableActiveUsersId, setAvailableActiveUsersId] = useState(0);
    const [availableActiveUserOneMonthAgo, setAvailableActiveUserOneMonthAgo] = useState(0);
    const [availableActiveUserTwoWeekAgo, setAvailableActiveUserTwoWeekAgo] = useState(0);

    const validateSlots = useCallback(
        (restrictions) => {
            const slots = restrictions.map((item) => item.slot).reduce((a, b) => a + b, 0);
            if (slots !== 100) {
                enqueueSnackbar('Slots sum must be 100', {
                    variant: 'error',
                    autoHideDuration: 3000
                });
                return false;
            }
            return true;
        },
        [enqueueSnackbar]
    );

    const action = useCallback(
        async (values) => {
            setLoaderOpen(true);

            if (values.meta.restrictions?.length > 0) {
                if (!validateSlots(values.meta.restrictions)) {
                    setLoaderOpen(false);
                    return false;
                }

                values.meta.restrictions = values.meta.restrictions.map((restriction) => {
                    restriction.restriction_rules = restriction.restriction_rules.filter((item) => item.value);
                    return restriction;
                });
            }

            const startDateInUtc = dayjs.tz(dayjs(values.start_date).format('YYYY-MM-DD HH:mm:ss'), values.meta.timezone).format();
            const endDateInUtc = dayjs.tz(dayjs(values.end_date).format('YYYY-MM-DD HH:mm:ss'), values.meta.timezone).format();

            const updatedValues = {
                ...values,
                quota: Number(values.quota),
                start_date: startDateInUtc,
                end_date: endDateInUtc,
                show_in_listing: values.show_in_listing ? 1 : 0
            };

            try {
                const { data: updatedSurvey } = await SurveyService.update(surveyToken, updatedValues);
                handleSurveyEditSubmit(updatedSurvey);
                enqueueSnackbar('Survey updated successfully', { variant: 'success', autoHideDuration: 3000 });
            } catch (error) {
                enqueueSnackbar('Failed to update survey', { variant: 'error', autoHideDuration: 3000 });
            } finally {
                setLoaderOpen(false);
            }

            return false;
        },
        [surveyToken, handleSurveyEditSubmit, enqueueSnackbar, validateSlots]
    );

    if (!open) return null;

    return (
        <Drawer
            ModalProps={{ keepMounted: true }}
            open={open}
            anchor="right"
            variant="temporary"
            PaperProps={{ sx: { maxWidth: 1200 } }}
            onClose={() => handleSidebarOpenClose(false)}
        >
            <Loader loaderOpen={loaderOpen} />

            <DialogTitle sx={{ backgroundColor: '#eceff1', display: 'flex', justifyContent: 'space-between' }}>
                <Typography variant="h2">Edit Survey</Typography>
                <Button variant="outlined" endIcon={<IconX />} onClick={() => setOpen(false)} aria-label="Close edit survey drawer">
                    Close
                </Button>
            </DialogTitle>

            <DialogContent sx={{ marginTop: '15px' }}>
                <Box sx={{ m: 1 }} />
                <Form
                    initialData={survey}
                    surveyAction={action}
                    activeUser={availableActiveUser}
                    activeUsersId={availableActiveUsersId}
                    activeUserOneMonthAgo={availableActiveUserOneMonthAgo}
                    activeUserTwoWeekAgo={availableActiveUserTwoWeekAgo}
                    mode="edit"
                />
            </DialogContent>
        </Drawer>
    );
};

SurveyViewEdit.propTypes = {
    open: PropTypes.bool,
    handleSidebarOpenClose: PropTypes.func,
    survey: PropTypes.object,
    setOpen: PropTypes.func,
    handleSurveyEditSubmit: PropTypes.func
};

export default SurveyViewEdit;
