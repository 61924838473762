import React, { useEffect, useState } from 'react';
import { PaymentElement, useStripe, useElements } from '@stripe/react-stripe-js';
import { Button, Alert, AlertTitle } from '@mui/material';
import { useNavigate } from 'react-router-dom';

export default function CheckoutForm(options) {
    const stripe = useStripe();
    const elements = useElements();
    const [paymentIntent, setPaymentIntent] = useState();
    const {
        props: { clientSecret, packageId }
    } = options;
    const navigate = useNavigate();
    const [message, setMessage] = useState(null);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        if (!stripe) {
            return;
        }

        if (!clientSecret) {
            return;
        }
        stripe.retrievePaymentIntent(clientSecret).then(({ paymentIntent }) => {
            switch (paymentIntent.status) {
                case 'succeeded':
                    setMessage('Payment succeeded!');
                    break;
                case 'processing':
                    setMessage('Your payment is processing.');
                    break;
                default:
                    break;
            }
        });
    }, [stripe, clientSecret]);

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!stripe || !elements) {
            console.error('Stripe or Elements not loaded');
            return;
        }

        const { error, paymentIntent } = await stripe.confirmPayment({
            elements,
            redirect: 'if_required'
        });
        /**
         * Now subscribe the user.
         */
        if (paymentIntent?.status === 'succeeded') {
            navigate({
                pathname: '/profile/membership'
            });
        }

        if (typeof error !== 'undefined' && (error.type === 'card_error' || error.type === 'validation_error')) {
            setMessage(error.message);
        } else {
            setMessage('An unexpected error occurred.');
        }
    };

    return (
        <form id="payment-form" onSubmit={handleSubmit}>
            <PaymentElement id="payment-element" />
            <Button type="submit" disabled={isLoading || !stripe || !elements} id="submit">
                <span id="button-text">{isLoading ? <div className="spinner" id="spinner" /> : 'Pay now'}</span>
            </Button>
            {/* Show any error or success messages */}
            {message && (
                <Alert severity="error" variant="filled" icon={false}>
                    <AlertTitle>Error</AlertTitle>
                    {message}
                </Alert>
            )}
        </form>
    );
}
