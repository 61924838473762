import React, { useEffect } from 'react';

// material-ui
import { makeStyles } from '@mui/styles';
import { Button, CardActions, Checkbox, Divider, FormControlLabel, Grid, Switch, Typography } from '@mui/material';

import AnimateButton from 'ui-component/extended/AnimateButton';
import { cloneDeep } from 'lodash';
import GeneralService from 'services/general.service';
import { useSnackbar } from 'notistack';
import useAuth from 'hooks/useAuth';
import { LoadingButton } from '@mui/lab';

// style constant
const useStyles = makeStyles({
    cardTitle: {
        fontWeight: 600,
        display: 'flex',
        alignItems: 'center',
        '& > svg': {
            marginRight: '8px'
        }
    }
});

// ===========================|| PROFILE 1 - SETTINGS ||=========================== //

const Settings = () => {
    const classes = useStyles();
    const { enqueueSnackbar } = useSnackbar();
    const { user } = useAuth();
    const [isLoading, setIsLoading] = React.useState(false);

    const [settings, setSettings] = React.useState([
        {
            headline: 'Receive Updates about surveyflip.com',
            items: [
                {
                    name: 'Send me summary emails for my surveys',
                    key: 'summary_emails',
                    checked: false
                },
                {
                    name: 'Send me updates about any new products',
                    key: 'new_product_update',
                    checked: false
                }
            ]
        }
    ]);

    const handleCheckboxChange = (event, item, index, i) => {
        const newSettingsData = cloneDeep(settings);
        newSettingsData[index].items[i].checked = event.target.checked;
        setSettings(newSettingsData);
    };

    const updateNotificationSettings = async () => {
        setIsLoading(true);
        const { data } = await GeneralService.updateNotificationSettings(settings);
        enqueueSnackbar('Settings Updated', {
            variant: 'success',
            autoHideDuration: 3000
        });
        delete data.meta.user_settings.ipinfo;
        setSettings(Object.values(data?.meta?.user_settings));
        setIsLoading(false);
    };

    useEffect(() => {
        if (user?.meta?.user_settings) {
            if (user?.meta?.user_settings?.items?.length > 0 || user?.meta?.user_settings?.length > 0) {
                delete user.meta.user_settings.ipinfo;
                setSettings(Object.values(user.meta.user_settings));
            }
        }
    }, [user]);

    return (
        <>
            <Grid container direction="column" spacing={3}>
                <Grid item alignContent="left" xs={12}>
                    <Typography variant="h3" pt={1}>
                        Notification Settings
                    </Typography>
                </Grid>

                <Grid item xs={12}>
                    {settings?.map((setting, index) => (
                        <Grid container direction="column" spacing={0} key={index}>
                            <Grid item>
                                <Typography variant="subtitle1">{setting?.headline}</Typography>
                            </Grid>
                            <Grid container ml={1}>
                                {setting?.items?.map((item, i) => (
                                    <Grid item xs={12} key={i}>
                                        <FormControlLabel
                                            control={<Checkbox checked={item?.checked} name={item?.key} color="primary" />}
                                            label={item?.name}
                                            onChange={(event) => handleCheckboxChange(event, item, index, i)}
                                        />
                                    </Grid>
                                ))}
                            </Grid>
                        </Grid>
                    ))}
                </Grid>
            </Grid>
            <CardActions sx={{ p: 0, pt: 3 }}>
                <Grid spacing={2} container justifyContent="flex-start">
                    <Grid item>
                        <AnimateButton>
                            <LoadingButton
                                loading={isLoading}
                                disabled={isLoading}
                                variant="contained"
                                onClick={updateNotificationSettings}
                            >
                                Update
                            </LoadingButton>
                        </AnimateButton>
                    </Grid>
                </Grid>
            </CardActions>
        </>
    );
};

export default Settings;
