import { Card, Grid, Skeleton } from '@mui/material';
import PropTypes from 'prop-types';
import { range } from 'lodash';

export default function ProfileSkeleton({ number, marginLeft = '25px' }) {
    return (
        <Grid container>
            {range(0, number).map((num, index) => (
                <Grid item xs={12} key={index}>
                    <Skeleton sx={{ marginTop: '20px', marginLeft: `${marginLeft}` }} variant="rectangular" height={50} />
                </Grid>
            ))}
        </Grid>
    );
}
ProfileSkeleton.propTypes = {
    number: PropTypes.number,
    marginLeft: PropTypes.any
};
