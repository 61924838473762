import React from 'react';
import BooleanChoice from 'views/logical-restrictions/BooleanChoice';
import NumberChoice from 'views/logical-restrictions/NumberChoice';
import TextChoice from 'views/logical-restrictions/TextChoice';
import create from 'zustand';

const useRestrictionStore = create((set) => ({
    ruleEngine: [],
    logicalOperatorTemplates: {
        phone_number_question: (props) => <NumberChoice {...props} />,
        short_question: (props) => <TextChoice {...props} />,
        long_question: (props) => <TextChoice {...props} />,
        dropdown_question: (props) => <TextChoice {...props} />,
        boolean_question: (props) => <BooleanChoice {...props} />,
        html_question: (props) => <TextChoice {...props} />,
        image_picker_question: (props) => <TextChoice {...props} />,
        email_question: (props) => <TextChoice {...props} />,
        country_question: (props) => <TextChoice {...props} />,
        state_question: (props) => <TextChoice {...props} />,
        mcq_question: (props) => <TextChoice {...props} />,
        rating_question: (props) => <NumberChoice {...props} />,
        opinion_scale_question: (props) => <NumberChoice {...props} />
    },

    ADD_RULENGINE: (id, values) => {
        set((state) => {
            // CreateOrUpdate
            const rule = state.ruleEngine.find((element) => Number(Object.keys(element)[0]) === id);
            if (rule) {
                state.ruleEngine = state.ruleEngine.map((v, i) => {
                    if (Number(Object.keys(v)[0]) === id) {
                        return { [id]: values };
                    }
                    return v;
                });
            } else {
                state.ruleEngine.push({ [id]: values });
            }
        });
    },
    SET_RULENGINE: (surveyId, values) =>
        set((state) => {
            state.ruleEngine.push({ [surveyId]: values });
        })
    // CLEAR_ANSWER: () => {
    //     set((state) => {
    //         state.answer = '';
    //     });
    // }
}));

export default useRestrictionStore;
