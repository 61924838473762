/* eslint-disable react/self-closing-comp */
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import useOnePageSurveyStore from 'zustand_store/useOnePageSurveyStore';
import { SaveAs } from '@mui/icons-material';
import { IconTrash } from '@tabler/icons';
import Tooltip from '@mui/material/Tooltip';
import { useSnackbar } from 'notistack';
import { useParams } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import QuestionService from 'services/question.service';
import { useEffect, useState } from 'react';
import SurveyService from 'services/survey.service';
import { findIndex, isEqual } from 'lodash';
import Emitter from 'utils/emitter';

// ==============================|| Main Template ||============================== //

const Delete = ({ showIcon, showTitle }) => {
    const [confirmOpen, setConfirmOpen] = useState(false);
    const [changingQuestionType, setChangingQuestionType] = useState('');

    const SET_CURRENT_QUESTION_ID = useOnePageSurveyStore((state) => state.SET_CURRENT_QUESTION_ID);
    const DELETE_QUESTION = useOnePageSurveyStore((state) => state.DELETE_QUESTION);
    const MUTATE_QUESTIONS_ORDER = useOnePageSurveyStore((state) => state.MUTATE_QUESTIONS_ORDER);
    const ADD_QUESTION = useOnePageSurveyStore((state) => state.ADD_QUESTION);
    const SET_SELECTED_QUESTION_IDENTIFIER = useOnePageSurveyStore((state) => state.SET_SELECTED_QUESTION_IDENTIFIER);

    const SET_CURRENT_QUESTION = useOnePageSurveyStore((state) => state.SET_CURRENT_QUESTION);
    const currentQuestion = useOnePageSurveyStore((state) => state.currentQuestion);
    const questions = useOnePageSurveyStore((state) => state.questions);
    const { surveyToken } = useParams();
    const { enqueueSnackbar } = useSnackbar();

    const handleConfirmCancel = () => {
        setConfirmOpen(false);
    };

    const handleDelete = async () => {
        const currentQuestionIndex = findIndex(questions, (obj) => isEqual(obj, currentQuestion));

        DELETE_QUESTION(currentQuestion?.id);
        setConfirmOpen(false);

        // If the given object exists in the array
        if (currentQuestionIndex !== -1) {
            // Check if the given object is not the first object in the array
            if (currentQuestionIndex > 0) {
                // Get the previous object
                const previousQuestion = questions[currentQuestionIndex - 1];
                SET_CURRENT_QUESTION_ID(previousQuestion?.id);

                SET_SELECTED_QUESTION_IDENTIFIER(previousQuestion?.question_type?.identifier);
                Emitter.emit('QUESTION_TYPE_CHANGED_AFTER_DELETE', previousQuestion?.question_type?.identifier);
            } else {
                const nextQuestion = questions[currentQuestionIndex + 1];

                if (questions.length > 0) {
                    SET_CURRENT_QUESTION_ID(nextQuestion?.id);

                    // SET_CURRENT_QUESTION(nextQuestion);
                    SET_SELECTED_QUESTION_IDENTIFIER(nextQuestion?.question_type?.identifier);
                }
                console.log('Given object is the first object in the array.');
            }
        } else {
            console.log('Given object not found in the array.');
        }
        enqueueSnackbar('Question deleted!', { variant: 'success', autoHideDuration: 3000 });

        if (typeof currentQuestion?.id === 'number') {
            const { data: response } = await SurveyService.deleteQuestion(currentQuestion?.id, surveyToken);
        }
    };

    return (
        <>
            {showIcon && (
                <Tooltip title="Delete Qustion" placement="top">
                    <Button onClick={() => setConfirmOpen(true)}>
                        <IconTrash />
                    </Button>
                </Tooltip>
            )}

            {showTitle && (
                <Typography variant="h6" onClick={() => setConfirmOpen(true)}>
                    Delete
                </Typography>
            )}
            <Dialog
                sx={{ '& .MuiDialog-paper': { width: '80%', maxHeight: 435 } }}
                maxWidth="xs"
                // TransitionProps={{ onEntering: handleEntering }}
                open={confirmOpen}
            >
                <DialogTitle>Warning</DialogTitle>
                <DialogContent dividers>
                    <strong>Are you sure that you want to delete this question?</strong>
                </DialogContent>
                <DialogActions>
                    <Button autoFocus onClick={handleConfirmCancel}>
                        Cancel
                    </Button>
                    <Button onClick={handleDelete}>Yes</Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

Delete.propTypes = {
    showIcon: PropTypes.bool,
    showTitle: PropTypes.bool
};

export default Delete;
